import Table from "../../organisms/Table/Table";
import React from "react";

const SelectDtxModal = ({
                            rows,
                            selectOrg,
                            getTotalRows,
                            search,
                            onChangeSearchValue,
                            onClickSearchButton,
                            page,
                            limit,
                            totalPages,
                            changePage,
                            sortParams,
                            setSortParams,
                        }) => {
    const dtxColumns = [
        {
            name: "dtx_name",
            header: "DTx명",
            width: "20%",
            customBody: (row) => (
                <td
                    key="dtx_name"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {row.dtx_name}
                </td>
            ),
        },
        {
            name: "dtx_code",
            header: "DTx코드",
            width: "20%",
            customBody: (row) => (
                <td
                    key="dtx_code"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {row.dtx_code}
                </td>
            ),
        },
        {
            name: "org_name",
            header: "기업",
            width: "20%",
            customBody: (row) => (
                <td
                    key="org_name"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {row.org_name}
                </td>
            ),
        },
        {
            name: "enable",
            header: "사용 상태",
            width: "15%",
            customBody: (row) => (
                <td
                    key="enable"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                      <span
                          className={
                              row.enable === "true" ? "badge badge_blue" : "badge badge_red"
                          }
                      >
            {row.enable === "true" ? "사용" : "중지"}
                    </span>
                </td>
            ),
        },
    ];
    return (
        <Table
            rows={rows}
            columns={dtxColumns}
            getTotalRows={getTotalRows}
            search={search}
            onChangeSearchValue={onChangeSearchValue}
            onClickSearchButton={onClickSearchButton}
            page={page}
            limit={limit}
            totalPages={totalPages}
            changePage={changePage}
            sortParams={sortParams}
            setSortParams={setSortParams}
        ></Table>
    );
};

export default SelectDtxModal;
