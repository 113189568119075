import axios from "axios";

const request = axios.create({
  timeout: 30000,
  baseURL: process.env.REACT_APP_BACKEND_URL,
  responseType: "json",
  withCredentials: false,
  headers: {
    'Content-type': 'application/json',
  },
});


export default request;
