import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { RecoilRoot, atom } from "recoil";
import { recoilPersist } from 'recoil-persist';
import Snackbar from "./components/organisms/Snackbar/Snackbar";
import SnackbarForInfo from "./components/organisms/SnackbarForInfo/SnackbarForInfo";

const { persistAtom } = recoilPersist();
export const AlertErrorState = atom({
  key: "AlertError",
  default: { error: {} },
});

export const AlertInfoState = atom({
  key: "AlertInfo",
  default: { message: "", color: "" },
});

export const UserDataState = atom({
  key: "UserData",
  default: {},
});

export const TokenExpired = atom(({
    key: "tokenExpired",
    default : false
}));

export const JwtState =atom({
    key:"jwt",
    default :{
        userId:"",
    },
    effects_UNSTABLE: [persistAtom],
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RecoilRoot>
      <Snackbar error={{}}></Snackbar>
    <SnackbarForInfo message=""></SnackbarForInfo>
    <App />
  </RecoilRoot>
);

