import React from "react";
import Input from "../../atoms/Input/Input";
import DynamicTable from "../../organisms/DynamicTable/DynamicTable";

export const CreateDTxAPPModal = ({
                                      appData,
                                      onChangeAppData,
                                      onChangeBlur,
                                      isChecked,
                                      setIsChecked,
                                      installRows,
                                      installColumns,
                                      createApp,
                                      modifyApp,
                                      closeModal,
                                      openSelectOrgModal,
                                      modalType,
                                      errorMessage,
                                  }) => {
    return (
        <>
            <ul className="form_wrap mb50">
                <li>
                    <label className="name">DTx코드</label>
                    <Input
                        type="text"
                        name="dtx_code"
                        value={appData.dtx_code}
                        onChange={onChangeAppData}
                        disabled
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">기업{modalType === "create" ?<sup className="required">*</sup> :""} </label>
                    <div style={{marginBottom: "0px"}} className="search_box">
                        <div key={appData.org_id} className="search_wrap">
                            <Input
                                type="text"
                                name={"org_name"}
                                value={appData.org_name}
                                className="input_search_modal"
                                placeholder={"기업 선택"}
                                disabled={modalType !== "create"}
                                autoComplete="off"
                                style={
                                    modalType === "create"
                                        ? {
                                            backgroundColor: "#FAFAFA",
                                            color: "#666670",
                                            cursor: "pointer",
                                        }
                                        : {
                                            backgroundColor: "#FAFAFA",
                                            color: "#666670",
                                        }
                                }
                                onClick={
                                    modalType === "create" ? openSelectOrgModal : () => {
                                    }
                                }
                                onChange={() => {
                                }}
                                errorMessage={errorMessage}
                            />
                            {modalType === "create" ? (
                                <button
                                    className="btn_search"
                                    onClick={openSelectOrgModal}
                                ></button>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </li>
                <li>
                    <label className="name">DTx명<sup className="required">*</sup></label>
                    <Input
                        type="text"
                        name="dtx_name"
                        value={appData.dtx_name}
                        onChange={onChangeAppData}
                        maxLength={"32"}
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">EDI 코드</label>
                    <Input
                        type="text"
                        name="edi_code"
                        value={appData.edi_code}
                        onBlur={onChangeBlur}
                        maxLength={"30"}
                        onChange={onChangeAppData}
                        errorMessage={errorMessage}
                    />
                </li>
                <li >
                    <label className="name">사용 상태</label>
                    <ul className="check_box_ul">
                        <li style={{paddingLeft: "0"}}>
                            <input
                                className={"checkbox"}
                                checked={isChecked}
                                name={"enable"}
                                type="checkbox"
                                onChange={() => setIsChecked((prev) => !prev)}
                            />
                        </li>
                    </ul>
                </li>
                    <li>
                        <label className="name">사용기간(일)<sup className="required">*</sup></label>
                        <Input
                            type="number"
                            onBlur={onChangeBlur}
                            name="use_period"
                            value={appData.use_period}
                            onChange={onChangeAppData}
                            errorMessage={errorMessage}
                        />
                    </li>
                    <li>
                        <label className="name">처방유효기간(일)<sup className="required">*</sup></label>
                        <Input
                            type="number"
                            onBlur={onChangeBlur}
                            name="prcp_valid_period"
                            value={appData.prcp_valid_period}
                            onChange={onChangeAppData}
                            errorMessage={errorMessage}
                        />
                    </li>
                <div className={"li_box"} style={{"display": "none"}}>
                    <li>
                        <label className="name">매입단가</label>
                        <Input
                            type="text"
                            name="unit_purchase_price"
                            value={appData.unit_purchase_price}
                            onChange={onChangeAppData}
                            errorMessage={errorMessage}
                        />
                    </li>
                    <li>
                        <label className="name">매출단가</label>
                        <Input
                            type="text"
                            name="unit_sales_price"
                            value={appData.unit_sales_price}
                            onChange={onChangeAppData}
                            errorMessage={errorMessage}
                        />
                    </li>
                </div>
                <li>
                    <label className="name">설치정보<sup className="required">*</sup></label>
                    <DynamicTable
                        rows={installRows}
                        columns={installColumns}
                        // totalElements={totalTurnElements}
                    ></DynamicTable>
                    {errorMessage.length !== 0 && errorMessage[0] === "install" ?
                        ( <p className="warning">{errorMessage[1]}</p>) : ""}
                </li>

                <div style={{ marginTop: "10px", fontWeight: "500" }}>
                    * Redirect가 포함될 경우 다른 설치경로는 무시됩니다.
                </div>
                <div style={{ marginTop: "5px", fontWeight: "400", marginLeft: "12px" }}>
                    <span>URL에 다음 파라미터를 사용할 수 있습니다.</span>
                    <span style={{ color: "red" }}>__PHICODE__</span>
                </div>
                <div style={{ marginTop: "5px", fontWeight: "400", marginLeft: "12px" }}>
                    <span style={{ fontWeight: "500" }}>example: </span>
                    <span>https://some.url?phicode=</span>
                    <span style={{ color: "red" }}>__PHICODE__</span>
                </div>
            </ul>
            <div className="btn_wrap">
                <div className="left"></div>
                <div className="center"></div>
                <div className="right">
                    <button className="btn btn_gray_line" onClick={closeModal}>
                        취소
                    </button>
                    <button
                        className="btn btn_black"
                        onClick={modalType === "createApps" ? createApp : modifyApp}
                    >
                        저장
                    </button>
                </div>
            </div>
        </>
    );
};

export default CreateDTxAPPModal;
