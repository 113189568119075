import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import {AlertInfoState} from "..";
import {useRecoilState} from "recoil";
import {DefaultPageSize, DefaultSearchDay, DefaultSortParams, MessageColor,} from "../components/Default/DefaultValue";
import moment from "moment/moment";

const AlertHist = () => {
    const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
    const [message, setMessage] = useRecoilState(AlertInfoState);

    // 제목, 브레드크럼
    const title = "알림 이력";
    const breadcrumb = [
        {name: "HOME", link: "./"},
        {name: "데이터 조회"},
        {name: "알림 이력", link: "./AlertHist"},
    ];

    const pageSize = DefaultPageSize;


    // 모달 테이블 관련 변수
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [sortParams, setSortParams] = useState(DefaultSortParams.ALERT);

    // 검색조회부분
    const [tableSearchItems, setTableSearchItems] = useState([]);

    // td 내용
    const [rows, _setRows] = useState([]);

    // header,body 변경 th 내용
    const columns = [
        {
            name: "org_name",
            header: "기관명",
            width: "13%",
        },
        {
            name: "push_type",
            header: "알림 전송 타입",
            width: "13%",
        },
        {
            name: "status",
            header: "처리 상태",
            width: "13%",
            customBody: (row) => (
                <td key="status">{row.status === "init"? "처리 전"
                    :row.status ==="pending" ? "처리중" : "처리 완료" }</td>
            ),
        },
        {
            name: "receive_time",
            header: "알림 전송 요청 수신일",
            width: "20%",
        },
        {
            name: "start_time",
            header: "처리 시작일 ",
            width: "15%",
        },
        {
            name: "end_time",
            header: "처리 완료일 ",
            width: "*%",
        },
        {
            name: "succeed",
            header: "처리 결과",
            width: "10%",
            customBody: (row) => (
                <td key="role">
          <span
              className={
                  row.succeed === true
                      ? "badge badge_green"
                      : row.succeed === false
                          ? "badge badge_red"
                          : "badge badge_black"
              }
          >
            {row.succeed === false ? "실패" : "성공"}
          </span>
                </td>
            ),
        },
    ];


    // 입력창값이 변경될때
    const onChangeSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickSearchButton();
        }
        const {name, value} = event.target;
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
        if (name === "startDate") {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "endDate") {
                        return {
                            ...item,
                            value:
                                item.value > moment(value).add(30, "day").format("YYYY-MM-DD")
                                    ? moment(value).add(30, "day").format("YYYY-MM-DD")
                                    : item.value < value
                                        ? value
                                        : item.value,
                        };
                    }
                    return item;
                })
            );
        }
    };

    // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
    const onClickSearchButton = () => {
        const start = tableSearchItems[0].value;
        const end = tableSearchItems[1].value;
        const diff = moment(end).diff(moment(start), "days")

        if(diff > 30){
            setMessage({
                message: "최대 조회기간은 30일입니다.",
                color: MessageColor.RED,
            })
        }else if(diff < 0){
            setMessage({
                message: "종료날짜는 시작날짜보다 이전일 수 없습니다.",
                color: MessageColor.RED,
            })
        }else {
            setPage(1);
            setRefresh((prev) => !prev);
        }
    };


    useEffect(() => {
        (async () => {
            const alertTypeEnum = {
                "알림 전송 타입 선택": "*",
                "db": "db",
                "http": "http",
            };
            const statusEnum = {
                "처리 상태 선택": "*",
                "처리전": "init",
                "처리중": "pending",
                "처리완료": "end"
            };

            let searchItems = [
                {
                    name: ["startDate"],
                    value: moment(new Date())
                        .subtract(DefaultSearchDay, "day")
                        .format("YYYY-MM-DD"),
                    type: "date",
                    label: "요청 수신일",
                },
                {
                    name: ["endDate"],
                    value: moment(new Date()).format("YYYY-MM-DD"),
                    type: "date",
                },
                {
                    name: ["typeFilter"],
                    filter: alertTypeEnum,
                    value: Object.values(alertTypeEnum)[0],
                    type: "select",
                },
                {
                    name: ["statusFilter"],
                    filter: statusEnum,
                    value: Object.values(statusEnum)[0],
                    type: "select",
                },
                {
                    name: ["org_name"],
                    placeholder: ["기관명"],
                    value: "",
                    type: "text",
                },
            ];

            setTableSearchItems(searchItems);
            setRefresh((prev) => !prev);
        })();
    }, []);


    useEffect(() => {
        if (tableSearchItems.length > 0) {
            (async () => {
                //유효하지 않은 날짜 체크
                if (!moment(tableSearchItems[0].value).isValid() || !moment(tableSearchItems[1].value).isValid()) {
                    return;
                }
                const HistoryData = await request.get(`alert-hists`, {
                    params: {
                        page: page - 1,
                        size: pageSize,
                        start_date: moment(tableSearchItems[0].value).format("YYYYMMDD"),
                        end_date: moment(tableSearchItems[1].value).format("YYYYMMDD"),
                        type:
                            tableSearchItems[2] === undefined ? "*" : tableSearchItems[2].value,
                        status:
                            tableSearchItems[3] === undefined ? "*" : tableSearchItems[3].value,
                        name:
                            tableSearchItems[4] === undefined
                                ? "*"
                                : tableSearchItems[4].value.trim(),
                        sort: sortParams,
                    },
                });
                _setRows(HistoryData.data.data.content);
                setTotalPages(HistoryData.data.data.totalPages);
                setTotalElements(HistoryData.data.data.totalElements);
            })();
        }
    }, [refresh, page, sortParams]);

    // 최종 화면 나오는 곳
    return (
        <>
            <div className="wrapper">
                {<History title={title} breadcrumb={breadcrumb}/>}
                <hr/>
                {
                    <Table
                        rows={rows}
                        columns={columns}
                        getTotalRows={totalElements}
                        search={tableSearchItems}
                        onChangeSearchValue={onChangeSearchValue}
                        onClickSearchButton={onClickSearchButton}
                        page={page}
                        totalPages={totalPages}
                        changePage={setPage}
                        sortParams={sortParams}
                        setSortParams={setSortParams}
                    />
                }
            </div>
        </>
    );
};

export default AlertHist;
