import React, { useEffect } from "react";
import Input from "../../atoms/Input/Input";
import clsx from "clsx";

const Postcode = ({
  postalCode,
  setPostalCode,
  roadAddress,
  setRoadAddress,
  detailAddress,
  setDetailAddress,
  errorMessage,
}) => {
  useEffect(() => {
    const loadDaumPostcodeScript = () => {
      const script = document.createElement("script");
      script.src =
        "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
      script.async = true;
      script.onload = () => {};
      document.body.appendChild(script);
    };

    loadDaumPostcodeScript();
  }, []);

  const handleExecDaumPostcode = (e) => {
    e.preventDefault();
    if (window.daum && window.daum.Postcode) {
      new window.daum.Postcode({
        oncomplete: (data) => {
          const newRoadAddress = data.roadAddress;
          setPostalCode(data.zonecode);
          setRoadAddress(newRoadAddress);
        },
      }).open();
    } else {
      console.error("Daum Postcode API not available.");
    }
  };

  return (
    <>
      <Input
        type="text"
        className={`w472`}
        id="sample4postcode"
        name="postal_code"
        placeholder="우편번호"
        value={postalCode}
        readOnly
        disabled
        errorMessage={errorMessage}
      />
      <button
        className="btn btn_gray ml10"
        value="Find Postcode"
        onClick={handleExecDaumPostcode}
      >
        주소검색
      </button>
      <Input
        type="text"
        className={`sub_input`}
        id="sample4roadAddress"
        name="road_address"
        placeholder="도로명주소"
        value={roadAddress}
        readOnly
        disabled
        errorMessage={errorMessage}
      />
      <div>
        <input
          type="text"
          className={clsx(
            "sub_input",
            errorMessage && errorMessage[0] === "address_detail"
              ? "badge_red"
              : ""
          )}
          id="sample4_detailAddress"
          name="address_detail"
          placeholder="상세주소"
          value={detailAddress}
          onChange={(e) => setDetailAddress(e.target.value)}
        />
        {errorMessage && errorMessage[0] === "address_detail" && (
          <p style={{ marginLeft: "250px" }} className="warning">
            {errorMessage[1]}
          </p>
        )}
      </div>
    </>
  );
};

export default Postcode;
