import React, { useEffect, useState } from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import { Table } from "../components/organisms/Table/Table";
import moment from "moment";
import {
  DefaultPageSize,
  DefaultSearchDay,
  DefaultSortParams,
  MessageCollection,
  copyToClipboard, MessageColor,
} from "../components/Default/DefaultValue";
import logCode from "../assets/resources/logCode.json";
import { useRecoilState } from "recoil";
import { AlertInfoState } from "..";

const AuditLog = () => {
  const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
  const [message, setMessage] = useRecoilState(AlertInfoState);

  // 제목, 브레드크럼
  const title = "감사로그";
  const breadcrumb = [
    { name: "HOME", link: "./" },
    { name: "이력 관리" },
    { name: "감사로그", link: "./OprLog" },
  ];

  const pageSize = DefaultPageSize;

  // 테이블 관련 변수
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [sortParams, setSortParams] = useState(DefaultSortParams.LOG);

  // 검색조회부분
  const [tableSearchItems, setTableSearchItems] = useState([]);
  const [searchFilter, setSearchFilter] = useState(-1);
  const [detail, setDetail] = useState();

  // Enum
  const [searchFilterEnum, setSearchFilterEnum] = useState({});
  const [logTypeEnum, setLogTypeEnum] = useState({});
  const [resultLogTypeEnum, setResultLogTypeEnum] = useState({});
  const [detailLogTypeEnum, setDetailLogTypeEnum] = useState({});
  const [logNameTypeEnum, setLogNameTypeEnum] = useState({});

  const [convertCodeToDesc, setConvertCodeToDesc] = useState({});

  // td 내용
  const [rows, _setRows] = useState([]);

  // header,body 변경 th 내용
  const columns = [
    {
      name: "log_type",
      header: "타입",
      width: "5%",
      customBody: (row) => (
        <td key="log_type">
          <span>{logNameTypeEnum[row.log_type]}</span>
        </td>
      ),
    },
    {
      name: "log_code",
      header: "코드",
      width: "5%",
    },
    {
      name: "log_desc",
      header: "행위",
      width: "16%",
      sort:false,
      customBody: (row) => (
        <td key="log_desc">
          <span>{convertCodeToDesc[`${row.log_type}:${row.log_code}`]}</span>
        </td>
      ),
    },
    {
      name: "result_code",
      header: "결과",
      width: "5%",
      customBody: (row) => (
        <td key="role">
          <span
            className={
              row.result_code === "성공"
                ? "badge badge_green"
                : row.result_code === "실패"
                ? "badge badge_red"
                : "badge badge_black"
            }
          >
            {row.result_code}
          </span>
        </td>
      ),
    },
    {
      name: "src_ip",
      header: "IP",
      width: "8%",
    },
    {
      name: "log_date_time",
      header: "시간",
      width: "15%",
    },
    {
      name: "contents",
      header: "내용",
      width: "46%",
      customBody: (row) => (
        <td key="contents">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                width: "570px",
              }}
              className="ellipsis_log"
              title={row.contents}
            >
              {row.contents}
            </div>
            <div
              className="attachIcon"
              style={{ width: "18px" }}
              onClick={() => {
                copyToClipboard(row.contents);
                setMessage({ message: MessageCollection.COPY });
              }}
            ></div>
          </div>
        </td>
      ),
    },
  ];

  // 입력창값이 변경될때
  const onChangeSearchValue = (event) => {
    if (event.key === "Enter") {
      onClickSearchButton();
    }
    const { name, value } = event.target;
    setTableSearchItems((prevState) =>
      prevState.map((item) => {
        if (item.name.join(",") === name) {
          return { ...item, value };
        }
        return item;
      })
    );
    if (name === "startDate") {
      setTableSearchItems((prevState) =>
        prevState.map((item) => {
          if (item.name.join(",") === "endDate") {
            return {
              ...item,
              value:
                item.value > moment(value).add(30, "day").format("YYYY-MM-DD")
                  ? moment(value).add(30, "day").format("YYYY-MM-DD")
                  : item.value < value
                  ? value
                  : item.value,
            };
          }
          return item;
        })
      );
      return;
    }
    if (name === "searchFilter") {
      setSearchFilter(parseInt(value));
      return;
    }
    if (name !== "detailFilter") {
      setDetail("*");
    } else {
      setDetail(value);
    }
  };

  // 검색 영역에서 검색버튼
  const onClickSearchButton = () => {
    const start = tableSearchItems[0].value;
    const end = tableSearchItems[1].value;
    const diff = moment(end).diff(moment(start), "days")

    if(diff > 30){
      setMessage({
        message: "최대 조회기간은 30일입니다.",
        color: MessageColor.RED,
      })
    }else if(diff < 0){
      setMessage({
        message: "종료날짜는 시작날짜보다 이전일 수 없습니다.",
        color: MessageColor.RED,
      })
    }else {
      setPage(1);
      setRefresh((prev) => !prev);
    }
  };

  useEffect(() => {
    (async () => {
      const updateLogTypeEnum = {};
      const updatedConvertCodeToDesc = {};

      const logType = logCode;
      logType.map((data) => {
        if (data.value.split(":")[0] !== "operation") {
          updateLogTypeEnum[data.display] = data.value;
          updatedConvertCodeToDesc[data.value] = data.display;
        }
      });
      setConvertCodeToDesc(updatedConvertCodeToDesc);
      setLogTypeEnum(updateLogTypeEnum);

      const updateLogResultCodeEnum = {};
      const logResultCode = await request.get(`commons/log/result`);
      logResultCode.data.data.map((data) => {
        updateLogResultCodeEnum[data.display] = data.code;
      });

      setResultLogTypeEnum(updateLogResultCodeEnum);

      const updateSearchFilterEnum = {};
      const searchFilter = await request.get(`commons/log/search`);
      searchFilter.data.data.map((data) => {
        updateSearchFilterEnum[data.display] = data.code;
      });

      setSearchFilterEnum(updateSearchFilterEnum);

      const updateDetailLogTypeEnum = {};
      const logTypeResponse = await request.get(`commons/log/type`);
      logTypeResponse.data.data
      .filter((data) => data.type !== "operation")
      .map((data) => {
        updateDetailLogTypeEnum[data.desc] = data.type;
      });

      setDetailLogTypeEnum(updateDetailLogTypeEnum);

      const updatedLogNameTypeEnum = {};
      const logNameType = await request.get(`commons/log/type`);
      logNameType.data.data
      .filter((data) => data.type !== "operation")
      .map((data) => {
        updatedLogNameTypeEnum[data.type] = data.desc;
      });
      setLogNameTypeEnum(updatedLogNameTypeEnum);

      let searchItems = [
        {
          name: ["startDate"],
          value: moment(new Date())
            .subtract(DefaultSearchDay, "day")
            .format("YYYY-MM-DD"),
          type: "date",
          label: "날짜",
        },
        {
          name: ["endDate"],
          value: moment(new Date()).format("YYYY-MM-DD"),
          type: "date",
        },
        {
          name: ["searchFilter"],
          filter: updateSearchFilterEnum,
          value: Object.values(updateSearchFilterEnum)[0],
          type: "select",
        },
      ];

      setSearchFilter(Object.values(updateSearchFilterEnum)[0]);
      setDetail("*");
      setTableSearchItems(searchItems);
      setRefresh((prev) => !prev);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (tableSearchItems.length > 0) {
        let refinedSearchItems = [
          {
            name: ["startDate"],
            value: moment(tableSearchItems[0].value).format("YYYY-MM-DD"),
            type: "date",
            label: "날짜",
          },
          {
            name: ["endDate"],
            value: moment(tableSearchItems[1].value).format("YYYY-MM-DD"),
            type: "date",
          },
          {
            name: ["searchFilter"],
            filter: searchFilterEnum,
            value: searchFilter,
            type: "select",
          },
        ];

        let updateDetail = "";

        if (searchFilter === 0) {
          updateDetail = "*";
        }
        if (searchFilter === 1) {
          updateDetail = Object.values(detailLogTypeEnum)[0];
          refinedSearchItems = [
            ...refinedSearchItems,
            {
              name: ["detailFilter"],
              filter: detailLogTypeEnum,
              value: Object.values(detailLogTypeEnum)[0],
              type: "select",
            },
          ];
        }
        if (searchFilter === 2) {
          updateDetail = Object.values(logTypeEnum)[0];
          refinedSearchItems = [
            ...refinedSearchItems,
            {
              name: ["detailFilter"],
              filter: logTypeEnum,
              value: Object.values(logTypeEnum)[0],
              type: "select",
            },
          ];
        }
        if (searchFilter === 3) {
          updateDetail = Object.values(resultLogTypeEnum)[0];
          refinedSearchItems = [
            ...refinedSearchItems,
            {
              name: ["detailFilter"],
              filter: resultLogTypeEnum,
              value: Object.values(resultLogTypeEnum)[0],
              type: "select",
            },
          ];
        }
        if (searchFilter === 4) {
          updateDetail = "";
          refinedSearchItems = [
            ...refinedSearchItems,
            {
              name: ["detailFilter"],
              placeholder: ["IP"],
              value: "",
              type: "text",
            },
          ];
        }
        setDetail(updateDetail);
        setTableSearchItems(refinedSearchItems);
      }
    })();
  }, [searchFilter]);

  useEffect(() => {
    if (tableSearchItems.length > 0) {
      (async () => {
        //유효하지 않은 날짜 체크
        if(!moment(tableSearchItems[0].value).isValid() || !moment(tableSearchItems[1].value).isValid()){
          return;
        }

        const oprLogResponse = await request.get(`logs/audit`, {
          params: {
            page: page - 1,
            size: pageSize,
            sort: sortParams,
            start_date: moment(tableSearchItems[0].value).format("YYYYMMDD"),
            end_date: moment(tableSearchItems[1].value).format("YYYYMMDD"),
            type: searchFilter,
            detail: detail.trim(),
          },
        });
        if (oprLogResponse.data.data.content.length !== 0) {
          const convertLogResultCodeToDisplayText = {};
          const logResultCode = await request.get(`commons/log/result`);
          logResultCode.data.data.map((data) => {
            convertLogResultCodeToDisplayText[data.desc] = data.display;
          });

          oprLogResponse.data.data.content.map((data) => {
            data["contents"] = JSON.stringify(data.contents);
            data["result_code"] =
              convertLogResultCodeToDisplayText[data["result_code"]];
            data["log_code"] = `${data.log_code}`;
          });
        }
        _setRows(oprLogResponse.data.data.content);
        setTotalPages(oprLogResponse.data.data.totalPages);
        setTotalElements(oprLogResponse.data.data.totalElements);
      })();
    }
  }, [refresh, page, sortParams]);

  // 최종 화면 나오는 곳
  return (
    <>
      <div className="wrapper">
        {<History title={title} breadcrumb={breadcrumb} />}
        <hr />
        {
          <Table
            rows={rows}
            columns={columns}
            getTotalRows={totalElements}
            search={tableSearchItems}
            onChangeSearchValue={onChangeSearchValue}
            onClickSearchButton={onClickSearchButton}
            page={page}
            totalPages={totalPages}
            changePage={setPage}
            sortParams={sortParams}
            setSortParams={setSortParams}
          />
        }
      </div>
    </>
  );
};

export default AuditLog;
