import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {forwardRef} from "react";
import {ko} from "date-fns/locale/ko";

const ReactDatePicker = ({startDate, setStartDate}) => {
    const renderMonthContent = (month, shortMonth) => {
        return <span>{shortMonth}</span>;
    };

    const CustomInput = forwardRef(({value, onClick}, ref) => (
        <button className="custom-input" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    return (
        <div>
            <DatePicker
                locale={ko}
                selected={startDate}
                renderMonthContent={renderMonthContent}
                onChange={(date) => setStartDate(date)}
                showMonthYearPicker
                dateFormat="yyyy-MM"
                customInput={<CustomInput/>}
            />
        </div>
    );
};

export default ReactDatePicker;