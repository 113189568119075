export const DefaultPageSize = 10;
export const DefaultModalPageSize = 10;
export const DefaultSearchDay = 7;

export const MessageCollection = {
    CREATE: "정상적으로 등록되었습니다.",
    MODIFY: "정상적으로 수정되었습니다.",
    DELETE: "정상적으로 삭제되었습니다.",
    NORMAL: "요청이 정상적으로 처리되었습니다.",
    COPY: "텍스트가 복사되었습니다.",
    AGREE: "미동의를 동의상태로 변경할 수 없습니다.",
    CHECKURL: "URL 양식에 맞게 입력해주세요.",
    INITOAUTH: "Client_secret 정보를 정상적으로 수정했습니다.",
};

export const MessageColor = {
    RED: "#cf2323",
};

export const ButtonPlaceHolder = {
    HOSP: "의료기관 선택",
    CORP: "기업 선택",
    ALL: "기관 선택",
    ALL_R: "기관 선택(필수)",
    DTX : "DTx 선택",
    HOSP_R: "의료기관 선택(*필수)",
    CORP_R: "기업 선택(*필수)",
    SERVICE: "서비스 선택",
};

export const OrgModalName = {
    HOSP: "의료기관 조회",
    CORP: "기업 조회",
    DTX : "DTX 조회",
    ALL: "기관 조회",
    SERVICE:"서비스 조회"
};

export const UnSelectOrgMsg = "의료기관을 먼저 선택해주세요.";

export const copyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
};

export const isValidId = (id) => {
    const idRegex = /^[a-zA-Z][a-zA-Z0-9]{5,19}$/;
    return idRegex.test(id);
};

export const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
};

export const isValidPassword = (password) => {
    //9~16자 최소 하나의 대문자, 소문자, 숫자, 특수문자를 포함해야 함
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,16}$/;
    return passwordRegex.test(password);
};
export const isValidPhoneNumber = (phoneNumber) => {
    const phoneNumberRegex =
        /^(\d{2}-\d{3}-\d{4}|\d{2}-\d{4}-\d{4}|\d{3}-\d{3}-\d{4}|\d{3}-\d{4}-\d{4})$/;
    return phoneNumberRegex.test(phoneNumber);
};

//ex) 1599-6114
export const isValidTelNumber = (telNumber) => {
    const telNumberRegex = /^\d{3,4}-\d{4}$/;
    return telNumberRegex.test(telNumber);
};

export const isURL = (str) => {
    const urlPattern =
        /^(http|https):\/\/[\w\-]+(\.|\/[\w\-]+)+[/#?]?.*$/;
    // /^(https?:\/\/)?([a-zA-Z0-9.-_]+)\.([a-zA-Z]{2,})(:[0-9]{2,5})?(\/[a-zA-Z0-9/_.-]*)*$/;
    return urlPattern.test(str);
};

export const isIp = (str) => {
    const ipPattern =
        /^(1|2)?\d?\d([.](1|2)?\d?\d){3}$/;
    return ipPattern.test(str);
};


export const isDBURL = (str) => {
    const urlPattern =
        /^(mongodb|jdbc:postgresql|jdbc:mariadb):\/\/[a-zA-Z0-9.-]+(:\d+)?\/[a-zA-Z0-9_]+$/;
    return urlPattern.test(str);
};

export const isPositiveInteger = (input) => {
    const positiveIntegerPattern = /^(0|[1-9]\d*)$/;
    return positiveIntegerPattern.test(input);
};

export const isNumber = (input) => {
    const numberPattern = /^(0|[1-9]\d{0,7}|100000000)?\s*$/;
    return numberPattern.test(input);
};

export const isRegularStringLength = (input, minLength, maxLength) => {
    const stringLength = input.length;
    return stringLength >= minLength && stringLength <= maxLength;
};

export const DefaultSortParams = {
    DEFAULT: "create_at,desc",
    LOG: "log_date_time,desc",
    PRCP: "prcp_recv_dt,desc",
    LAST: "result_last_recv_dt,desc",
    SELFAUTH: "agree_dt,desc",
    ALERT: "end_time,desc",
    EXTPRCP:"received_time,desc",
};

export const checkMaxLength = (input, maxLen) => {
    const pattern = new RegExp(`^.{0,${maxLen}}$`);
    return pattern.test(input);
};

export const isBizno = (input) => {
    const pattern = /^\d{3}-\d{2}-\d{5}$/;
    return pattern.test(input);
};

export const checkNumberWithin365WithSpaces = (input) => {
    if (!input) {
        return true;
    }

    const numberValue = parseInt(input, 10);
    return (
        /^[0-9\s]+$/.test(input) &&
        input.trim() !== "00" &&
        numberValue >= 0 &&
        numberValue <= 365
    );
};
