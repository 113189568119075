import React from "react";
import Input from "../../atoms/Input/Input";

const CreateAgreementModal = ({
                                  modifyEnum,
                                  createAgreeRule,
                                  modifyAgreeRule,
                                  agreeList,
                                  closeModal,
                                  isChecked,
                                  agreeCheckList,
                                  agreeConfigData,
                                  modalType,
                                  onClickOrgFilter,
                                  onChangeAgreeConfigData,
                                  openSelectOrgModal,
                                  errorMessage,
                                  setAgreeCheckList
                              }) => {
    const updateSet = (set, id) => {
        const updatedSet = new Set(set);
        if (updatedSet.has(id)) {
            updatedSet.delete(id);
        } else {
            updatedSet.add(id);
        }

        return updatedSet;
    };
    const handleOnChange = (index, id) => {
        setAgreeCheckList((prevSet) => updateSet(prevSet, id));
    };

    return (
        <>
            <ul className="form_wrap">
                <li>
                    <label className="name">의료기관{modalType === "create" ?
                        <sup className="required">*</sup> : ""}</label>
                    <div style={{marginBottom: "0px"}} className="search_box">
                        <div key={agreeConfigData.provider_org_id} className="search_wrap">
                            <Input
                                type="text"
                                name="provider_org_name"
                                value={agreeConfigData.provider_org_name}
                                className="input_search_modal"
                                disabled={modalType !== "create"}
                                placeholder={"의료기관 선택"}
                                autoComplete="off"
                                style={{
                                    backgroundColor: "#FAFAFA",
                                    color: "#666670",
                                    cursor: modalType === "create" ? "pointer" : "default",
                                }}
                                onClick={() => onClickOrgFilter("HOSP", true)}
                                onChange={onChangeAgreeConfigData}
                                errorMessage={errorMessage}
                            />
                            {modalType === "create" ? (
                                <button
                                    className="btn_search"
                                    onClick={openSelectOrgModal}
                                ></button>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </li>
                <li>
                    <label className="name">기업{modalType === "create" ?
                        <sup className="required">*</sup> : ""}</label>
                    <div style={{marginBottom: "0px"}} className="search_box">
                        <div key={agreeConfigData.used_org_id} className="search_wrap">
                            <Input
                                type="text"
                                name="used_org_name"
                                value={agreeConfigData.used_org_name}
                                className="input_search_modal"
                                placeholder={"업체 선택"}
                                autoComplete="off"
                                style={{
                                    backgroundColor: "#FAFAFA",
                                    color: "#666670",
                                    cursor: modalType === "create" ? "pointer" : "default",
                                }}
                                onClick={() => modalType === "create" ? onClickOrgFilter("CORP", true) : ""}
                                disabled={modalType !== "create"}
                                onChange={onChangeAgreeConfigData}
                                errorMessage={errorMessage}
                            />
                            {modalType === "create" ? (
                                <button
                                    className="btn_search"
                                    onClick={openSelectOrgModal}
                                ></button>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </li>
                <li>
                    <label className="name">서비스{modalType === "create" ?
                        <sup className="required">*</sup> : ""}</label>
                    <select
                        id="service_type"
                        name="service_type"
                        required="required"
                        value={agreeConfigData.service_type}
                        onChange={onChangeAgreeConfigData}
                        disabled={modalType !== "create"}
                    >
                        {Object.entries(modifyEnum).map((org) => {
                            return (
                                <option key={org[0]} value={org[1]}>
                                    {org[0]}
                                </option>
                            );
                        })}
                    </select>
                </li>
                <li>
                    <label className="name desc">정보 제공 항목<span>정보 제공 항목 미선택 시, 전체 동의로 처리됩니다.</span></label>
                </li>
                <li>
                    <ul className={"agree_ul"}>
                        {agreeList.map((item, idx) => (
                            <li key={idx}>

                                <input
                                    id={item.profile}
                                    type="checkbox"
                                    checked={agreeCheckList.has(item.profile)}
                                    disabled={isChecked}
                                    onChange={() => handleOnChange(idx, item.profile, "agree")}
                                />
                                <label
                                    htmlFor={item.profile}>{item.display}
                                    {item.required === "Required" ? <sup className="required">*</sup> : ""}
                                    <span>{item.desc ? item.desc : ""}</span>
                                </label>
                            </li>
                        ))}
                    </ul>

                </li>
            </ul>
            <div className="btn_wrap">
                <div className="left"></div>
                <div className="center"></div>
                <div className="right">
                    <button className="btn btn_gray_line" onClick={closeModal}>
                        취소
                    </button>
                    <button className="btn btn_black" onClick={modalType ==="create"? createAgreeRule : modifyAgreeRule}>
                        저장
                    </button>
                </div>
            </div>
        </>
    );
};

export default CreateAgreementModal;
