import React from "react";
import Input from "../../atoms/Input/Input";

export const CreateExtServiceModal = ({
                                          serviceData,
                                          setServiceData,
                                          serviceList,
                                          onChangeServiceData,
                                          onClickCorpFilter,
                                          onClickHospFilter,
                                          createService,
                                          modifyService,
                                          closeModal,
                                          openSelectOrgModal,
                                          modalType,
                                          errorMessage,
                                      }) => {
    const handleOnChange = (id) => {
        setServiceData((prevState) => ({
            ...prevState,
            service_type: id
        }));
    };
    return (
        <>
            {/*연계서비스 등록*/}
            <ul className="form_wrap mb50">
                <li>
                    <label className="name">연계서비스 기업{modalType === "create" ? <sup className="required">*</sup> : ""}</label>
                    <div style={{marginBottom: "0px"}} className="search_box">
                        <div key={serviceData.provider_org_id} className="search_wrap">
                            <Input
                                type="text"
                                name="provider_org_name"
                                value={serviceData.provider_org_name}
                                className="input_search_modal"
                                placeholder={"연계서비스 기업 선택"}
                                autoComplete="off"
                                style={{
                                    backgroundColor: "#FAFAFA",
                                    color: "#666670",
                                    cursor: modalType === "create" ? "pointer" : "default",
                                }}
                                onClick={() => modalType === "create" ? onClickCorpFilter("corp") : ""}
                                disabled={modalType !== "create"}
                                onChange={onChangeServiceData}
                                errorMessage={errorMessage}
                            />
                            {modalType === "create" ? (
                                <button
                                    className="btn_search"
                                    onClick={openSelectOrgModal}
                                ></button>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </li>
                <li>
                    <label className="name">의료기관{modalType === "create" ?
                        <sup className="required">*</sup> : ""}</label>
                    <div style={{marginBottom: "0px"}} className="search_box">
                        <div key={serviceData.user_org_id} className="search_wrap">
                            <Input
                                type="text"
                                name="user_org_name"
                                value={serviceData.user_org_name}
                                className="input_search_modal"
                                disabled={modalType !== "create"}
                                placeholder={"의료기관 선택"}
                                autoComplete="off"
                                style={{
                                    backgroundColor: "#FAFAFA",
                                    color: "#666670",
                                    cursor: modalType === "create" ? "pointer" : "default",
                                }}
                                onClick={() => onClickHospFilter("hosp")}
                                onChange={onChangeServiceData}
                                errorMessage={errorMessage}
                            />
                            {modalType === "create" ? (
                                <button
                                    className="btn_search"
                                    onClick={openSelectOrgModal}
                                ></button>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </li>
                <li>
                    <label className="name">서비스{modalType === "create" ?
                        <sup className="required">*</sup> : ""}</label>
                    <ul className={"result_ul"}>
                        {serviceList.map((item, idx) => (
                            <li key={idx}>
                                <label htmlFor={item.display}>{item.display}</label>
                                <input
                                    name={"service_type"}
                                    id={item.id}
                                    type="radio"
                                    checked={serviceData.service_type === item.id}
                                    onChange={() => handleOnChange(item.id)}
                                    disabled={modalType !== "create"}
                                />
                            </li>
                        ))}
                    </ul>
                    {errorMessage.length !== 0 && errorMessage[0] === "service_type" ?
                        <p className="warning"
                           style={{marginLeft: "235px"}}
                        >서비스를 선택해주세요.</p> : ""}
                </li>
                <li>
                    <label className="name">서비스명<sup className="required">*</sup></label>
                    <Input
                        type="text"
                        name="service_name"
                        value={serviceData.service_name}
                        placeholder={"서비스명(최대 20글자)"}
                        maxLength={"20"}
                        onChange={onChangeServiceData}
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">API url<sup className="required">*</sup></label>
                    <textarea
                        className="api_textarea"
                        name="url"
                        maxLength={1024}
                        value={serviceData.url}
                        placeholder={"ex) https://www.api.com/api/v1/?user=12345"}
                        onChange={onChangeServiceData}
                    ></textarea>
                    <p className="warning" style={{paddingLeft: "255px"}}
                    > {errorMessage && errorMessage[0] === "url" ? errorMessage[1] : ""} </p>
                </li>
            </ul>
            <div className="btn_wrap">
                <div className="left"></div>
                <div className="center"></div>
                <div className="right">
                    <button className="btn btn_gray_line" onClick={closeModal}>
                        취소
                    </button>
                    <button
                        className="btn btn_black"
                        onClick={modalType === "create" ? createService : modifyService}
                    >
                        저장
                    </button>
                </div>
            </div>
        </>
    );
};

export default CreateExtServiceModal;
