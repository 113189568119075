import React, {useEffect, useState} from 'react';
import ApexChart from "react-apexcharts";
import logCode from "../../../assets/resources/logCode.json";

function ServiceUsageChart({chartData, monthlyCount,selectedMonth}) {
    const logType = logCode;
    const apiData = [];
    const labelsData = [];
    logType.map((data) => {
        if (data.value.split(":")[0] === "emr" &&  data.history !== false || data.value.split(":")[0] === "dtx") {
            apiData.push(data.value);
            labelsData.push(data.display);
        }
    });
    const [colorArr, setColorArr] = useState([]);
    const colorData = {
        "emr:021": "#B1CEFBFF",
        "emr:022": "#007CFBFF",
        "emr:031": "#005FC2FF",
        "dtx:021": "#004891FF",
        "dtx:031": "#F7F29FFF",
    };

    useEffect(() => {
        setColorArr(chartData.map((el) => colorData[el.code]));
    }, [chartData]);
    const refinedlabelsData = chartData.map(item => item.display)
    const seriesData = chartData.map(item => item.sub_total);

    return (
        monthlyCount && monthlyCount.total > 0 ? (
            <>
                <table className={"color_table"}>
                    <thead>
                    <tr>
                        <th></th>
                        <th>API</th>
                        <th>금액</th>
                    </tr>
                    </thead>
                    <tbody>
                    {apiData && apiData.map((data, index) => {
                        return (
                            <>
                                <tr key={data}>
                                    <td style={{backgroundColor: colorData[apiData[index]]}}></td>
                                    <td>{labelsData[index]}</td>
                                    <td>0</td>
                                </tr>
                            </>
                        )
                    })}
                    </tbody>
                </table>
                <ApexChart
                    width="400px"
                    height="400px"
                    type="donut"
                    series={seriesData}
                    options={{
                        title:{
                            text:selectedMonth + "월",
                            floating :true,
                            // offsetX: 173,
                            offsetY :180,
                            align:"center",
                            style :{
                                fontSize : "24px",
                            }
                        },
                        plotOptions:{
                            pie:{
                                donut:{
                                    size:"55%",
                                }
                            },
                        },
                        chart: {
                            toolbar: {show: false},
                            background: "transparent",
                            animations: {
                                enabled: false,
                            },
                        },
                        dataLabels: { //파이차트 위 데이터표시
                            enabled: false,
                        },
                        legend: {
                            show: false
                        },
                        labels: refinedlabelsData,
                        colors: colorArr,
                        markers: {
                            size: 5,
                        },
                        tooltip: {
                            enabled: true,
                        },
                        stroke: {
                            show: false,
                        },

                    }}
                />
            </>
        ) : <>
            <ApexChart
                width="300px"
                height="300px"
                type="donut"
                series={[0]}
                options={{
                    chart: {
                        toolbar: {show: false},
                        background: "transparent",
                        animations: {
                            enabled: false,
                        },
                    },
                    dataLabels: { //파이차트 위 데이터표시
                        enabled: false,
                    },
                    legend: {
                        show: true
                    },
                    labels: ["데이터 없음"],
                    colors: colorData[0],
                    tooltip: {
                        enabled: false,
                    },
                    stroke: {
                        show: false,
                    },
                }}
            />
        </>
    )
}

export default ServiceUsageChart;
