import React from "react";


const Badge = (org_type, data) => {
    const badgeStyles = {
        HOSP: "badge_blue", CORP: "badge_green", default: "badge_black",
    };
    const badgeClass = badgeStyles[org_type] || badgeStyles.default;
    return (
        <span className={`badge ${badgeClass}`}>
                          {data}
                         </span>
    )
}

export default Badge;