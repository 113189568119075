import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import moment from "moment";
import {
    ButtonPlaceHolder,
    DefaultModalPageSize,
    DefaultPageSize,
    DefaultSearchDay,
    DefaultSortParams,
    MessageCollection,
    OrgModalName,
    copyToClipboard, MessageColor,
} from "../components/Default/DefaultValue";
import {AlertInfoState} from "..";
import {useRecoilState} from "recoil";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";

const ExtServicePrcpView = () => {
    const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
    const [modalRefresh, setModalRefresh] = useState(false);
    const [message, setMessage] = useRecoilState(AlertInfoState);

    // 제목, 브레드크럼
    const title = "연계서비스 처방정보";
    const breadcrumb = [
        {name: "HOME", link: "./"},
        {name: "데이터 조회"},
        {name: "연계서비스 처방정보", link: "./ExtServicePrcpView"},
    ];

    const pageSize = DefaultPageSize;

    // 모달
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState("");
    const closeModal = () => {
        setModalType("");
        setOpenModal((openModal) => !openModal);
    };

    // 테이블 관련 변수
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [sortParams, setSortParams] = useState(DefaultSortParams.EXTPRCP);

    // 모달 테이블 관련 변수
    const [modalTotalElements, setModalTotalElements] = useState(0);
    const [modalPage, setModalPage] = useState(1);
    const [modalTotalPages, setModalTotalPages] = useState(5);
    const [modalSortParams, setModalSortParams] = useState(
        DefaultSortParams.DEFAULT
    );

    // 검색조회부분
    const [tableSearchItems, setTableSearchItems] = useState([]);
    // 서브 모달 검색 조회
    const [modalTableSearchItems, setModalTableSearchItems] = useState([
        {
            name: ["orgName"],
            placeholder: ["기관명"],
            value: "",
            type: "text",
        },
    ]);

    // td 내용
    const [rows, _setRows] = useState([]);
    const [orgRows, _setOrgRows] = useState([]);

    // header,body 변경 th 내용
    const columns = [
        {
            name: "hosp_name",
            header: "의료기관",
            width: "12%",
        },

        {
            name: "corp_name",
            header: "연계서비스 기업",
            width: "12%",
        },
        {
            name: "service_type",
            header: "서비스 유형",
            width: "10%",
        },
        {
            name: "request_type",
            header: "요청 유형",
            width: "10%",
        },
        {
            name: "extension.prescriptionId",
            header: "처방번호",
            width: "14%",
            customBody: (row) => (
                <td key="hosp_prcp_seq">
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div className="ellipsis">{row.extension.prescriptionId}</div>
                        <div
                            className="attachIcon"
                            style={{width: "18px"}}
                            onClick={() => {
                                copyToClipboard(row.extension.prescriptionId);
                                setMessage({message: MessageCollection.COPY});
                            }}
                        ></div>
                    </div>
                </td>
            ),
        },
        {
            name: "phi_code",
            header: "PHI코드",
            width: "16%",
            customBody: (row) => (
                <td key="phi_code">
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div className="ellipsis">{row.phi_code}</div>
                        <div
                            className="attachIcon"
                            style={{width: "18px"}}
                            onClick={() => {
                                copyToClipboard(row.phi_code);
                                setMessage({message: MessageCollection.COPY});
                            }}
                        ></div>
                    </div>
                </td>
            ),
        },
        {
            name: "received_time",
            header: "수신일",
            width: "15%",
        },
        {
            name: "batch_state",
            header: "상태",
            width: "*",
            customBody:(row)=>(
                <td key="batch_state">{row.batch_state === "Init" ? "준비" : row.batch_state === "Complete" ? "완료": "실패"}</td>
            )
        },
    ];

    // 입력창값이 변경될때
    const onChangeSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickSearchButton();
        }
        const {name, value} = event.target;
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
        if (name === "startDate") {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "endDate") {
                        return {
                            ...item,
                            value:
                                item.value > moment(value).add(30, "day").format("YYYY-MM-DD")
                                    ? moment(value).add(30, "day").format("YYYY-MM-DD")
                                    : item.value < value
                                        ? value
                                        : item.value,
                        };
                    }
                    return item;
                })
            );
        }
    };

    const selectOrg = (row) => {
        const type = row.org_type;
        if (type === "HOSP") {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "hospFilter") {
                        return {...item, value: row.oid, display: row.org_name};
                    }
                    return item;
                })
            );
        } else {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "corpFilter") {
                        return {...item, value: row.biz_no, display: row.org_name};
                    }
                    return item;
                })
            );
        }
        closeModal();
    };

    // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
    const onClickSearchButton = () => {
        const start = tableSearchItems[0].value;
        const end = tableSearchItems[1].value;
        const diff = moment(end).diff(moment(start), "days")

        if(diff > 30){
            setMessage({
                message: "최대 조회기간은 30일입니다.",
                color: MessageColor.RED,
            })
        }else if(diff < 0){
            setMessage({
                message: "종료날짜는 시작날짜보다 이전일 수 없습니다.",
                color: MessageColor.RED,
            })
        }else {
            setPage(1);
            setRefresh((prev) => !prev);
        }
    };

    const onChangeModalSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickModalSearchButton();
        }
        const {name, value} = event.target;
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    const onClickModalSearchButton = () => {
        setModalPage(1);
        setModalRefresh((prev) => !prev);
    };

    const onClickOrgFilter = (type) => {
        setModalType(type);
        setModalPage(1);
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgName") {
                    return {...item, value: ""};
                }
                return item;
            })
        );
        setOpenModal(true);
    };

    const onClickOrgFilterForRemove = (type) => {
        if (type === "HOSP") {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "hospFilter") {
                        return {...item, value: "*", display: ButtonPlaceHolder.HOSP};
                    }
                    return item;
                })
            );
        } else {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "corpFilter") {
                        return {...item, value: "*", display: ButtonPlaceHolder.CORP};
                    }
                    return item;
                })
            );
        }
    };

    useEffect(() => {
        (async () => {
            let updateServiceTypeEnum = {"전체 연계서비스": "*"};
            try {
                const svrEnumResponse = await request.get(`commons/type/service`);
                const deleteDtxEnum = svrEnumResponse.data.data.filter((item) => item.id !== "DTx")
                deleteDtxEnum.map((data) => {
                    updateServiceTypeEnum[data.display] = data.id;
                });
                let searchItems = [
                    {
                        name: ["startDate"],
                        value: moment(new Date())
                            .subtract(DefaultSearchDay, "day")
                            .format("YYYY-MM-DD"),
                        type: "date",
                        label: "수신일",
                    },
                    {
                        name: ["endDate"],
                        value: moment(new Date()).format("YYYY-MM-DD"),
                        type: "date",
                    },
                    {
                        name: ["hospFilter"],
                        type: "labelButton",
                        onClick: () => {
                            onClickOrgFilter("HOSP")
                        },
                        onClickRemove: () => {
                            onClickOrgFilterForRemove("HOSP")
                        },
                        display: ButtonPlaceHolder.HOSP,
                        value: "*",
                    },
                    {
                        name: ["corpFilter"],
                        type: "labelButton",
                        onClick: () => {
                            onClickOrgFilter("CORP")
                        },
                        onClickRemove: () => {
                            onClickOrgFilterForRemove("CORP")
                        },
                        display: "연계서비스 기업 선택",
                        value: "*",
                    },
                    {
                        name: ["svcFilter"],
                        filter: updateServiceTypeEnum,
                        value: Object.values(updateServiceTypeEnum)[0],
                        type: "select",
                    },
                    {
                        name: ["prescriptionId", "phiCode"],
                        placeholder: ["처방번호, PHI코드"],
                        value: "",
                        type: "text",
                    },
                ];

                setTableSearchItems(searchItems);
                setRefresh((prev) => !prev);

            } catch (error) {

            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const organsResponse = await request.get("organs", {
                params: {
                    page: modalPage - 1,
                    size: DefaultModalPageSize,
                    sort: modalSortParams,
                    name: modalTableSearchItems[0].value.trim(),
                    type: modalType,
                },
            });
            _setOrgRows(organsResponse.data.data.content);
            setModalTotalPages(organsResponse.data.data.totalPages);
            setModalTotalElements(organsResponse.data.data.totalElements);
        })();
    }, [openModal, modalPage, modalSortParams, modalRefresh]);

    useEffect(() => {
        if (tableSearchItems.length > 0) {
            (async () => {
                //유효하지 않은 날짜 체크
                if (!moment(tableSearchItems[0].value).isValid() || !moment(tableSearchItems[1].value).isValid()) {
                    return;
                }
                const codesData = await request.get(`ext-services/prcps`, {
                    params: {
                        page: page - 1,
                        size: pageSize,
                        sort: sortParams,
                        start_date: moment(tableSearchItems[0].value).format("YYYYMMDD"),
                        end_date: moment(tableSearchItems[1].value).format("YYYYMMDD"),
                        oid: tableSearchItems[2].value,
                        biz: tableSearchItems[3].value,
                        service: tableSearchItems[4].value,
                        search_word_like:tableSearchItems[5].value.trim(),

                    },
                });
                _setRows(codesData.data.data.content);
                setTotalPages(codesData.data.data.totalPages);
                setTotalElements(codesData.data.data.totalElements);
            })();
        }
    }, [refresh, page, sortParams]);

    // 최종 화면 나오는 곳
    return (
        <>
            <div className="wrapper">
                {<History title={title} breadcrumb={breadcrumb}/>}
                <hr/>
                {
                    <Table
                        rows={rows}
                        columns={columns}
                        getTotalRows={totalElements}
                        search={tableSearchItems}
                        onChangeSearchValue={onChangeSearchValue}
                        onClickSearchButton={onClickSearchButton}
                        page={page}
                        totalPages={totalPages}
                        changePage={setPage}
                        sortParams={sortParams}
                        setSortParams={setSortParams}
                    />
                }
            </div>
            {
                <Modal
                    open={openModal}
                    close={closeModal}
                    title={modalType === "HOSP" ? OrgModalName.HOSP : OrgModalName.CORP}
                    content={
                        <SelectOrgModal
                            rows={orgRows}
                            orgType={modalType}
                            selectOrg={selectOrg}
                            getTotalRows={modalTotalElements}
                            search={modalTableSearchItems}
                            onChangeSearchValue={onChangeModalSearchValue}
                            onClickSearchButton={onClickModalSearchButton}
                            page={modalPage}
                            limit={DefaultModalPageSize}
                            totalPages={modalTotalPages}
                            changePage={setModalPage}
                            sortParams={modalSortParams}
                            setSortParams={setModalSortParams}
                        ></SelectOrgModal>
                    }
                />
            }
        </>
    );
};

export default ExtServicePrcpView;
