import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import {AlertErrorState, AlertInfoState} from "..";
import {useRecoilState} from "recoil";
import {
    ButtonPlaceHolder,
    DefaultModalPageSize,
    DefaultPageSize,
    DefaultSortParams,
    isDBURL,
    MessageCollection,
    OrgModalName,
} from "../components/Default/DefaultValue";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";
import CreateAlertRuleModal from "../components/templates/CreateExtServiceModal/CreateAlertRuleModal";
import Input from "../components/atoms/Input/Input";


const ManageAlertRule = () => {
    const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
    const [modalRefresh, setModalRefresh] = useState(false);
    const [alart, setAlert] = useRecoilState(AlertErrorState);
    const [message, setMessage] = useRecoilState(AlertInfoState);
    const [errorMessage, setErrorMessage] = useState([]);
    const [orgName, setOrgName] = useState("");
    const [totalHeaderElements, setTotalHeaderElements] = useState(0);
    const [orgId, setOrgId] = useState("");
    const [isChecked, setIsChecked] = useState(false);

    // 제목, 브레드크럼
    const title = "알림 정책";
    const breadcrumb = [
        {name: "HOME", link: "./"},
        {name: "서비스 설정"},
        {name: "알림 정책", link: "./ManageAlertRule"},
    ];

    const pageSize = DefaultPageSize;
    const [convertProfileToDisplay, setConvertProfileToDisplay] = useState({});
    // 모달
    const [modalType, setModalType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => {
        setSvcCheckedList(new Set());
        setOrgName("");
        setSelectType("");
        setErrorMessage([]);
        setOpenModal((openModal) => !openModal);
    };

    // 서브 모달
    const [openSubModal, setOpenSubModal] = useState(false);
    const closeSubModal = () => {
        setOpenSubModal((openModal) => !openModal);
    };

    //Alert 모달
    const [openAlert, setOpenAlert] = useState(false);
    const closeAlert = () => {
        setOpenAlert(false);
    };

    // 모달 테이블 관련 변수
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [sortParams, setSortParams] = useState("change_time,desc");

    // 서브 모달 테이블 관련 변수
    const [subModalType, setSubModalType] = useState("HOSP");
    const [selectType, setSelectType] = useState("");
    const [modalTotalElements, setModalTotalElements] = useState(0);
    const [modalPage, setModalPage] = useState(1);
    const [modalTotalPages, setModalTotalPages] = useState(5);
    const [modalSortParams, setModalSortParams] = useState(
        DefaultSortParams.DEFAULT
    );

    //서비스 체크박스
    const [serviceList, setServiceList] = useState([]);
    const [svcCheckedList, setSvcCheckedList] = useState(new Set());
    // 검색조회부분
    const [tableSearchItems, setTableSearchItems] = useState([]);
    // 서브 모달 검색 조회
    const [modalTableSearchItems, setModalTableSearchItems] = useState([
        {
            name: ["orgName"],
            placeholder: ["기관명"],
            value: "",
            type: "text",
        },
    ]);

    const initSendInfoData = {
        url: "",
        user: "",
        password: "",
        table: "",
        colum_info: {
            send_time: "",
            message: ""
        },
    };
    const initHttpSendInfoData = {
        url: "",
        headers: {},
        timeout: 30,
        cert_validate: false
    };
    const initRuleData = {
        service_list: [],
        send_info: {
            info: {initSendInfoData},
            type: "db",
        },
        org_id: "",
        org_name: ""
    };


    const [ruleData, setRuleData] = useState(initRuleData);
    const [sendInfoData, setSendInfoData] = useState(initSendInfoData);
    const [httpSendInfoData, setHttpSendInfoData] = useState(initHttpSendInfoData);
    const [alertTypeList, setAlertTypeList] = useState([]);
    const [headerRows, setHeaderRows] = useState([]);

    // td 내용
    const [rows, _setRows] = useState([]);
    const [orgRows, _setOrgRows] = useState([]);

    // header,body 변경 th 내용
    const columns = [
        {
            name: "org_name",
            header: "기관",
            width: "15%",
        },
        {
            name: "send_info.type",
            header: "알림 전송 타입",
            width: "13%",
            customBody: (row) => (
                <td>{row.send_info?.type}</td>
            ),
        },
        {
            name: "service_list",
            header: "서비스",
            width: "25%",
            customBody: (row) => (
                <td key={"service_list"}>
                    {row.service_list && row.service_list.map((el,index)=> row.service_list.length-1 !== index ? el +", " : el)}
                </td>
            ),
            sort: false
        },
        {
            name: "change_time",
            header: "정책 변경일",
            width: "15%",
        },
        {
            name: "modify",
            header: "수정",
            width: "8%",
            sort: false,
            customBody: (row) => (
                <td key={"modify"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            setOrgName(row.org_name);
                            void openEditModal(row);
                        }}
                    >
                        수정
                    </label>
                </td>
            ),
        },
        {
            name: "delete",
            header: "삭제",
            width: "8%",
            sort: false,
            customBody: (row) => (
                <td key={"delete"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            void openDeleteModal(row.org_id);
                        }}
                    >
                        삭제
                    </label>
                </td>
            ),
        },
    ];
    const headerColumns = [
        {
            name: "key",
            header: "Key",
            width: "30%",
            customBody: (row) => (
                <td key="key">
                    <Input
                        type="text"
                        className={"w210"}
                        value={row.key}
                        name={"key"}
                        onChange={(event) =>
                            onChangeHeaderSettings({event, row}, "key")
                        }
                    />
                </td>
            ),
        },
        {
            name: "value",
            header: "Value",
            width: "30%",
            customBody: (row) => (
                <td key="value">
                    <Input
                        type="text"
                        className={"w210"}
                        value={row.value}
                        onChange={(event) =>
                            onChangeHeaderSettings({event, row}, "value")
                        }
                    />
                </td>
            ),
        },
        {
            name: "add_rows",
            width: "10%",
            customHeader: () => (
                <th key="add_rows">
                    <div className="action_container">
                        <button className="btn btn_xs btn_black" onClick={addHeader}>
                            행추가
                        </button>
                    </div>
                </th>
            ),
            customBody: (row) => (
                <td key={"delete_rows"} className="action_container">
                    <button
                        className="btn btn_xs btn_gray"
                        onClick={() => {
                            deleteHeader(row.temp_id);
                        }}
                    >
                        행삭제
                    </button>
                </td>
            ),
        },
        {
            name: "temp_id",
            header: "임시 아이디",
            customHeader: () => <th key="temp_id" style={{display: "none"}}></th>,
            customBody: () => <td key="temp_id" style={{display: "none"}}></td>,
        },
    ];

    const addHeader = () => {
        const maxTempId = headerRows.reduce(
            (max, item) => (item.temp_id > max ? item.temp_id : max),
            -1
        );
        setHeaderRows((prevState) => [
            ...prevState,
            {
                key: "",
                value: "",
                temp_id: maxTempId + 1,
            },
        ]);
        setTotalHeaderElements((prev) => prev + 1);
    };
    const onClickModalSearchButton = () => {
        setModalPage(1);
        setModalRefresh((prev) => !prev);
    };

    const selectOrg = (row) => {
        if (selectType === "modal") {
            setRuleData((prevState) => ({
                ...prevState,
                org_id: row.org_id,
                org_name: row.org_name
            }));
        } else {
            setTableSearchItems((prevState) => prevState.map((item) => {
                    if (item.name.join(",") === "filter") {
                        return {...item, value: row.org_id, display: row.org_name};
                    }
                    return item;
                })
            );
        }
        closeSubModal();
    };

    const deleteRule = async () => {
        try {
            await request.delete(`alert-rules/${orgId}`);
            setPage(1);
            setTableSearchItems((prevState) =>
                prevState.map((item, index) => {
                    if (index === 0 || index === 1) {
                        return {...item, value: ""};
                    }
                    return item;
                })
            );
            setRefresh((prev) => !prev);
            setMessage({message: MessageCollection.DELETE});
            closeAlert();

        } catch (error) {
            // setAlert(error);
        }
    };


    const createRule = async () => {
        setErrorMessage([]);
        let refinedRuleData;
        const id = ruleData.org_id;

        if (ruleData.send_info.type === "db") {
            refinedRuleData = {
                ...ruleData,
                send_info: sendInfoData,
                type: ruleData.send_info.type,
                service_list: Array.from(svcCheckedList),
            };
            let flag = true;
            if (flag === true && refinedRuleData.org_id === "") {
                setErrorMessage(["org_name", "필수 입력값입니다."]);
                flag = false;
            }
            if (flag === true && refinedRuleData.service_list.length === 0) {
                setErrorMessage([
                    "service",
                    "서비스 선택해주세요.",
                ]);
                return;
            }

            const checkItems = [
                "url",
                "user",
                "password",
                "table"
            ];
            checkItems.map((data) => {
                if (flag === true && refinedRuleData.send_info[data] === "") {
                    setErrorMessage([data, "필수 입력값입니다."]);
                    flag = false;
                }
            });
            const checkItems2 = [
                "send_time",
                "message",
            ];

            checkItems2.map((data) => {
                if (flag === true && refinedRuleData.send_info.colum_info[data] === "") {
                    setErrorMessage([data, "필수 입력값입니다."]);
                    flag = false;
                }
            });

            if (flag === false) {
                return;
            }
            if (!isDBURL(refinedRuleData.send_info.url)) {
                setErrorMessage(["url", "올바른 url 형식을 입력해주세요. (지원 DB : mongodb,postgresql,mariadb)"]);
                return;
            }

            if (openAlert !== true) {
                setOpenAlert(true);
                return;
            }

            delete refinedRuleData.org_name;
            delete refinedRuleData.org_id;

            //연계서비스 등록 db
            try {
                const enroll = await request.post(`alert-rules/${id}/db`, {
                    ...refinedRuleData
                });

                if (enroll !== undefined) {
                    setPage(1);
                    setMessage({message: MessageCollection.CREATE});
                    setRefresh((prev) => !prev);
                    closeAlert();
                    closeModal();
                }else{
                    closeAlert();
                }

            } catch (error) {
                //catch에 오기전에 axios handler에서 처리됨.
                // setAlert(error);
            }

        } else { //http
            refinedRuleData = {
                ...ruleData,
                send_info: httpSendInfoData,
                service_list: Array.from(svcCheckedList),
            };


            if (refinedRuleData.org_name === "") {
                setErrorMessage(["org_name", "필수 입력값입니다."]);
                return;
            }
            if (refinedRuleData.service_list.length === 0) {
                setErrorMessage([
                    "service",
                    "서비스 선택해주세요.",
                ]);
                return;
            }
            if (refinedRuleData.send_info.url === "") {
                setErrorMessage(["url", "필수 입력값입니다."]);
                return;
            }


            if (openAlert !== true) {
                setOpenAlert(true);
                return;
            }
            delete refinedRuleData.org_name;
            delete refinedRuleData.org_id;


            //연계서비스 등록 http
            try {
                const enroll = await request.post(`alert-rules/${id}/http`, {...refinedRuleData});

                if (enroll !== undefined) {
                    setPage(1);
                    setMessage({message: MessageCollection.CREATE});
                    closeAlert();
                    closeModal();
                    setRefresh((prev) => !prev);
                }
            } catch (error) {
                // setAlert(error);
            }
        }//


    };

    const modifyRule = async () => {
        setErrorMessage([]);
        const type = ruleData.send_info.type;
        const id = ruleData.org_id;

        if (type === "db") {
            const refinedRuleData = {
                ...ruleData,
                send_info: {...sendInfoData},
                type: type,
                service_list: Array.from(svcCheckedList),
            };


            let flag = true;
            const checkItems = [
                "url",
                "user",
                "password",
                "table",
            ];
            checkItems.map((data) => {
                if (flag === true && refinedRuleData.send_info[data] === "") {
                    setErrorMessage([data, "필수 입력값입니다."]);
                    flag = false;
                }
            });
            const checkItems2 = [
                "send_time",
                "message",
            ];
            checkItems2.map((data) => {
                if (flag === true && refinedRuleData.send_info.colum_info[data] === "") {
                    setErrorMessage([data, "필수 입력값입니다."]);
                    flag = false;
                }
            });

            if (flag === false) {
                return;
            }

            if (!isDBURL(refinedRuleData.send_info.url)) {
                setErrorMessage(["url", "올바른 url 형식을 입력해주세요. (지원 DB : mongodb,postgresql,mariadb)"]);
                return;
            }

            delete refinedRuleData.org_name;
            delete refinedRuleData.org_id;
            delete refinedRuleData.format;
            delete refinedRuleData.merge;
            delete refinedRuleData.send_info.iv;

            if (openAlert !== true) {
                setOpenAlert(true);
                return;
            }

            try {
                await request.put(`alert-rules/${id}/db`, {...refinedRuleData});
                setRefresh((prev) => !prev);
                setMessage({message: MessageCollection.MODIFY});
                closeAlert();
                closeModal();
                setSendInfoData(initSendInfoData);
            } catch (error) {
                // setAlert(error);
            }
        } else { //http

            const refinedRuleData = {
                ...ruleData,
                type: type,
                send_info: {...httpSendInfoData},
                service_list: Array.from(svcCheckedList),
            };

            if (refinedRuleData.send_info.url === "") {
                setErrorMessage(["url", "필수 입력값입니다."]);
                return;
            }
            if (openAlert !== true) {
                setOpenAlert(true);
                return;
            }
            delete refinedRuleData.org_name;
            delete refinedRuleData.org_id;

            const id = ruleData.org_id;
            try {
                await request.put(`alert-rules/${id}/http`, {...refinedRuleData});

                setPage(1);
                setTableSearchItems((prevState) =>
                    prevState.map((item, index) => {
                        if (index === 0 || index === 1) {
                            return {...item, value: ""};
                        }
                        return item;
                    })
                );
                setRefresh((prev) => !prev);
                setMessage({message: MessageCollection.MODIFY});
                closeAlert();
                closeModal();
            } catch (error) {
                // setAlert(error);
            }

        }
    };


    // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
    const onClickSearchButton = () => {
        setPage(1);
        setRefresh((prev) => !prev);
    };
    // 테이블에다가 사용자 등록하는 모달 버튼
    const tableButton = {
        name: "알림 정책 등록",
        onClick: () => {
            setModalType("create");
            setErrorMessage([]);
            setSelectType("modal");
            setSendInfoData(initSendInfoData);
            setHttpSendInfoData(initHttpSendInfoData);
            setHeaderRows([]);
            setRuleData(initRuleData);
            setOpenModal(true);
        },
    };

    const onChangeRuleData = (event) => {
        const {name, value} = event.target;

        if (ruleData.send_info.type !== value) {
            setRuleData((prevState) => ({
                ...prevState,
                send_info: {
                    info: value === "db" ? {initSendInfoData} : {initHttpSendInfoData},
                    type: value
                }
            }));
        } else {
            setRuleData((prevState) => ({
                ...prevState
            }));
        }

        // if(name === "type" ){
        //     //원래 내 type과 다를시 init값 넣어주기.
        //     if( value !== myType){
        //     setRuleData((prevState) => ({
        //         ...prevState,
        //         send_info: {
        //             info : value === "db"? {initSendInfoData}: {initHttpSendInfoData},
        //             type : value
        //         }
        //     }));
        //     }
        // }else{
        //     setRuleData((prevState) => ({
        //         ...prevState,
        //         send_info: {
        //             info : ruleData.send_info.info,
        //         }
        //     }));
        // }

        setRuleData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const onChangeSendInfoData = (event) => {
        const {name, value} = event.target;
        if (name === "send_time") {
            setSendInfoData((prevState) => ({
                ...prevState,
                colum_info: {
                    message: sendInfoData.colum_info.message,
                    send_time: value
                },
            }));
        } else if (name === "message") {
            setSendInfoData((prevState) => ({
                ...prevState,
                colum_info: {
                    message: value,
                    send_time: sendInfoData.colum_info.send_time,
                },
            }));
        } else {
            setSendInfoData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };
    const onChangeHttpSendInfoData = (event) => {
        const {name, value, checked} = event.target;
        if (name === "cert_validate") {
            setHttpSendInfoData(prevState => ({
                ...prevState,
                cert_validate: checked
            }))
        } else {
            setHttpSendInfoData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const openEditModal = async (row) => {
        //초기화
        setSendInfoData(initSendInfoData);
        setHttpSendInfoData(initHttpSendInfoData);
        setHeaderRows([]);
        try {
            const selectedRuleDataRes = await request.get(`alert-rules/${row.org_id}`);
            const res = selectedRuleDataRes?.data?.data;
            const addList = new Set();
            res.service_list.forEach(item => addList.add(item));
            setSvcCheckedList(addList);
            //object -> array   object를 key, value 정리해서 array로 만들어주기.
            if (res?.send_info.type === "http") {
                if (res.send_info.info.cert_validate) {
                    setIsChecked(true);
                } else {
                    setIsChecked(false)
                }
                setHttpSendInfoData(res?.send_info.info);
                if (Object.keys(res.send_info.info.headers).length === 0) {
                    setHeaderRows([]);
                    setTotalHeaderElements(0);
                } else {
                    let headerList = [];
                    for (let i = 0; i < Object.keys(res?.send_info?.info.headers).length; i++) {
                        let obj = {};
                        obj.key = Object.keys(res?.send_info?.info?.headers)[i];
                        obj.value = Object.values(res?.send_info?.info?.headers)[i];
                        headerList.push(obj);
                        headerList[i].temp_id = i;
                    }
                    setTotalHeaderElements(headerList.length)
                    setHeaderRows(headerList);
                }
                setRuleData((prevState) => ({
                    ...prevState,
                    org_id: row.org_id,
                    send_info:
                        {
                            type: "http",
                            info: {...httpSendInfoData}
                        },
                    service_list: svcCheckedList
                }))
            } else { //db
                if (res.send_info.info === null) {
                    setSendInfoData((prevState) => ({
                        ...prevState,
                        info: {...res?.send_info.info}
                    }));
                } else {
                    setSendInfoData(res.send_info.info);
                }
                setRuleData((prevState) => ({
                    ...prevState,
                    org_id: row.org_id,
                    send_info:
                        {
                            type: "db",
                            info: {...sendInfoData}
                        },
                    service_list: svcCheckedList
                }))
            }
        } catch (error) {
            // setAlert(error);
        }
        setModalType("modify");
        setOpenModal(true);


    };

    const openDeleteModal = async (id) => {
        setOrgId(id);
        setModalType("delete");
        setOpenAlert(true);
    };

    const deleteHeader = (id) => {
        const updatedHeaderRows = headerRows.filter(
            (item) => item.temp_id !== id
        );
        let headers = {};
        if (updatedHeaderRows.length > 0) {
            updatedHeaderRows.map((item) =>
                headers[item.key] = item.value
            )
        }

        setHttpSendInfoData((prevState) => ({
            ...prevState,
            headers: headers,
        }));
        setHeaderRows(updatedHeaderRows);
        setTotalHeaderElements((prev) => prev - 1);
    };

    const openSelectOrgModal = () => {
        setModalPage(1);
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgName") {
                    return {...item, value: ""};
                }
                return item;
            })
        );
        setOpenSubModal(true);
    };

    const onClickOrgFilter = () => {
        setModalPage(1);
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgName") {
                    return {...item, value: ""};
                }
                return item;
            })
        );
        setOpenSubModal(true);
    };

    const onClickOrgFilterForRemove = () => {
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "filter") {
                    return {...item, value: "*", display: ButtonPlaceHolder.ALL};
                }
                return item;
            })
        );

    };
    // 입력창값이 변경될때
    const onChangeModalSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickModalSearchButton();
        }
        const {name, value} = event.target;
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };


    const onChangeHeaderSettings = ({event, row}, name) => {
        const {value} = event.target;
        const updatedRows = headerRows.map((item) =>
            item.temp_id === row.temp_id ? {...item, [name]: value} : item
        );
        let headers = {};
        if (updatedRows.length > 0) {
            updatedRows.map((item) =>
                headers[item.key] = item.value
            )
        }
        setHeaderRows(updatedRows);
        setHttpSendInfoData((prevState) => ({
            ...prevState,
            headers: headers,
        }));

    };


    useEffect(() => {
        (async () => {
            try {
                const alertTypeRes = await request.get(`/alert-rules/commons/alert`);
                const updateAlertTypeRes = alertTypeRes.data?.data?.filter((item) => item.code != 99)
                setAlertTypeList(updateAlertTypeRes);

                const updatedConvertTypeEnum = {};
                const svrEnumResponse = await request.get(`commons/type/service`);
                const deleteDtxEnum = svrEnumResponse.data.data.filter((item) => item.id !== "DTx")
                setServiceList(deleteDtxEnum);

                svrEnumResponse.data.data.map((data) => {
                    updatedConvertTypeEnum[data.id] = data.display;
                });
                setConvertProfileToDisplay(updatedConvertTypeEnum);

                setTableSearchItems([
                    {
                        name: ["filter"],
                        type: "labelButton",
                        onClick: onClickOrgFilter,
                        onClickRemove: () => onClickOrgFilterForRemove(),
                        display: ButtonPlaceHolder.ALL,
                        value: "*",
                    },
                ]);
            } catch (error) {
            }
        })();
    }, []);


    useEffect(() => {
        (async () => {
            try {
                const organsResponse = await request.get("organs", {
                    params: {
                        page: modalPage - 1,
                        size: DefaultModalPageSize,
                        sort: modalSortParams,
                        name: modalTableSearchItems[0].value.trim(),
                        type: "*",
                    },
                });
                _setOrgRows(organsResponse.data.data.content);
                setModalTotalPages(organsResponse.data.data.totalPages);
                setModalTotalElements(organsResponse.data.data.totalElements);


            } catch (error) {
                //setAlert(error);
            }
        })();
    }, [openSubModal, modalPage, modalSortParams, modalRefresh]);

    useEffect(() => {
        (async () => {
            try {
                const ruleData = await request.get(`alert-rules`, {
                    params: {
                        page: page - 1,
                        size: pageSize,
                        id: tableSearchItems[0] === undefined ? "*" :tableSearchItems[0].value,
                        sort: sortParams,
                    },
                });
                const res = ruleData.data.data.content;
                _setRows(res);
                setTotalPages(ruleData.data.data.totalPages);
                setTotalElements(ruleData.data.data.totalElements);
            } catch (error) {
                // setAlert(error)
            }
        })();
    }, [refresh, page, sortParams]);

    // 최종 화면 나오는 곳
    return (
        <>
            <div className="wrapper">
                {<History title={title} breadcrumb={breadcrumb}/>}
                <hr/>
                {
                    <Table
                        rows={rows}
                        columns={columns}
                        getTotalRows={totalElements}
                        search={tableSearchItems}
                        onClickSearchButton={onClickSearchButton}
                        button={tableButton}
                        page={page}
                        totalPages={totalPages}
                        changePage={setPage}
                        sortParams={sortParams}
                        setSortParams={setSortParams}
                    />
                }
            </div>
            {
                <Modal
                    open={openModal}
                    close={closeModal}
                    title={
                        modalType === "create"
                            ? "알림 정책 등록"
                            : modalType === "modify"
                                ? "알림 정책 수정"
                                : ""
                    }
                    content={
                        <CreateAlertRuleModal
                            ruleData={ruleData}
                            isChecked={isChecked}
                            setIsChecked={setIsChecked}
                            sendInfoData={sendInfoData}
                            headerRows={headerRows}
                            httpSendInfoData={httpSendInfoData}
                            onChangeRuleData={onChangeRuleData}
                            onChangeSendInfoData={onChangeSendInfoData}
                            onChangeHttpSendInfoData={onChangeHttpSendInfoData}
                            alertTypeList={alertTypeList}
                            createRule={createRule}
                            modifyRule={modifyRule}
                            closeModal={closeModal}
                            openSelectOrgModal={openSelectOrgModal}
                            modalType={modalType}
                            errorMessage={errorMessage}
                            headerColumns={headerColumns}
                            totalHeaderElements={totalHeaderElements}
                            orgName={orgName}
                            serviceList={serviceList}
                            svcCheckedList={svcCheckedList}
                            setSvcCheckedList={setSvcCheckedList}

                        />}

                />
            }
            {
                <Modal
                    open={openSubModal}
                    close={closeSubModal}
                    title={OrgModalName.ALL}
                    content={
                        <SelectOrgModal
                            rows={orgRows}
                            orgType={subModalType}
                            selectOrg={selectOrg}
                            selectType={selectType}
                            getTotalRows={modalTotalElements}
                            search={modalTableSearchItems}
                            onChangeSearchValue={onChangeModalSearchValue}
                            onClickSearchButton={onClickModalSearchButton}
                            page={modalPage}
                            limit={DefaultModalPageSize}
                            totalPages={modalTotalPages}
                            changePage={setModalPage}
                            sortParams={modalSortParams}
                            setSortParams={setModalSortParams}
                        ></SelectOrgModal>
                    }
                />
            }
            {
                <Modal
                    size={"sm"}
                    open={openAlert}
                    close={closeAlert}
                    title={modalType === "modify" ? "알림 정책 수정" : modalType === "delete" ? "알림 정책 삭제" : "알림 정책 등록"}
                    content={
                        <div>
                            <div
                                style={{marginBottom: "30px"}}>{modalType === "delete" ? "알림 정책 삭제 시 알림을 사용 할 수 없습니다. 삭제하시겠습니까?" : "정말 저장하시겠습니까?"}</div>
                            <div style={{display: "flex", justifyContent: "right"}}>
                                <button className="btn btn_gray_line" onClick={closeAlert}>
                                    취소
                                </button>
                                <button
                                    style={{marginLeft: "15px"}}
                                    className="btn btn_black"
                                    onClick={
                                        modalType === "create"
                                            ? createRule
                                            : modalType === "modify"
                                                ? modifyRule
                                                : modalType === "delete"
                                                    ? deleteRule
                                                    : ""
                                    }
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    }
                />
            }
        </>
    );
};

export default ManageAlertRule;
