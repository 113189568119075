import LayOuts from "./Pages/LayOuts.js";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
// 페이지 목록
import ErrorPage from "./Pages/error-page.js";
import ManageOrganization from "./Pages/ManageOrganization.js"; // 사용자 정보
import ManageUser from "./Pages/ManageUser.js"; // 사용자 정보
import ManageDtxApp from "./Pages/ManageDtxApp.js"; // DTx 앱
import ManageDTxHosp from "./Pages/ManageDTxHosp.js"; // DTx 로컬코드
import InquirePrcpView from "./Pages/InquirePrcpView.js"; // DTx 처방정보
import InquireDtxUsage from "./Pages/InquireDtxUsage.js"; // DTx 결과리포트
import ExtServicePrcpView from "./Pages/ExtServicePrcpView.js" //연계서비스 처방정보
import ExtServiceUsageHistory from "./Pages/ExtServiceUsageHistory";
import UserSelfAuth from "./Pages/UserSelfAuth.js"; // 본인확인
import PersonalInfoAgreement from "./Pages/PersonalInfoAgreement.js"; // 정보제공 동의
import ManageAgreeRule from "./Pages/ManageAgreeRule.js"; // 정보제공설정
import OprLog from "./Pages/OprLog.js"; // 운영로그
import AuditLog from "./Pages/AuditLog.js"; // 감사로그
import SystemConfig from "./Pages/SystemConfig.js"; // 시스템 설정
import Mainpage from "./Pages/Mainpage.js"; // HOME
import Login from "./Pages/Login.js"; // 로그인
import ManageExtService from "./Pages/ManageExtService"; //연계서비스
import ManageAlertRule from "./Pages/ManageAlertRule"; //알림정책
import AlertHist from "./Pages/AlertHist";
import UsageHistory from "./Pages/UsageHistory"; //알림 이력
import Setup from "./Pages/Setup" // 최초 로그인시 가입
const Router = () => {
    return <RouterProvider router={router}/>;
};

const router = createBrowserRouter([
    {
        path: "/Login",
        element: <Login/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "/Setup",
        element: <Setup/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "/",
        element: <LayOuts/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "",
                element: <Mainpage/>,
            },
            {
                path: "ManageOrganization",
                element: <ManageOrganization/>,
            },
            {
                path: "ManageUser",
                element: <ManageUser/>,
            },
            {
                path: "ManageDtxApp",
                element: <ManageDtxApp/>,
            },
            {
                path: "ManageDTxHosp",
                element: <ManageDTxHosp/>,
            },
            {
                path: "ManageExtService",
                element: <ManageExtService/>,
            },
            {
                path: "ManageAlertRule",
                element: <ManageAlertRule/>,
            },
            {
                path: "InquirePrcpView",
                element: <InquirePrcpView/>,
            },
            {
                path: "InquireDtxUsage",
                element: <InquireDtxUsage/>,
            },
            {
                path: "UsageHistory",
                element: <UsageHistory/>,
            },
            {
                path: "ExtServicePrcpView",
                element: <ExtServicePrcpView/>,
            },
            {
                path: "ExtServiceUsageHistory",
                element: <ExtServiceUsageHistory/>,
            },
            {
                path: "AlertHist",
                element: <AlertHist/>,
            },
            {
                path: "UserSelfAuth",
                element: <UserSelfAuth/>,
            },
            {
                path: "PersonalInfoAgreement",
                element: <PersonalInfoAgreement/>,
            },
            {
                path: "ManageAgreeRule",
                element: <ManageAgreeRule/>,
            },
            {
                path: "AuditLog",
                element: <AuditLog/>,
            },
            {
                path: "OprLog",
                element: <OprLog/>,
            },
            {
                path: "SystemConfig",
                element: <SystemConfig/>,
            },
        ],
    },
]);

export default Router;
