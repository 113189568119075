import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import {AlertErrorState, AlertInfoState} from "..";
import {useRecoilState, useSetRecoilState} from "recoil";
import {
    ButtonPlaceHolder,
    copyToClipboard,
    DefaultModalPageSize,
    DefaultPageSize,
    DefaultSortParams,
    isURL,
    MessageCollection,
    OrgModalName,
} from "../components/Default/DefaultValue";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";
import CreateExtServiceModal from "../components/templates/CreateAlertRuleModal/CreateExtServiceModal";

const ManageExtService = () => {
    const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
    const [modalRefresh, setModalRefresh] = useState(false);
    const setMessage = useSetRecoilState(AlertInfoState);
    const [errorMessage, setErrorMessage] = useState([]);
    const [serviceId, setServiceId] = useState("");
    const [alart, setAlert] = useRecoilState(AlertErrorState);

    // 제목, 브레드크럼
    const title = "연계서비스";
    const breadcrumb = [
        {name: "HOME", link: "./"},
        {name: "서비스 설정"},
        {name: "연계서비스", link: "./ManageExtService"},
    ];

    const pageSize = DefaultPageSize;

    // 모달
    const [modalType, setModalType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => {
        setServiceId("");
        setErrorMessage([]);
        setOpenModal((openModal) => !openModal);
    };

    // 서브 모달
    const [openSubModal, setOpenSubModal] = useState(false);
    const closeSubModal = () => {
        setOpenSubModal((openModal) => !openModal);
    };

    //Alert 모달
    const [openAlert, setOpenAlert] = useState(false);
    const closeAlert = () => {
        setOpenAlert((openModal) => !openModal);
    };

    // 모달 테이블 관련 변수
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [sortParams, setSortParams] = useState(DefaultSortParams.DEFAULT);

    // 서브 모달 테이블 관련 변수
    const [subModalType, setSubModalType] = useState("");
    const [selectType, setSelectType] = useState("");
    const [modalTotalElements, setModalTotalElements] = useState(0);
    const [modalPage, setModalPage] = useState(1);
    const [modalTotalPages, setModalTotalPages] = useState(5);
    const [modalSortParams, setModalSortParams] = useState(
        DefaultSortParams.DEFAULT
    );
    //서비스 라디오버튼
    const [serviceList, setServiceList] = useState([]);
    // 검색조회부분
    const [tableSearchItems, setTableSearchItems] = useState([]);
    // 서브 모달 검색 조회
    const [modalTableSearchItems, setModalTableSearchItems] = useState([
        {
            name: ["orgName"],
            placeholder: ["기관명"],
            value: "",
            type: "text",
        },
    ]);


    const initExtServiceData = {
        user_org_id: "",
        user_org_name: "",
        provider_org_id: "",
        provider_org_name: "",
        service_name: "",
        url: "",
        service_type: "",
    };

    const [serviceData, setServiceData] = useState(initExtServiceData);

    // td 내용
    const [rows, _setRows] = useState([]);
    const [orgRows, _setOrgRows] = useState([]);

    // header,body 변경 th 내용
    const columns = [
        {
            name: "user_org_name",
            header: "의료기관",
            width: "10%",
        },
        {
            name: "provider_org_name",
            header: "연계서비스 기업",
            width: "10%",
        },
        {
            name: "service_name",
            header: "서비스명",
            width: "10%",
        },
        {
            name: "url",
            header: "API url",
            width: "15%",
            customBody: (row) => (
                <td key="url">
                    <div style={{display: "flex", justifyContent: "space-between", textAlign: "left"}}>
                        <div className="ellipsis_url">{row.url}</div>
                        <div
                            className="attachIcon"
                            style={{width: "18px"}}
                            onClick={() => {
                                copyToClipboard(row.url);
                                setMessage({message: MessageCollection.COPY, color: ""});
                            }}
                        ></div>
                    </div>
                </td>
            ),
            sort: false
        },
        {
            name: "create_at",
            header: "등록일",
            width: "15%",
        },
        {
            name: "modify",
            header: "수정",
            width: "3%",
            sort: false,
            customBody: (row) => (
                <td key={"modify"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            void openEditModal(row);
                        }}
                    >
                        수정
                    </label>
                </td>
            ),
        },
        {
            name: "delete",
            header: "삭제",
            width: "3%",
            sort: false,
            customBody: (row) => (
                <td key={"delete"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            void openDeleteModal(row);
                        }}
                    >
                        삭제
                    </label>
                </td>
            ),
        },
    ];


    const onChangeModalSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickModalSearchButton();
        }
        const {name, value} = event.target;
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    const onClickModalSearchButton = () => {
        setModalPage(1);
        setModalRefresh((prev) => !prev);
    };

    const selectOrg = (row) => {
        if (selectType === "corp") {
            setServiceData((prevState) => ({
                ...prevState,
                provider_org_id: row.org_id,
                provider_org_name: row.org_name
            }));
        } else if (selectType === "hosp") {
            setServiceData((prevState) => ({
                ...prevState,
                user_org_id: row.org_id,
                user_org_name: row.org_name
            }));
        } else {
            if (subModalType === "HOSP") {
                setTableSearchItems((prevState) =>
                    prevState.map((item) => {
                        if (item.name.join(",") === "hospFilter") {
                            return {...item, value: row.org_id, display: row.org_name};
                        }
                        return item;
                    })
                );
            } else {
                setTableSearchItems((prevState) => prevState.map((item) => {
                        if (item.name.join(",") === "corpFilter") {
                            return {...item, value: row.org_id, display: row.org_name};
                        }
                        return item;
                    })
                );
            }
        }
        closeSubModal();
    };

    const deleteService = async () => {
        try {
            await request.delete(`ext-services/${serviceId}`);

            setPage(1);
            setTableSearchItems((prevState) =>
                prevState.map((item, index) => {
                    if (index === 0 || index === 1) {
                        return {...item, value: ""};
                    }
                    return item;
                })
            );
            setRefresh((prev) => !prev);
            setMessage({message: MessageCollection.DELETE, color: ""});
            closeAlert();

        } catch (error) {
            //  setAlert(error);
        }
    };

    const checkValue = (checkItems, refinedData) => {
        let flag = true;

        checkItems.map((data) => {
            if (flag === true && refinedData[data] === "") {
                setErrorMessage([data, "필수 입력값입니다."]);
                flag = false;

            }
        });

        if (flag === false) {
            return;
        }


        if (!isURL(refinedData.url)) {
            setErrorMessage(["url", "올바른 url 형식을 입력해주세요."]);
            return;
        }

        if (openAlert !== true) {
            setOpenAlert(true);
            return;
        }
        return refinedData;

    }
    const createService = async () => {
        setErrorMessage([]);
        const refinedServiceData = {
            ...serviceData,
        };

        const checkItems = [
            "provider_org_name",
            "user_org_name",
            "service_type",
            "service_name",
            "url",
        ];
        const checkedData = checkValue(checkItems, refinedServiceData);
        if (checkedData !== undefined) {
            //중복확인
            try {
                const {user_org_id, service_type} = refinedServiceData
                const checkExist = await request.get(`ext-services/service-is-duplicated/${user_org_id}/${service_type}`);
                if (checkExist === undefined) {
                    return;
                }
            } catch (error) {

            }

            delete refinedServiceData.provider_org_name;
            delete refinedServiceData.user_org_name;
            //연계서비스 등록
            try {
              const res  =   await request.post(`ext-services`, {...refinedServiceData});


              if(res !== undefined) {
                  setTableSearchItems((prevState) =>
                      prevState.map((item, index) => {
                          if (index === 0 || index === 1) {
                              return {...item, value: ""};
                          }
                          return item;
                      })
                  );

              }
                setPage(1);
                setRefresh((prev) => !prev);
                setMessage({message: MessageCollection.CREATE, color: ""});
                closeAlert();
                closeModal();
            } catch (error) {
                // setAlert(error);
            }
        }
    };

    const modifyService = async () => {
        const refinedServiceData = {...serviceData};

        const checkItems = [
            "service_name",
            "url",
            "service_type"
        ];

        const checkedData = checkValue(checkItems, refinedServiceData);
        if (checkedData !== undefined) {
            delete refinedServiceData.provider_org_name;
            delete refinedServiceData.user_org_name;

            try {
                await request.put(`ext-services/${serviceId}`, refinedServiceData);
                setRefresh((prev) => !prev);
                setMessage({message: MessageCollection.MODIFY, color: ""});
                closeAlert();
                closeModal();
                setRefresh((prev) => !prev);
            } catch (error) {
                // setAlert(error);
            }
        }
    };

    // 입력창값이 변경될때
    const onChangeSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickSearchButton();
        }
        const {name, value} = event.target;
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
    const onClickSearchButton = () => {
        setPage(1);
        setRefresh((prev) => !prev);
    };
    // 테이블에다가 사용자 등록하는 모달 버튼
    const tableButton = {
        name: "연계서비스 등록",
        onClick: () => {
            setModalType("create");
            setErrorMessage([]);
            setServiceData(initExtServiceData);
            setOpenModal(true);
        },
    };

    const onChangeServiceData = (event) => {
        const {name, value} = event.target;
        setServiceData((prevState) => ({
            ...prevState,
            [name]: value.trim(),
        }));
    };

    const openEditModal = async (row) => {
        setServiceId(row.service_id);
        try {
            const selectedExtServiceDataResponse = await request.get(`ext-services/${row.service_id}`);
            const selectedServiceData = selectedExtServiceDataResponse.data.data;
            setServiceData(selectedServiceData);

        } catch (error) {

        }

        setModalType("modifyService");
        setOpenModal(true);
    };

    const openDeleteModal = async (row) => {
        setServiceId(row.service_id);
        setModalType("deleteService");
        setOpenAlert(true);

    };


    const openSelectOrgModal = (type) => {
        setModalPage(1);
        setSubModalType(type);
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgName") {
                    return {...item, value: ""};
                }
                return item;
            })
        );
        setOpenSubModal(true);
    };

    const onClickHospFilter = (type) => {
        if (type === "hosp") {
            setSelectType("hosp");
        }
        setModalPage(1);
        setSubModalType("HOSP");
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgName") {
                    return {...item, value: ""};
                }
                return item;
            })
        );
        setOpenSubModal(true);
    };
    const onClickCorpFilter = (type) => {
        if (type === "corp") {
            setSelectType("corp");
        }
        setModalPage(1);
        setSubModalType("CORP");
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgName") {
                    return {...item, value: ""};
                }
                return item;
            })
        );
        setOpenSubModal(true);
    };
    const onClickOrgFilterForRemove = (value) => {
        if (value === "HOSP") {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "hospFilter") {
                        return {...item, value: "*", display: ButtonPlaceHolder.HOSP};
                    }
                    return item;
                })
            );
        }
        if (value === "CORP") {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "corpFilter") {
                        return {...item, value: "*", display: ButtonPlaceHolder.CORP};
                    }
                    return item;
                })
            );
        }
    };


    useEffect(() => {
        (async () => {
            try {
                const svrEnumResponse = await request.get(`commons/type/service`);
                const updateEnum = svrEnumResponse.data.data.filter(el => el.id !== "DTx");
                setServiceList(updateEnum);
            } catch (error) {
                // setAlert(error);
            }

            setTableSearchItems([
                {
                    name: ["hospFilter"],
                    type: "labelButton",
                    onClick: () => onClickHospFilter(),
                    onClickRemove: () => {
                        onClickOrgFilterForRemove("HOSP");
                    },
                    display: ButtonPlaceHolder.HOSP,
                    value: "*",
                },
                {
                    name: ["corpFilter"],
                    type: "labelButton",
                    onClick: () => onClickCorpFilter(),
                    onClickRemove: () => {
                        onClickOrgFilterForRemove("CORP");
                    },
                    display: "연계서비스 기업 선택",
                    value: "*",
                },
                {
                    name: ["service"],
                    placeholder: ["서비스명"],
                    value: "",
                    type: "text",
                },
            ]);


        })();
    }, []);


    useEffect(() => {
        (async () => {
            try {
                const organsResponse = await request.get("organs", {
                    params: {
                        page: modalPage - 1,
                        size: DefaultModalPageSize,
                        sort: modalSortParams,
                        name: modalTableSearchItems[0].value.trim(),
                        type: subModalType,
                    },
                });
                _setOrgRows(organsResponse.data.data.content);
                setModalTotalPages(organsResponse.data.data.totalPages);
                setModalTotalElements(organsResponse.data.data.totalElements);
            } catch (error) {
                // setAlert(error)
            }
        })();
    }, [openSubModal, modalPage, modalSortParams, modalRefresh]);

    useEffect(() => {
        (async () => {
            try {
                const serviceData = await request.get(`ext-services`, {
                    params: {
                        page: page - 1,
                        size: pageSize,
                        hosp_id:
                            tableSearchItems[0] === undefined ? "*" : tableSearchItems[0].value,
                        corp_id:
                            tableSearchItems[1] === undefined ? "*" : tableSearchItems[1].value,
                        service:
                            tableSearchItems[2] === undefined
                                ? "*"
                                : tableSearchItems[2].value.trim(),
                        sort: sortParams,
                    },
                });
                _setRows(serviceData.data.data.content);
                setTotalPages(serviceData.data.data.totalPages);
                setTotalElements(serviceData.data.data.totalElements);
            } catch (error) {
                //  setAlert(error);
            }
        })();
    }, [refresh, page, sortParams]);

    // 최종 화면 나오는 곳
    return (
        <>
            <div className="wrapper">
                {<History title={title} breadcrumb={breadcrumb}/>}
                <hr/>
                {
                    <Table
                        rows={rows}
                        columns={columns}
                        getTotalRows={totalElements}
                        search={tableSearchItems}
                        onChangeSearchValue={onChangeSearchValue}
                        onClickSearchButton={onClickSearchButton}
                        button={tableButton}
                        page={page}
                        totalPages={totalPages}
                        changePage={setPage}
                        sortParams={sortParams}
                        setSortParams={setSortParams}
                    />
                }
            </div>
            {
                <Modal
                    open={openModal}
                    close={closeModal}
                    title={
                        modalType === "create"
                            ? "연계서비스 등록"
                            : modalType === "modifyService"
                                ? "연계서비스 수정"
                                : ""
                    }
                    content={
                        <CreateExtServiceModal
                            serviceData={serviceData}
                            setServiceData={setServiceData}
                            serviceList={serviceList}
                            onChangeServiceData={onChangeServiceData}
                            onClickCorpFilter={onClickCorpFilter}
                            onClickHospFilter={onClickHospFilter}
                            createService={createService}
                            modifyService={modifyService}
                            closeModal={closeModal}
                            openSelectOrgModal={openSelectOrgModal}
                            modalType={modalType}
                            errorMessage={errorMessage}
                        />}
                />
            }
            {
                <Modal
                    open={openSubModal}
                    close={closeSubModal}
                    title={subModalType === "HOSP" ? OrgModalName.HOSP : OrgModalName.CORP}
                    content={
                        <SelectOrgModal
                            rows={orgRows}
                            orgType={subModalType}
                            selectOrg={selectOrg}
                            selectType={selectType}
                            getTotalRows={modalTotalElements}
                            search={modalTableSearchItems}
                            onChangeSearchValue={onChangeModalSearchValue}
                            onClickSearchButton={onClickModalSearchButton}
                            page={modalPage}
                            limit={DefaultModalPageSize}
                            totalPages={modalTotalPages}
                            changePage={setModalPage}
                            sortParams={modalSortParams}
                            setSortParams={setModalSortParams}
                        ></SelectOrgModal>
                    }
                />
            }
            {
                <Modal
                    size={"sm"}
                    open={openAlert}
                    close={closeAlert}
                    title={modalType === "modifyService" ? "연계서비스 수정" : modalType === "deleteService" ? "연계서비스 삭제" : "연계서비스 등록"}
                    content={
                        <div>
                            <div
                                style={{marginBottom: "30px"}}>{modalType === "deleteService" ? "연계서비스 삭제 시 서비스를 사용할 수 없습니다. 삭제하시겠습니까?" : "정말 저장하시겠습니까?"}</div>
                            <div style={{display: "flex", justifyContent: "right"}}>
                                <button className="btn btn_gray_line" onClick={closeAlert}>
                                    취소
                                </button>
                                <button
                                    style={{marginLeft: "15px"}}
                                    className="btn btn_black"
                                    onClick={
                                        modalType === "create"
                                            ? createService
                                            : modalType === "modifyService"
                                                ? modifyService
                                                : modalType === "deleteService"
                                                    ? deleteService
                                                    : ""
                                    }
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    }
                />
            }
        </>
    );
};

export default ManageExtService;
