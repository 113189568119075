import clsx from "clsx";

const Input = ({
  style,
  type,
  id,
    min,
    max,
  maxLength,
  name,
  value,
  onClick,
  onChange,
  onKeyDown,
  required,
  readOnly,
  onBlur,
  disabled,
  placeholder,
  className,
  autoComplete,
  ref,
  errorMessage,
}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <input
        style={style}
        type={type}
        id={id}
        name={name}
        value={value}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onChange={onChange}
        required={required}
        readOnly={readOnly}
        onBlur={onBlur}
        min={min}
        max={max}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
        className={clsx(
          className,
          errorMessage && errorMessage[0] === name ? "badge_red" : ""
        )}
        autoComplete={autoComplete}
        ref={ref}
      />
      {errorMessage && (
        <p className="warning">
          {errorMessage[0] === name ? errorMessage[1] : ""}
        </p>
      )}
    </div>
  );
};

export default Input;
