import React from "react";
import Input from "../../atoms/Input/Input";
import DynamicTable from "../../organisms/DynamicTable/DynamicTable";

export const CreateAlertRuleModal = ({
                                         ruleData,
                                         sendInfoData,
                                         httpSendInfoData,
                                         onChangeRuleData,
                                         onChangeSendInfoData,
                                         onChangeHttpSendInfoData,
                                         alertTypeList,
                                         isChecked,
                                         setIsChecked,
                                         createRule,
                                         modifyRule,
                                         closeModal,
                                         openSelectOrgModal,
                                         modalType,
                                         errorMessage,
                                         headerRows,
                                         headerColumns,
                                         orgName,
                                         serviceList,
                                         svcCheckedList,
                                         setSvcCheckedList
                                     }) => {
    const updateSet = (set, id) => {
        const updatedSet = new Set(set);
        if (updatedSet.has(id)) {
            updatedSet.delete(id);
        } else {
            updatedSet.add(id);
        }

        return updatedSet;
    };
    const handleOnChange = (index, id) => {
            setSvcCheckedList((prevSet) => updateSet(prevSet, id));
    };

    return (
        <>
            <ul className="form_wrap mb50">
                <li>
                    <label className="name">기관{modalType === "create"?<sup className="required">*</sup>:""}</label>
                    <div style={{marginBottom: "0px"}} className="search_box">
                        <div key={ruleData?.org_name} className="search_wrap">
                            <Input
                                type="text"
                                name="org_name"
                                value={modalType === "create" ? ruleData?.org_name : orgName}
                                className="input_search_modal"
                                placeholder={"업체 선택"}
                                autoComplete="off"
                                style={{
                                    backgroundColor: "#FAFAFA",
                                    color: "#666670",
                                    cursor: modalType === "create" ? "pointer" : "default",
                                }}
                                onClick={() => modalType === "create" ? openSelectOrgModal() : ""}
                                disabled={modalType !== "create"}
                                onChange={onChangeRuleData}
                                errorMessage={errorMessage}
                            />
                            {modalType === "create" ? (
                                <button
                                    className="btn_search"
                                    onClick={openSelectOrgModal}
                                ></button>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </li>
                <li>
                    <label className="name">전송 타입<sup className="required">*</sup></label>
                    <ul key={ruleData?.send_info.type} className="radio_ul">
                        {alertTypeList.map((item, idx) => (
                            <li key={idx}>
                                <label htmlFor={item.type} className={ruleData?.send_info.type === item.type ? "checked" : ""}
                                >{item.type}</label>
                                <input
                                    id={item.type}
                                    value={item.type}
                                    name={"type"}
                                    type="radio"
                                    onChange={onChangeRuleData}
                                />
                            </li>
                        ))}
                    </ul>
                </li>
                <li>
                    <label className="name">서비스<sup className="required">*</sup></label>
                    <ul className={"result_ul"}>
                        {serviceList.map((item, idx) => (
                            <li key={idx}>
                                <label htmlFor={item.display}>{item.display}</label>
                                <input
                                    id={item.id}
                                    type="checkbox"
                                    checked={svcCheckedList.has(item.id)}
                                    onChange={() => handleOnChange(idx, item.id, "service")}
                                />
                            </li>
                        ))}
                    </ul>
                    {errorMessage.length !== 0 && errorMessage[0] === "service" ?
                        <p className="warning"
                           style={{marginLeft: "235px"}}
                        >서비스를 선택해주세요.</p> : ""}
                </li>
                {ruleData?.send_info.type === "http" ? (
                    <li>
                        <label className="name">사설 인증서 허용</label>
                        <ul className="check_box_ul">
                            <li>
                                <label htmlFor={"허용"}>허용</label>
                                <input
                                    id={"허용"}
                                    checked={isChecked}
                                    name={"cert_validate"}
                                    type="checkbox"
                                    onChange={(event)=>
                                    {setIsChecked((prev)=>!prev)
                                    onChangeHttpSendInfoData(event)}}
                                />
                            </li>
                        </ul>
                    </li>
                ) : (<></>)}
                <li>
                    <label className="name">전송 대상 정보</label>
                </li>
                <ul className={"alert_info"}>
                    {ruleData?.send_info.type === 1 || ruleData?.send_info.type === "db" ? <>
                            <p className="sub_title"> 접속정보</p>
                            <li>
                                <label className="name">접속 url<sup className="required">*</sup></label>
                                <Input
                                    type="text"
                                    name="url"
                                    value={sendInfoData.url}
                                    placeholder={"ex) jdbc:postgresql://postgres:5432/job_repository"}
                                    maxLength={"1024"}
                                    onChange={onChangeSendInfoData}
                                    errorMessage={errorMessage}
                                />
                            </li>
                            <li>
                                <label className="name">계정<sup className="required">*</sup></label>
                                <Input
                                    type="text"
                                    name="user"
                                    value={sendInfoData.user}
                                    placeholder={"계정"}
                                    maxLength={"50"}
                                    onChange={onChangeSendInfoData}
                                    errorMessage={errorMessage}
                                />
                            </li>
                            <li>
                                <label className="name">비밀번호<sup className="required">*</sup></label>
                                <Input
                                    type="text"
                                    name="password"
                                    value={sendInfoData.password}
                                    placeholder={"비밀번호"}
                                    maxLength={"50"}
                                    onChange={onChangeSendInfoData}
                                    errorMessage={errorMessage}
                                />
                            </li>
                            <p className="sub_title"> 저장정보</p>
                            <li >
                                <label className="name">저장 테이블명<sup className="required">*</sup></label>
                                <Input
                                    type="text"
                                    name="table"
                                    value={sendInfoData.table}
                                    placeholder={"저장 테이블명"}
                                    maxLength={"50"}
                                    onChange={onChangeSendInfoData}
                                    errorMessage={errorMessage}
                                />
                            </li>
                            <li>
                                <label className="name">전송일시 컬럼명<sup className="required">*</sup></label>
                                <Input
                                    type="text"
                                    name="send_time"
                                    value={sendInfoData?.colum_info?.send_time}
                                    placeholder={"전송일시 컬럼명"}
                                    maxLength={"50"}
                                    onChange={onChangeSendInfoData}
                                    errorMessage={errorMessage}
                                />
                            </li>
                            <li>
                                <label className="name">전송메시지 컬럼명<sup className="required">*</sup></label>
                                <Input
                                    type="text"
                                    name="message"
                                    value={sendInfoData?.colum_info?.message}
                                    placeholder={"전송메시지 컬럼명"}
                                    maxLength={"50"}
                                    onChange={onChangeSendInfoData}
                                    errorMessage={errorMessage}
                                />
                            </li>
                        </>
                        : <>
                            <li>
                                <label className="name">수신 API url<sup className="required">*</sup></label>
                                <Input
                                    type="text"
                                    name="url"
                                    value={httpSendInfoData.url}
                                    placeholder={""}
                                    maxLength={"1024"}
                                    onChange={onChangeHttpSendInfoData}
                                    errorMessage={errorMessage}
                                />
                            </li>
                            <li>
                                <label className="name">http 헤더</label>
                                <DynamicTable
                                    rows={headerRows}
                                    columns={headerColumns}
                                    // totalElements={totalHeaderElements}
                                ></DynamicTable>
                            </li>
                        </>
                    }
                </ul>
            </ul>
            <div className="btn_wrap">
                <div className="left"></div>
                <div className="center"></div>
                <div className="right">
                    <button className="btn btn_gray_line" onClick={closeModal}>
                        취소
                    </button>
                    <button
                        className="btn btn_black"
                        onClick={modalType === "create" ? createRule : modifyRule}
                    >
                        저장
                    </button>
                </div>
            </div>
        </>
    );
};

export default CreateAlertRuleModal;
