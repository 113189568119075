import Table from "../../organisms/Table/Table";
import Badge from "../../atoms/Badge/Badge";

const SelectOrgModal = ({
                            rows,
                            orgType,
                            selectOrg,
                            getTotalRows,
                            search,
                            onChangeSearchValue,
                            onClickSearchButton,
                            page,
                            limit,
                            totalPages,
                            changePage,
                            sortParams,
                            setSortParams,
                        }) => {
    const orgColumns = [
        {
            name: "org_name",
            header: orgType === "CORP" ? "기업명" : "기관명",
            width: "30%",
            customBody: (row) => (
                <td
                    key="org_name"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {row.org_name}
                </td>
            ),
        },
        {
            name: "org_id",
            header: "기관아이디",
            width: "20%",
            customBody: (row) => (
                <td
                    key="org_id"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {row.org_id}
                </td>
            ),
        },
        {
            name: "org_type",
            header: "유형",
            width: "10%",
            customBody: (row) => (
                <td
                    key="org_type"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {Badge(row.org_type, row.org_type)}
                </td>
            ),
        },
        {
            name: "biz_no",
            header: "사업자등록번호",
            width: "20%",
            customBody: (row) => (
                <td
                    key="biz_no"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {row.biz_no}
                </td>
            ),
        },
        {
            name: "president_name",
            header: "대표자",
            width: "20%",
            customBody: (row) => (
                <td
                    key="president_name"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                        selectOrg(row);
                    }}
                >
                    {row.president_name}
                </td>
            ),
        },
        {
            name: "dtx_code",
            header: "DTx코드",
            width: "*",
            customHeader: () => <th key="dtx_code" style={{display: "none"}}></th>,
            customBody: () => <td key="dtx_code" style={{display: "none"}}></td>,
        },
    ];

    return (
        <Table
            rows={rows}
            columns={orgColumns}
            getTotalRows={getTotalRows}
            search={search}
            onChangeSearchValue={onChangeSearchValue}
            onClickSearchButton={onClickSearchButton}
            page={page}
            limit={limit}
            totalPages={totalPages}
            changePage={changePage}
            sortParams={sortParams}
            setSortParams={setSortParams}
        ></Table>
    );
};

export default SelectOrgModal;
