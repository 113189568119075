import React, {useEffect, useRef, useState} from "react";
import requestForLogin from "../config/AxiosForLogin";
import Input from "../components/atoms/Input/Input";
import {useNavigate} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {AlertInfoState} from "..";
import {isValidEmail,isValidPassword, MessageColor,} from "../components/Default/DefaultValue";
import request from "../config/Axios";

const Login = () => {
    const savedUserName = localStorage.getItem("username");
    const savedCheckStatus = JSON.parse(localStorage.getItem("isSave"));
    const [mode, setMode] = useState("login");
    const setMessage = useSetRecoilState(AlertInfoState);

    const [userData, setUserData] = useState({
        username: savedCheckStatus === true ? savedUserName : "",
        password: "",
        checkPassword: "",
        otpcode: ""
    });
    const [checkUserData, setCheckUserData] = useState({
        username:  "",
        email: "",
    });
    const [errorMessage, setErrorMessage] = useState([]);

    const [isCheckedSaveInfo, setIsCheckedSaveInfo] = useState(
        savedCheckStatus === null ? false : savedCheckStatus
    );
    const [orgCntCheck, setOrgCntCheck] = useState(1);

    let navigate = useNavigate();

    const passwordInputRef = useRef(null);

    const onChangeUserData = (event) => {
        const {name, value} = event.target;
        if (event.key === "Enter" && mode === "login") {
            if (name === "username" && userData.password === "") {
                passwordInputRef.current.focus();
                return;
            }
            void onClickLoginButton();
        }
        if (event.key === "Enter" && mode === "change") {
            void onClickChangePasswordButton();
        }
        setUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const onChangeCheckUserData = (event) => {
        const {name, value} = event.target;
        setCheckUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const onClickLoginButton = async () => {
        //비어있는지 체크
        if (userData.username.trim() === "" || userData.password.trim() === "") {
            setErrorMessage(["password", "아이디 또는 비밀번호를 입력해주세요."]);
        } else if (userData.otpcode === "") {
            setErrorMessage(["otpcode", "OTP 인증번호를 입력해주세요."]);
        } else {
            const loginData = `Basic ${btoa(`${userData.username.trim()}:${userData.password.trim()}`)}`;
            try {
                const loginResponse = await requestForLogin.post(
                    "auth/loginProc",
                    {},
                    {
                        headers: {
                            Authorization: loginData,
                            "Content-Type": "application/json",
                        },
                        params: {
                            otp: userData.otpcode
                        }
                    }
                );
                if (localStorage.getItem("isSave") === "true") {
                    localStorage.setItem(`username`, userData.username);
                } else {
                    localStorage.removeItem(`username`);
                }
                localStorage.setItem("jwt", `Bearer ${loginResponse.data.data.jwt}`);
                localStorage.setItem("name", loginResponse.data.data.name);
                localStorage.setItem("uuid", loginResponse.data.data.uuid);
                navigate("/");
            } catch (error) {
                //계정 만료 code 02
                if (error.response?.data?.code === "02") {
                    // 임시 토큰 값 저장하여 패스워드 변경
                    localStorage.setItem("jwt", `Bearer ${error.response.data.detail.jwt}`);
                    localStorage.setItem("name", error.response.data.detail.name);
                    setMode("change");
                    sessionStorage.setItem("mode", "change");
                    sessionStorage.setItem("username", userData.username);
                    window.location.reload();
                } else {
                    setErrorMessage(["password", error.response?.data?.msg]);
                }
            }

        }
    };
    const onClickResetButton = async () => {
        //비어있는지 체크
        if (checkUserData.username.trim() === "" || checkUserData.email.trim() === "") {
            setErrorMessage(["email", "아이디 또는 이메일을 입력해주세요."]);
        }  else {
            if (!isValidEmail(checkUserData.email)) {
                setErrorMessage(["email", "올바른 이메일 형식을 입력해주세요."]);
                return;
            }
            // 아이디, 이메일 보내면 존재하는 user인지 확인
            // 비밀번호 초기화 , 메일로 초기 비밀번호 전송
            // 로그인페이지 이동, 초기화 메시지 띄우기
            try {
                await requestForLogin.get(
                    "users-pwd-find",
                    {
                        params: {
                            id: checkUserData.username,
                            mail:checkUserData.email
                        }
                    }
                );
                //메일 인증성공시 실행 - > 메일전송확인
                sessionStorage.setItem("code", "reset");
                window.location.reload();
            } catch (error) {
                setErrorMessage(["email",error?.response?.data.msg]);
            }

        }
    };
    const onClickChangePasswordButton = async () => {
        if (userData.password !== userData.checkPassword) {
            setErrorMessage(["password", "확인 비밀번호가 일치하지 않습니다."]);
        } else {
            if (!isValidPassword(userData.password)) {
                setErrorMessage(["password", "비밀번호는 영문 대소문자+숫자+특수문자 조합의 9-16자로 입력해주세요."]);
                return;
            }
            const token = localStorage.getItem("jwt");
            if (token !== null) {
                try {
                    await request.put(`users-pwd-change/${userData.username}`, {
                        pwd: userData.password,
                        verify: userData.checkPassword,
                    }, {
                        headers: {
                            Authorization: token,
                        },
                    });
                    setMessage({message: "비밀번호가 변경되었습니다."});
                    localStorage.removeItem("jwt");
                    localStorage.removeItem("name");
                    setMode("login");
                    const updatedUserData = {...userData};
                    updatedUserData.password = "";
                    updatedUserData.checkPassword = "";
                    setUserData(updatedUserData);
                    setErrorMessage([]);
                } catch (error) {
                    setErrorMessage(["password", error.response?.data?.msg]);
                    // setAlert(error);
                }
            }
        }
    };

    const onChangeCheckSaveInfo = (event) => {
        setIsCheckedSaveInfo(event);
        if (event === true) {
            localStorage.setItem("isSave", true);
        }
        if (event === false) {
            localStorage.setItem("isSave", false);
            localStorage.removeItem("userConInfo");
        }
    };
    useEffect(() => {
        (async () => {
            //organ count 체크
            try {
                const checkCnt = await requestForLogin.get(`setup/count-check`);
                setOrgCntCheck(checkCnt.data.data);
            } catch (error) {
                // setAlert(error);
            }
        })();
        if (sessionStorage.getItem("mode") === "change") {
            setMode("change");
            const updatedUserData = {
                username: sessionStorage.getItem("username"),
                password: "",
                checkPassword: "",
            };
            setErrorMessage([]);
            setUserData(updatedUserData);

            sessionStorage.removeItem("username");
            sessionStorage.removeItem("mode");

        }

        if (sessionStorage.getItem("code")) {
            let msg;
            const code = sessionStorage.getItem("code");
            switch (code) {
                case "08":
                    msg = "유효하지 않은 토큰입니다.";
                    break;
                case "session":
                    msg = "세션이 만료되었습니다.";
                    break;
                case "reset":
                    msg = "초기 비밀번호가 메일로 전송되었습니다.";
                    break;
                default:
                    return;
            }
            setMessage({
                message: msg,
                color: code === "reset" ? "" : MessageColor.RED,
            });
            sessionStorage.clear();
        }
    }, []);

    useEffect(() => {
        if (orgCntCheck === 0) {
            navigate("/Setup");
        }

    }, [orgCntCheck]);


    return (
        <>
            <div id="login">
                <h2 className="title">
                    {mode === "login" ? "로그인" : mode === "reset" ? "비밀번호 초기화" : "비밀번호 변경"}
                </h2>
                {mode !== "reset" ?<ul>
                    <form>
                        <li>
                            <label className="name">아이디</label>
                            <Input
                                type="text"
                                name="username"
                                placeholder="아이디"
                                value={userData.username}
                                maxLength={"20"}
                                disabled={mode !== "login"}
                                onChange={onChangeUserData}
                                onKeyDown={onChangeUserData}
                                autoComplete="username"
                            />
                        </li>
                        <li>
                            <label className="name">비밀번호</label>
                            <input
                                type="password"
                                name="password"
                                autoComplete="current-password"
                                value={userData.password}
                                maxLength={20}
                                onChange={onChangeUserData}
                                onKeyDown={onChangeUserData}
                                placeholder="비밀번호"
                                ref={passwordInputRef}
                            />
                        </li>
                        {mode === "login" && (
                            <li>
                                <input
                                    type="text"
                                    id="otpcode"
                                    name="otpcode"
                                    value={userData.otpcode}
                                    maxLength={20}
                                    onChange={onChangeUserData}
                                    onKeyDown={onChangeUserData}
                                    placeholder="Google OTP"
                                    autoComplete="off"
                                ></input>
                                {mode === "login" && errorMessage.length !== 0 && (
                                    <p className="warning">{errorMessage[1]}</p>
                                )}
                            </li>
                        )}
                        {mode === "change" && (
                            <li>
                                <input
                                    type="password"
                                    id="new-password"
                                    name="checkPassword"
                                    autoComplete="current-password"
                                    value={userData.checkPassword}
                                    maxLength={20}
                                    onChange={onChangeUserData}
                                    onKeyDown={onChangeUserData}
                                    placeholder="비밀번호 확인"
                                ></input>
                                {mode !== "login" && errorMessage.length !== 0 && (
                                    <p className="warning">{errorMessage[1]}</p>
                                )}
                            </li>
                        )}
                    </form>
                    <li className="save_info">
                        <input
                            id="check_btn"
                            className="check_btn"
                            type="checkbox"
                            checked={isCheckedSaveInfo}
                            onChange={({target: {checked}}) =>
                                onChangeCheckSaveInfo(checked)
                            }
                        />
                        {mode === "login" && (
                            <label htmlFor="check_btn">아이디 저장</label>
                        )}
                        {mode === "login" && (
                            <label
                                className="reset_pw"
                                onClick={()=>{
                                    setErrorMessage([]);
                                    setMode("reset");
                                }}
                            >비밀번호 초기화</label>
                        )}
                    </li>
                    <label>
                        * 본 페이지는 1920x1080에 최적화 되어있습니다.
                    </label>
                </ul> : <ul>
                    <form>
                        <li>
                            <label className="name">아이디</label>
                            <Input
                                type="text"
                                name="username"
                                placeholder="아이디"
                                value={checkUserData.username}
                                maxLength={"20"}
                                onChange={onChangeCheckUserData}
                                onKeyDown={onChangeCheckUserData}
                            />
                        </li>
                        <li>
                            <Input
                                type="email"
                                name="email"
                                value={checkUserData.email}
                                maxLength={"50"}
                                onChange={onChangeCheckUserData}
                                onKeyDown={onChangeCheckUserData}
                                placeholder="이메일"
                            />
                            {mode === "reset" && errorMessage.length !== 0 && (
                                <p className="warning">{errorMessage[1]}</p>
                            )}
                        </li>
                    </form>
                </ul> }
                <button
                    className="btn btn_blue"
                    onClick={
                        mode === "login" ? onClickLoginButton :  mode === "reset" ? onClickResetButton: onClickChangePasswordButton
                    }
                >
                    {mode === "login" ? "로그인" : mode ==="reset" ? "초기화" : "확인"}
                </button>
            </div>
        </>
    );
};

export default Login;
