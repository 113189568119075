import {useRecoilState, useSetRecoilState} from "recoil";
import request from "../config/Axios";
import {useEffect} from "react";
import {AlertErrorState, AlertInfoState} from "../"
import {useMutex} from 'react-context-mutex';
import requestForRetry from "./AxiosForRetry";

const AxiosHandler = () => {
    const setErrorState = useSetRecoilState(AlertErrorState);
    const [message, setMessage] = useRecoilState(AlertInfoState);
    const MutexRunner = useMutex();
    const mutex = new MutexRunner('myUniqueKey1');
    const errorHandler = (error) => {
        setErrorState({error});
    };

    const responseInterceptor = request.interceptors.response.use(
        async response => response,
        async (error) => {
            const {config} = error;

            // CONNECTION_REFUSED
            if (error.message && error.message.includes("Network Error")) {
                //서버 연결 실패(CONNECTtion refused 일 경우 1~3회 재시도 필요)
                // 반복된 인증 재실패의 경우 서버 인증 에러로 로그인 페이지 이동
                return requestForRetry(config);

            } else if (error.response) {
                switch (error.response.status) {
                    case 401:
                        const code = error.response?.data?.code;
                        if (code === "07") { //refresh
                            mutex.run(async () => {
                                mutex.lock();
                                try {
                                    await postRefreshToken(config);
                                    mutex.unlock();
                                } catch (error) {
                                    mutex.unlock();
                                    window.location.href = "/Login";
                                    console.log(error);

                                }
                            });
                        } else if (code === "08") { //08 유효하지 않은 토큰 -> 로그인화면
                            sessionStorage.setItem("code", code);
                            window.location.href = "/Login";
                            console.log(error);
                        }
                        break;
                    default :
                        return errorHandler(error);

                }
            } else {
                console.log(error);
                return errorHandler(error);
            }

        });
    //refresh token api
    const postRefreshToken = async (config) => {
        try {
            const response = await request.post(`auth/renewal`,{},{
                params :{
                    uuid : localStorage.getItem('uuid')
                }
            });

            localStorage.setItem('jwt', `Bearer ${response.data.data.jwt}`);
            localStorage.setItem('uuid', response.data.data.uuid);

            window.location.reload();
            config.headers.Authorization = localStorage.getItem("jwt");
            return request(config);
        } catch (error) {
            //세션이 만료되었습니다.
            sessionStorage.setItem("code", "session");
            window.location.href = "/Login";
        }
    }

    const requestInterceptor = request.interceptors.request.use((config) => {
            const token = localStorage.getItem("jwt");
            if (token !== null) {
                config.headers.Authorization = token;
            } else {
                window.location.href = "/Login";
            }
            return config;
        });

    useEffect(() => {
        return () => {
            request.interceptors.request.eject(requestInterceptor);
            request.interceptors.response.eject(responseInterceptor);
        };
    }, [responseInterceptor, requestInterceptor]);


};
export default AxiosHandler;
