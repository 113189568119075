import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import Input from "../components/atoms/Input/Input";
import Modal from "../components/organisms/Modal/Modal";
import {
    ButtonPlaceHolder,
    checkMaxLength,
    DefaultModalPageSize,
    DefaultPageSize,
    DefaultSortParams,
    MessageCollection,
    MessageColor,
    OrgModalName,
} from "../components/Default/DefaultValue";
import {AlertErrorState, AlertInfoState} from "..";
import {useRecoilState} from "recoil";
import CreateDtxHospModal from "../components/templates/CreateDtxHospModal/CreateDtxHospModal";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";
import SelectDtxModal from "../components/templates/SelectDtxModal/SelectDtxModal";

const ManageDTxHosp = () => {
        const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
        const [modalRefresh, setModalRefresh] = useState(false);
        const [alart, setAlert] = useRecoilState(AlertErrorState);
        const [message, setMessage] = useRecoilState(AlertInfoState);
        const [errorMessage, setErrorMessage] = useState([]);

        const initCheckDtx = {
            hosp: "",
            dtx: ""
        };
        const [checkDTx, setCheckDTx] = useState(initCheckDtx);
        const [orgId, setOrgId] = useState("");
        //수정시 기존 로컬코드 중복확인 x
        const [savedCode, setSavedCode] = useState([]);

        // 제목, 브레드크럼
        const title = "DTx 로컬코드";
        const breadcrumb = [
            {name: "HOME", link: "./"},
            {name: "서비스 설정"},
            {name: "DTx 로컬코드", link: "./ManageDTxHosp"},
        ];

        const pageSize = DefaultPageSize;

        // 모달
        const [openModal, setOpenModal] = useState(false);
        const [modalType, setModalType] = useState("");
        const closeModal = () => {
            setOpenModal((openModal) => !openModal);
            setCheckDTx(initCheckDtx);
            setSavedCode([]);
            setErrorMessage([]);
            _setLocalCodeRows([]);
            setOrgId("");
        };

        // 서브 모달
        const [subModalType, setSubModalType] = useState("");
        const [openSubModal, setOpenSubModal] = useState(false);
        const closeSubModal = () => {
            setOpenSubModal((openModal) => !openModal);
            setSubModalType("");
        };

        //Alert 모달
        const [openAlert, setOpenAlert] = useState("");
        const closeAlert = () => {
            setOpenAlert((openModal) => !openModal);
        };

        // 테이블 관련 변수
        const [totalElements, setTotalElements] = useState(0);
        const [page, setPage] = useState(1);
        const [totalPages, setTotalPages] = useState(5);
        const [sortParams, setSortParams] = useState(DefaultSortParams.DEFAULT);

        // 모달 테이블 관련 변수
        const [modalTotalElements, setModalTotalElements] = useState(0);
        const [modalPage, setModalPage] = useState(1);
        const [modalTotalPages, setModalTotalPages] = useState(5);
        const [modalSortParams, setModalSortParams] = useState(
            DefaultSortParams.DEFAULT
        );

        // 검색 조회
        const [tableSearchItems, setTableSearchItems] = useState([]);

        const orgTableSearchItems = {
            name: ["orgName"],
            placeholder: ["기관명"],
            value: "",
            type: "text",
        };

        const dtxTableSearchItems = {
            name: ["dtxName"],
            placeholder: ["DTx코드, DTx명"],
            search_word_like: "",
            type: "text",
        };

        // 서브 모달 검색 조회
        const [modalTableSearchItems, setModalTableSearchItems] = useState([orgTableSearchItems]);

        const [localCodeData, setLocalCodeData] = useState({});
        const [localCodeRows, _setLocalCodeRows] = useState([]);
        const [totalLocalCodeElements, setTotalLocalCodeElements] = useState(0);

        const initHospDtxData = {
            dtx_code: "",
            dtx_name: "",
            org_id: "",
            org_name: "",
            unit_purchase_price: "",
            unit_sales_price: "",
            local_prcp: []
        };

        const initLocalCodeRowData = {
            code: "",
            desc: "",
            temp_id: 0,
        };

        const [dtxData, setDtxData] = useState(initHospDtxData);

        // td 내용
        const [rows, _setRows] = useState([]);
        const [orgRows, _setOrgRows] = useState([]);
        const [appRows, _setAppRows] = useState([]);
        // 테이블에다가 사용자 등록하는 모달 버튼
        const tableButton = {
            name: "로컬코드 등록",
            onClick: () => {
                setModalType("create");
                setErrorMessage([]);
                _setLocalCodeRows([initLocalCodeRowData]);
                setDtxData(initHospDtxData);
                setOpenModal(true);
            },
        };

        // header,body 변경 th 내용
        const columns = [
            {
                name: "org_name",
                header: "의료기관",
                width: "15%",
            },

            {
                name: "dtx_code",
                header: "DTx코드",
                width: "15%",
            },
            {
                name: "dtx_name",
                header: "DTx명",
                width: "20%",
            },
            {
                name: "manage_org_name",
                header: "기업",
                width: "15%",
            },
            {
                name: "create_at",
                header: "등록일",
                width: "15%",
            },
            {
                name: "update",
                header: "수정",
                width: "7%",
                sort: false,
                customBody: (row) => (
                    <td key={"update"}>
                        <label
                            className="btn btn_sm btn_gray_line"
                            onClick={() => {
                                openEditModal(row);
                            }}
                        >
                            수정
                        </label>
                    </td>
                ),
            },
        ];

        const localCodeColumns = [
            {
                name: "code",
                header: "로컬코드",
                width: "32%",
                customBody: (row) => (
                    <td key="code" style={{paddingLeft: "100px"}}>
                        <Input
                            type="text"
                            className="w180"
                            name={"code"}
                            value={row.code}
                            onBlur={(event) => onChangeBlur(event, row)}
                            placeholder="ex) 로컬코드 값"
                            errorMessage={errorMessage}
                            onChange={(event) =>
                                onChangeLocalCodeSettings({event, row}, "code")
                            }
                        />
                    </td>
                ),
            },
            {
                name: "desc",
                header: "설명",
                desc: "관리자에게만 보여지는 설명입니다.",
                width: "32%",
                customBody: (row) => (
                    <td key="desc">
                        <input
                            type="text"
                            className="w180"
                            maxLength="100"
                            value={row.desc}
                            onChange={(event) => onChangeLocalCodeSettings({event, row}, "desc")}
                        />
                    </td>
                ),
            },
            {
                name: "add_rows",
                width: "13%",
                customHeader: () => (
                    <th key="add_rows">
                        <div className="action_container">
                            <button className="btn btn_xs btn_black" onClick={addLocalCode}>
                                행추가
                            </button>
                        </div>
                    </th>
                ),
                customBody: (row) => (
                    <td key={"delete_rows"} className="action_container">
                        <button
                            className="btn btn_xs btn_gray"
                            onClick={() => {
                                deleteLocalCode(row.temp_id);
                            }}
                        >
                            행삭제
                        </button>
                    </td>
                ),
            },
            {
                name: "temp_id",
                header: "임시 아이디",
                customHeader: () => <th key="temp_id" style={{display: "none"}}></th>,
                customBody: () => <td key="temp_id" style={{display: "none"}}></td>,
            },
        ];

        const onChangeBlur = async (event, row) => {
            const {value} = event.target;
            if (value.length > 0) {
                if (dtxData.org_id === "") {
                    setErrorMessage(["org_name", "필수 입력값입니다."]);
                    row.code = "";
                    return;
                }
                if (dtxData.dtx_code === "") {
                    setErrorMessage(["dtx_name", "필수 입력값입니다."]);
                    row.code = "";
                    return;
                }
                //수정시, 기존값과 비교
                if (savedCode.length > 0) {
                    savedCode?.map(async (item) => {
                        if (item.code !== row.code) {
                            const checkDuplicateCode = await request.get(`codes/code-is-duplicated/hosp/${dtxData.org_id}/code/${value}`)
                            if (checkDuplicateCode == undefined) {
                                row.code = "";
                            }
                            return;
                        }
                        let checkSet = new Set();
                        localCodeRows.map((item)=> checkSet.add(item.code));
                        if(localCodeRows.length !== checkSet.size){
                            setMessage({
                                message: "중복된 로컬 코드 값이 있습니다.",
                                color: MessageColor.RED,
                            });
                            row.code = "";
                        }
                        return;
                    });
                } else {
                    //신규일때
                    const checkDuplicateCode = await request.get(`codes/code-is-duplicated/hosp/${dtxData.org_id}/code/${value}`)
                    if (checkDuplicateCode == undefined) {
                        row.code = "";
                    }
                    return;
                }
            }
        };

        const openEditModal = async (row) => {
            setModalType("modify");

            const id = row.org_id;
            setOrgId(id);
            const code = row.dtx_code;
            const detailRes = await request.get(`codes/hosp/${id}/dtx/${code}`);
            const selectedDtxHospData = detailRes.data.data;
            if (selectedDtxHospData.local_prcp === null) {
                _setLocalCodeRows([]);
                setTotalLocalCodeElements(0);
            } else {
                for (let i = 0; i < selectedDtxHospData.local_prcp.length; i++) {
                    selectedDtxHospData.local_prcp[i].temp_id = i;
                }
                _setLocalCodeRows(selectedDtxHospData.local_prcp);
                setTotalLocalCodeElements(selectedDtxHospData.local_prcp.length);
            }
            //로컬코드 임시저장
            setSavedCode(selectedDtxHospData.local_prcp);
            setDtxData(selectedDtxHospData);
            setOpenModal(true);
        };

        const onChangeLocalCodeSettings = ({event, row}, name) => {
            const {value} = event.target;
            if (name === "code") {
                if (checkMaxLength(value, 32) === true) {
                    const updatedRows = localCodeRows.map((item) =>
                        item.temp_id === row.temp_id
                            ? {...item, [name]: value.trim()}
                            : item
                    );
                    _setLocalCodeRows(updatedRows);
                }
            } else {
                const updatedRows = localCodeRows.map((item) =>
                    item.temp_id === row.temp_id
                        ? {...item, [name]: value}
                        : item
                );
                _setLocalCodeRows(updatedRows);
            }
        };

        const modifyDtxHosp = async () => {
            let flag = true;

            localCodeRows.map((data) => {
                if (data.code === "") {
                    flag = false;
                }
            });
            if (flag === false) {
                setMessage({
                    message: "로컬 코드는 빈값일 수 없습니다.",
                    color: MessageColor.RED,
                });
                return;
            }
            const uniqueSet = new Set();
            localCodeRows.map((data) => {
                uniqueSet.add(data.code);
            });
            if (uniqueSet.size !== localCodeRows.length) {
                setMessage({
                    message: "중복된 로컬 코드 값이 있습니다.",
                    color: MessageColor.RED,
                });
                return;
            }

            const refinedDtxData = {
                ...dtxData,
                local_prcp: localCodeRows
            };

            if (openAlert !== true) {
                setOpenAlert(true);
                return;
            }

            const id = refinedDtxData.org_id;
            const code = refinedDtxData.dtx_code;
            try {
                await request.put(`codes/hosp/${id}/dtx/${code}`, {...refinedDtxData});
                setMessage({message: MessageCollection.MODIFY});
                closeAlert();
                closeModal();
            } catch (error) {
              //  setAlert(error);
            }
        };

        const addLocalCode = () => {
            let flag = true;
            const checkItems = [
                "org_name",
                "dtx_name",
            ];

            checkItems.map((data) => {
                if (flag === true && dtxData[data] === "") {
                    setErrorMessage([data, "필수 입력값입니다."]);
                    flag = false;
                }
            })
            if (flag === false) {
                return;
            }
            {
                const maxTempId = localCodeRows.reduce(
                    (max, item) => (item.temp_id > max ? item.temp_id : max),
                    0
                );
                _setLocalCodeRows((prevState) => [
                    ...prevState,
                    {
                        ...initLocalCodeRowData,
                        temp_id: maxTempId + 1,
                    },
                ]);
                setTotalLocalCodeElements((prev) => prev + 1);
            }
        };

        const deleteLocalCode = (id) => {
            const updatedLocalCodeRows = localCodeRows.filter(
                (item) => item.temp_id !== id
            );
            _setLocalCodeRows(updatedLocalCodeRows);
            setTotalLocalCodeElements((prev) => prev - 1);
        };

// 입력창값이 변경될때
        const onChangeSearchValue = (event) => {
            if (event.key === "Enter") {
                onClickSearchButton();
            }
            const {name, value} = event.target;
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === name) {
                        return {...item, value};
                    }
                    return item;
                })
            );
        };

// 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
        const onClickSearchButton = () => {
            setPage(1);
            setRefresh((prev) => !prev);
        };

        const selectOrg = (row) => {
            const refineTableSearchItems = [...tableSearchItems];
            if (subModalType === "HOSP") {
                refineTableSearchItems[0].display = row.org_name;
                refineTableSearchItems[0].value = row.org_id;
            } else if (subModalType === "CORP") {
                refineTableSearchItems[1].display = row.org_name;
                refineTableSearchItems[1].value = row.org_id;
            } else if (subModalType === "DTX") { //모달창 내 dtx 선택
                setDtxData((prevState) => ({
                    ...prevState,
                    dtx_code: row.dtx_code,
                    dtx_name: row.dtx_name
                }));
                setCheckDTx((prevState) => ({
                    ...prevState,
                    dtx: row.dtx_code
                }));
            } else { //모달창 내 의료기관 선택
                setDtxData((prevState) => ({
                    ...prevState,
                    org_id: row.org_id,
                    org_name: row.org_name
                }));
                setCheckDTx((prevState) => ({
                    ...prevState,
                    hosp: row.org_id
                }));
            }
            setTableSearchItems(refineTableSearchItems);

            closeSubModal();
        };

        const onClickOrgFilter = (value) => {
            setModalPage(1);
            setSubModalType(value);
            setModalTableSearchItems([orgTableSearchItems]);
            setModalTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "orgName") {
                        return {...item, value: ""};
                    }
                    return item;
                })
            );
            setOpenSubModal(true);
        };

        const onClickOrgFilterForRemove = (value) => {
            if (value === "HOSP") {
                setTableSearchItems((prevState) =>
                    prevState.map((item) => {
                        if (item.name.join(",") === "hospFilter") {
                            return {...item, value: "", display: ButtonPlaceHolder.HOSP};
                        }
                        return item;
                    })
                );
            }
            if (value === "CORP") {
                setTableSearchItems((prevState) =>
                    prevState.map((item) => {
                        if (item.name.join(",") === "corpFilter") {
                            return {...item, value: "", display: ButtonPlaceHolder.CORP};
                        }
                        return item;
                    })
                );
            }
        };

        //모달창 내 기업선택
        const openSelectOrgModal = (type) => {
            setModalPage(1);
            setSubModalType(type);
            if (type === "subHosp") {
                setModalTableSearchItems([orgTableSearchItems]);
                setModalTableSearchItems((prevState) =>
                    prevState.map((item) => {
                        if (item.name.join(",") === "orgName") {
                            return {...item, value: "", display: ButtonPlaceHolder.HOSP};
                        }
                        return item;
                    })
                );
            } else {
                setModalTableSearchItems([dtxTableSearchItems]);
                setModalTableSearchItems((prevState) =>
                    prevState.map((item) => {
                        if (item.name.join(",") === "dtxName") {
                            return {...item, value: "", display: ButtonPlaceHolder.DTX}
                        }
                        return item;
                    })
                );
            }
            setOpenSubModal(true);
        }

        //모달 내 검색
        const onChangeModalSearchValue = (event) => {
            if (event.key === "Enter") {
                onClickModalSearchButton();
            }
            const {name, value} = event.target;
            if (subModalType !== "DTX") {
                setModalTableSearchItems([orgTableSearchItems]);
                setModalTableSearchItems((prevState) =>
                    prevState.map((item) => {
                        if (item.name.join(",") === name) {
                            return {
                                ...item, value
                            };
                        }
                        return item;
                    })
                );
            } else { //dtx
                setModalTableSearchItems([dtxTableSearchItems]);
                setModalTableSearchItems((prevState) =>
                    prevState.map((item) => {
                        if (item.name.join(",") === name) {
                            return {
                                ...item, search_word_like: value
                            };
                        }
                        return item;
                    })
                );
            }
        };
        const createDtxHosp = async () => {
            setErrorMessage([]);

            const refinedDtxData = {
                ...dtxData,
            };

            if (refinedDtxData.org_name === "") {
                setErrorMessage(["org_name", "공백일 수 없습니다"]);
                return;
            }

            if (refinedDtxData.dtx_name === "") {
                setErrorMessage(["dtx_name", "공백일 수 없습니다"]);
                return;
            }


            //로컬코드 확인
            let flag = true;

            localCodeRows.map((data) => {
                if (data.code === "") {
                    flag = false;
                }
            });
            if (flag === false) {
                setMessage({
                    message: "로컬코드는 빈값일 수 없습니다.",
                    color: MessageColor.RED,
                });
                return;
            }
            const uniqueSet = new Set();
            localCodeRows.map((data) => {
                uniqueSet.add(data.code);
            });
            if (uniqueSet.size !== localCodeRows.length) {
                setMessage({
                    message: "중복된 로컬 코드 값이 있습니다.",
                    color: MessageColor.RED,
                });
                return;
            }

            if (openAlert !== true) {
                setOpenAlert(true);
                return;
            }

            refinedDtxData.local_prcp = localCodeRows;
            delete refinedDtxData.unit_purchase_price;
            delete refinedDtxData.unit_sales_price;

            const id = refinedDtxData.org_id;
            const code = refinedDtxData.dtx_code;

            try {
                await request.post(`codes/hosp/${id}/dtx/${code}`, {...refinedDtxData});

                setPage(1);
                setTableSearchItems((prevState) =>
                    prevState.map((item, index) => {
                        if (index === 0 || index === 1) {
                            return {...item, value: ""};
                        }
                        return item;
                    })
                );
                setRefresh((prev) => !prev);
                setMessage({message: MessageCollection.CREATE});
                closeAlert();
                closeModal();
            } catch (error) {
               // setAlert(error);
            }
        };

        const onClickHospFilter = () => {
            setModalPage(1);
            setModalTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "orgName") {
                        return {...item, value: ""};
                    }
                    return item;
                })
            );
            setOpenModal(true);
        };

        const onClickModalSearchButton = () => {
            setModalPage(1);
            setModalRefresh((prev) => !prev);
        };

        useEffect(() => {
            (async () => {
                //의료기관, DTx 둘다 선택되면 중복검사 실행
                if (checkDTx.hosp !== "" && checkDTx.dtx !== "") {
                    try {
                        const checkDuplicate = await request.get(`codes/dtx-is-duplicated/hosp/${checkDTx.hosp}/dtx/${checkDTx.dtx}`);
                        if (checkDuplicate === undefined) {
                            setDtxData((prevState) => ({
                                ...prevState, dtx_name: "", dtx_code: ""
                            }))
                        }
                    } catch (e) {
                       // setAlert(e);
                    }
                }
            })();
        }, [checkDTx]);

        useEffect(() => {
            (async () => {
                setTableSearchItems([
                    {
                        name: ["hospFilter"],
                        type: "labelButton",
                        onClick: () => {
                            onClickOrgFilter("HOSP");
                        },
                        onClickRemove: () => {
                            onClickOrgFilterForRemove("HOSP");
                        },
                        display: ButtonPlaceHolder.HOSP,
                        value: "",
                    },
                    {
                        name: ["corpFilter"],
                        type: "labelButton",
                        onClick: () => {
                            onClickOrgFilter("CORP");
                        },
                        onClickRemove: () => {
                            onClickOrgFilterForRemove("CORP");
                        },
                        display: ButtonPlaceHolder.CORP,
                        value: "",
                    },
                    {
                        name: ["dtxCode", "dtxName"],
                        placeholder: ["DTx코드, DTx명"],
                        value: "",
                        type: "text",
                    },
                ]);


            })();
        }, []);

        useEffect(() => {
            if (subModalType !== "") {
                if (subModalType === "DTX") {
                    (async () => {
                        try {
                            const appsData = await request.get("apps", {
                                params: {
                                    page: modalPage - 1,
                                    size: DefaultModalPageSize,
                                    sort: modalSortParams,
                                    type: subModalType,
                                    search_word_like: modalTableSearchItems[0].search_word_like?.trim(),
                                },
                            });
                            _setAppRows(appsData.data.data.content);
                            setModalTotalPages(appsData.data.data.totalPages);
                            setModalTotalElements(appsData.data.data.totalElements);
                        }catch (error){}
                    })();
                } else {
                    (async () => {
                        try {
                            const organsResponse = await request.get("organs", {
                                params: {
                                    page: modalPage - 1,
                                    size: DefaultModalPageSize,
                                    sort: modalSortParams,
                                    type: subModalType === "subHosp" ? "HOSP" : subModalType,
                                    name: modalTableSearchItems[0].value.trim(),
                                },
                            });
                            _setOrgRows(organsResponse.data.data.content);
                            setModalTotalPages(organsResponse.data.data.totalPages);
                            setModalTotalElements(organsResponse.data.data.totalElements);
                        }catch (error){

                        }
                    })();
                }
            }
        }, [openSubModal, modalPage, modalSortParams, modalRefresh]);

        useEffect(() => {
            (async () => {
                try {
                    const codesData = await request.get(`codes`, {
                        params: {
                            page: page - 1,
                            size: pageSize,
                            sort: sortParams,
                            hosp_id: tableSearchItems[0] === undefined
                                ? "*"
                                : tableSearchItems[0].value,
                            corp_id: tableSearchItems[1] === undefined
                                ? "*"
                                : tableSearchItems[1].value,
                            search_word_like: tableSearchItems[2] === undefined
                                ? "*"
                                : tableSearchItems[2].value.trim(),
                        },
                    });
                    _setRows(codesData.data.data.content);

                    setTotalPages(codesData.data.data.totalPages);
                    setTotalElements(codesData.data.data.totalElements);
                }catch (error){}
            })();

        }, [refresh, page, sortParams]);

// 최종 화면 나오는 곳
        return (
            <>
                <div className="wrapper">
                    {<History title={title} breadcrumb={breadcrumb}/>}
                    <hr/>
                    {
                        <Table
                            rows={rows}
                            columns={columns}
                            getTotalRows={totalElements}
                            search={tableSearchItems}
                            onChangeSearchValue={onChangeSearchValue}
                            onClickSearchButton={onClickSearchButton}
                            page={page}
                            button={tableButton}
                            totalPages={totalPages}
                            changePage={setPage}
                            sortParams={sortParams}
                            setSortParams={setSortParams}
                        />
                    }
                </div>
                {
                    <Modal
                        open={openModal}
                        close={closeModal}
                        title={
                            modalType === "create" ? "DTx 로컬코드 등록"
                                : modalType === "modify" ? "DTx 로컬코드 수정" : ""
                        }
                        content={
                            <CreateDtxHospModal
                                dtxData={dtxData}
                                modalType={modalType}
                                errorMessage={errorMessage}
                                createDtxHosp={createDtxHosp}
                                modifyDtxHosp={modifyDtxHosp}
                                openSelectOrgModal={openSelectOrgModal}
                                onClickHospFilter={onClickHospFilter}
                                localCodeData={localCodeData}
                                localCodeRows={localCodeRows}
                                localCodeColumns={localCodeColumns}
                                totalLocalCodeElements={totalLocalCodeElements}
                                closeModal={closeModal}
                            />
                        }
                    />
                }
                {
                    <Modal
                        open={openSubModal}
                        close={closeSubModal}
                        title={
                            subModalType === "HOSP" || subModalType === "subHosp" ? OrgModalName.HOSP
                                : subModalType === "DTX" ? OrgModalName.DTX : OrgModalName.CORP
                        }
                        content={subModalType === "DTX" ? <SelectDtxModal
                                rows={appRows}
                                orgType={subModalType}
                                selectOrg={selectOrg}
                                getTotalRows={modalTotalElements}
                                search={modalTableSearchItems}
                                onChangeSearchValue={onChangeModalSearchValue}
                                onClickSearchButton={onClickModalSearchButton}
                                page={modalPage}
                                limit={DefaultModalPageSize}
                                totalPages={modalTotalPages}
                                changePage={setModalPage}
                                sortParams={modalSortParams}
                                setSortParams={setModalSortParams}
                            ></SelectDtxModal>
                            : <SelectOrgModal
                                rows={orgRows}
                                orgType={subModalType}
                                selectOrg={selectOrg}
                                getTotalRows={modalTotalElements}
                                search={modalTableSearchItems}
                                onChangeSearchValue={onChangeModalSearchValue}
                                onClickSearchButton={onClickModalSearchButton}
                                page={modalPage}
                                limit={DefaultModalPageSize}
                                totalPages={modalTotalPages}
                                changePage={setModalPage}
                                sortParams={modalSortParams}
                                setSortParams={setModalSortParams}
                            ></SelectOrgModal>
                        }
                    />
                }
                {
                    <Modal
                        size={"sm"}
                        open={openAlert}
                        close={closeAlert}
                        title={"DTx 로컬코드"}
                        content={
                            <div>
                                <div style={{marginBottom: "30px"}}>정말 저장하시겠습니까?</div>
                                <div style={{display: "flex", justifyContent: "right"}}>
                                    <button className="btn btn_gray_line" onClick={closeAlert}>
                                        취소
                                    </button>
                                    <button
                                        style={{marginLeft: "30px"}}
                                        className="btn btn_black"
                                        onClick={modalType === "create" ? createDtxHosp : modifyDtxHosp}
                                    >
                                        확인
                                    </button>
                                </div>
                            </div>
                        }
                    />
                }
            </>
        );
    }
;

export default ManageDTxHosp;
