import React, {useEffect, useState} from 'react';
import ApexChart from "react-apexcharts";
import logCode from "../../../assets/resources/logCode.json";

function ExtServiceUsageChart({chartData, labelsData, monthlyCount,selectedMonth}) {
    const logType = logCode;
    const apiData = [];
    logType.map((data) => {
        if (data.value.split(":")[0] === "emr" || data.value.split(":")[0] === "dtx") {
            apiData.push(data.display);
        }
    });
    const [colorArr, setColorArr] = useState([]);
    const colorData = {
        "사전 문진-emr:021": "#FF80AB",
        "사전 문진-emr:022": "#FF4081",
        "사전 문진-emr:031": "#F50057",
        "사전 문진-dtx:021": "#C51162",
        "사전 문진-dtx:031": "#880E4F",
        "디지털 큐레이션-emr:021": "#B39DDB",
        "디지털 큐레이션-emr:022": "#7E57C2",
        "디지털 큐레이션-emr:031": "#5E35B1",
        "디지털 큐레이션-dtx:021": "#4527A0",
        "디지털 큐레이션-dtx:031": "#7C4DFF",
        "스마트 동의서-emr:021": "#0D47A1",
        "스마트 동의서-emr:022": "#82B1FF",
        "스마트 동의서-emr:031": "#448AFF",
        "스마트 동의서-dtx:021": "#2979FF",
        "스마트 동의서-dtx:031": "#2962FF",
    };

    useEffect(() => {
        // 순서 : 사전문진, 큐레이션, 스마트동의서
        setColorArr(chartData.map((el) => colorData[el.service_name + "-" + el.code]));
    }, [chartData]);
    const labels = labelsData.map((el) => el.display);
    const refinedlabelsData = chartData.map(item => item.service_name + " - " + item.display)
    const seriesData = chartData.map(item => item.sub_total);

    return (
        monthlyCount && monthlyCount.total > 0 ? (
            <>
                <table className={"color_table"}>
                    <thead>
                    <tr>
                        <th></th>
                        <th>연계서비스</th>
                        <th>API</th>
                        <th>금액</th>
                    </tr>
                    </thead>
                    <tbody>
                    {labels && labels.map((data, index) => {
                        return (
                            <>
                                <tr key={data + index}>
                                    <td style={{backgroundColor: colorData[labels[index] + "-emr:021"]}}></td>
                                    <td rowSpan={5} style={{verticalAlign: "middle"}}>{labels[index]}</td>
                                    <td>{apiData[0]}</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor: colorData[labels[index] + "-emr:022"]}}></td>
                                    <td>{apiData[1]}</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor: colorData[labels[index] + "-emr:031"]}}></td>
                                    <td>{apiData[2]}</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor: colorData[labels[index] + "-dtx:021"]}}></td>
                                    <td>{apiData[3]}</td>
                                    <td>0</td>
                                </tr>
                                <tr>
                                    <td style={{backgroundColor: colorData[labels[index] + "-dtx:031"]}}></td>
                                    <td>{apiData[4]}</td>
                                    <td>0</td>
                                </tr>
                            </>
                        )
                    })}
                    </tbody>
                </table>
                <ApexChart
                    width="400px"
                    height="400px"
                    type="donut"
                    series={seriesData}
                    options={{
                        title:{
                            text:selectedMonth + "월",
                            floating :true,
                            // offsetX: 173,
                            offsetY :180,
                            align:"center",
                            style :{
                                fontSize : "24px",
                            }
                        },
                        plotOptions:{
                            pie:{
                                donut:{
                                    size:"55%",
                                }
                            },
                        },
                        chart: {
                            toolbar: {show: false},
                            background: "transparent",
                            animations: {
                                enabled: false,
                            },
                        },
                        dataLabels: { //파이차트 위 데이터표시
                            enabled: false,
                        },
                        legend: {
                            show: false
                        },
                        labels: refinedlabelsData,
                        colors: colorArr,
                        markers: {
                            size: 5,
                        },
                        tooltip: {
                            enabled: true,
                        },
                        stroke: {
                            show: false,
                        },
                    }}
                />
            </>
        ) : <>
            <ApexChart
                width="300px"
                height="300px"
                type="donut"
                series={[0]}
                options={{
                    chart: {
                        toolbar: {show: false},
                        background: "transparent",
                        animations: {
                            enabled: false,
                        },
                    },
                    dataLabels: { //파이차트 위 데이터표시
                        enabled: false,
                    },
                    legend: {
                        show: true
                    },
                    labels: ["데이터 없음"],
                    colors: colorData[0],
                    tooltip: {
                        enabled: false,
                    },
                    stroke: {
                        show: false,
                    },
                }}
            />
        </>
    )
}

export default ExtServiceUsageChart;
