import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import Input from "../components/atoms/Input/Input";
import CreateDTxAPPModal from "../components/templates/CreateDTxAPPModal/CreateDTxAPPModal";
import {AlertErrorState, AlertInfoState} from "..";
import {useRecoilState} from "recoil";
import {
    ButtonPlaceHolder,
    DefaultModalPageSize,
    DefaultPageSize,
    DefaultSortParams,
    isNumber,
    isURL,
    MessageCollection,
    MessageColor,
    OrgModalName,
} from "../components/Default/DefaultValue";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";

const ManageDtxApp = () => {
    const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
    const [modalRefresh, setModalRefresh] = useState(false);
    const [alart, setAlert] = useRecoilState(AlertErrorState);
    const [message, setMessage] = useRecoilState(AlertInfoState);
    const [errorMessage, setErrorMessage] = useState([]);
    const [isChecked, setIsChecked] = useState(true);
    const [ediCode, setEdiCode] = useState("");

    // 제목, 브레드크럼
    const title = "DTx 앱";
    const breadcrumb = [
        {name: "HOME", link: "./"},
        {name: "서비스 설정"},
        {name: "DTx 앱", link: "./ManageDtxApp"},
    ];

    const pageSize = DefaultPageSize;

    // 모달
    const [modalType, setModalType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => {
        setErrorMessage([]);
        _setInstallRows([]);
        setIsChecked(true);
        setOpenModal((openModal) => !openModal);
    };

    // 서브 모달
    const [openSubModal, setOpenSubModal] = useState(false);
    const closeSubModal = () => {
        setOpenSubModal((openModal) => !openModal);
    };

    //Alert 모달
    const [openAlert, setOpenAlert] = useState("");
    const closeAlert = () => {
        setOpenAlert((openModal) => !openModal);
    };

    // 모달 테이블 관련 변수
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [sortParams, setSortParams] = useState(DefaultSortParams.DEFAULT);

    // 서브 모달 테이블 관련 변수
    const [subModalType, setSubModalType] = useState("");
    const [modalTotalElements, setModalTotalElements] = useState(0);
    const [modalPage, setModalPage] = useState(1);
    const [modalTotalPages, setModalTotalPages] = useState(5);
    const [modalSortParams, setModalSortParams] = useState(
        DefaultSortParams.DEFAULT
    );

    // 검색조회부분
    const [tableSearchItems, setTableSearchItems] = useState([]);
    // 서브 모달 검색 조회
    const [modalTableSearchItems, setModalTableSearchItems] = useState([
        {
            name: ["orgName"],
            placeholder: ["기관명"],
            value: "",
            type: "text",
        },
    ]);

    // ENUM
    const [marketEnum, setMarketEnum] = useState({});

    const initAppData = {
        dtx_code: "자동으로 생성됩니다.",
        org_name: "",
        dtx_name: "",
        edi_code: "",
        enable: "",
        prcp_valid_period: 1,
        unit_purchase_price: "",
        unit_sales_price: "",
        use_period: 1,
        install: {},
    };

    const initInstallData = {
        market: Object.values(marketEnum)[0],
        url: "",
        desc: "",
        temp_id: 0,
    };

    const [totalInstallElements, setTotalInstallElements] = useState(0);
    const [appData, setAppData] = useState(initAppData);

    // td 내용
    const [turnRows, _setTurnRows] = useState([]);
    const [rows, _setRows] = useState([]);
    const [orgRows, _setOrgRows] = useState([]);
    const [installRows, _setInstallRows] = useState([]);

    // header,body 변경 th 내용
    const columns = [
        {
            name: "dtx_code",
            header: "DTx코드",
            width: "12%",
        },
        {
            name: "dtx_name",
            header: "DTx명",
            width: "20%",
        },
        {
            name: "org_name",
            header: "기업",
            width: "20%",
        },
        {
            name: "enable",
            header: "사용 상태",
            width: "9%",
            customBody: (row) => (
                <td key={"enable"}>
          <span
              className={
                  row.enable === "true" ? "badge badge_blue" : "badge badge_red"
              }
          >
            {row.enable === "true" ? "사용" : "중지"}
          </span>
                </td>
            ),
        },
        {
            name: "create_at",
            header: "등록일",
            width: "15%",
        },
        {
            name: "app_info",
            header: "앱 정보",
            width: "7%",
            sort: false,
            customBody: (row) => (
                <td key={"app_info"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            setEdiCode("");
                            void openEditModal(row);
                        }}
                    >
                        수정
                    </label>
                </td>
            ),
        },
    ];
    const installColumns = [
        {
            name: "market",
            header: "마켓",
            width: "30%",
            customBody: (row) => (
                <td key="market">
                    <select
                        className={"w180"}
                        name="market"
                        value={row.market}
                        onChange={(event) =>
                            onChangeInstallSettings({event, row}, "market")
                        }
                    >
                        {Object.entries(marketEnum).map((market) => {
                            return (
                                <option key={market[0]} value={market[1]}>
                                    {market[0]}
                                </option>
                            );
                        })}
                    </select>
                </td>
            ),
        },
        {
            name: "url",
            header: "URL",
            width: "30%",
            customBody: (row) => (
                <td key="url">
                    <Input
                        type="text"
                        className={"w210"}
                        value={row.url}
                        name={"url"}
                        onBlur={onChangeBlur}
                        placeholder={"ex) https://example.com"}
                        onChange={(event) =>
                            onChangeInstallSettings({event, row}, "url")
                        }
                    />
                </td>
            ),
        },
        {
            name: "desc",
            header: "설명",
            desc: "사용자에게 보여지는 DTx 설명입니다.",
            width: "30%",
            customBody: (row) => (
                <td key="desc">
                    <Input
                        type="text"
                        className={"w210"}
                        value={row.desc}
                        onChange={(event) =>
                            onChangeInstallSettings({event, row}, "desc")
                        }
                    />
                </td>
            ),
        },
        {
            name: "add_rows",
            width: "10%",
            customHeader: () => (
                <th key="add_rows">
                    <div className="action_container">
                        <button className="btn btn_xs btn_black" onClick={addInstall}>
                            행추가
                        </button>
                    </div>
                </th>
            ),
            customBody: (row) => (
                <td key={"delete_rows"} className="action_container">
                    <button
                        className="btn btn_xs btn_gray"
                        onClick={() => {
                            deleteInstall(row.temp_id);
                        }}
                    >
                        행삭제
                    </button>
                </td>
            ),
        },
        {
            name: "temp_id",
            header: "임시 아이디",
            customHeader: () => <th key="temp_id" style={{display: "none"}}></th>,
            customBody: () => <td key="temp_id" style={{display: "none"}}></td>,
        },
    ];

    const onChangeModalSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickModalSearchButton();
        }
        const {name, value} = event.target;
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    const onClickModalSearchButton = () => {
        setModalPage(1);
        setModalRefresh((prev) => !prev);
    };

    const selectOrg = (row) => {
        if (subModalType === "CORP") {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "corpFilter") {
                        return {...item, value: row.org_id, display: row.org_name};
                    }
                    return item;
                })
            );
        } else { //모달창 내 기업선택
            setAppData((prevState) => ({
                ...prevState,
                org_id: row.org_id,
                org_name: row.org_name,
            }));
        }
        closeSubModal();
    };


    const onChangeInstallSettings = ({event, row}, name) => {
        const {value} = event.target;
        const updatedRows = installRows.map((item) =>
            item.temp_id === row.temp_id ? {...item, [name]: value} : item
        );

        _setInstallRows(updatedRows);
    };

    const addInstall = () => {
        if (totalInstallElements === 30) {
            return;
        }
        const maxTempId = installRows.reduce(
            (max, item) => (item.temp_id > max ? item.temp_id : max),
            -1
        );

        _setInstallRows((prevState) => [
            ...prevState,
            {
                ...initInstallData,
                temp_id: maxTempId + 1,
            },
        ]);
        setTotalInstallElements((prev) => prev + 1);
    };

    const deleteInstall = (id) => {
        const updatedInstallRows = installRows.filter(
            (item) => item.temp_id !== id
        );
        _setInstallRows(updatedInstallRows);
        setTotalInstallElements((prev) => prev - 1);
    };

    const validCheck = (data) => {

        delete data.unit_purchase_price;
        delete data.unit_sales_price;

        let flag = true;
        const checkItems = [
            "org_name",
            "dtx_name",
            "use_period",
            "prcp_valid_period"
        ];

        checkItems.map((item) => {
            if (flag === true && data[item] === "") {
                setErrorMessage([item, "필수 입력값입니다."]);
                flag = false;
            }
        })

        if (flag === false) {
            return;
        }


        if (!isNumber(data.use_period) || data.use_period < 1) {
            setErrorMessage(["use_period", "최소 1일 이상의 기간을 입력해주세요."]);
            return;
        }
        if (!isNumber(data.prcp_valid_period) || data.prcp_valid_period < 1) {
            setErrorMessage(["prcp_valid_period", "최소 1일 이상의 기간을 입력해주세요."]);
            return;
        }


        if (data.enable === "true") {
            data.enable = true;
        } else if (data.enable === "false") {
            data.enable = false;
        }

        if (data.edi_code !== "") {
            const checkDuplicateEdi = async () => {
                await request.get(`apps/edi-is-duplicated/${data.edi_code}`);
            }
            if (!checkDuplicateEdi) {
                setAppData((prev) => ({...prev, edi_code: ""}));
                return;
            }
        }

        //설치정보 셋팅

        const updateInstallRows = installRows.map((item) => {
            const {temp_id, ...rest} = item;
            return rest;
        });

        updateInstallRows.map((data) => {
            if (!isURL(data.url)) {
                flag = false;
            }
        });
        if (!flag) {
            setMessage({
                message: MessageCollection.CHECKURL,
                color: MessageColor.RED,
            });
            return;
        }
        data.install = updateInstallRows;
        if (data.install.length === 0) {
            setErrorMessage(["install", "최소 1개 이상의 설치정보를 입력해주세요."]);
            return;
        }
        return data;

    };

    const createApp = async () => {
        setErrorMessage([]);

        const refinedAppData = {
            ...appData,
            enable: isChecked
        };

        const org_id = refinedAppData.org_id;

        const checkedData = validCheck(refinedAppData);
        if (checkedData !== undefined) {

            if (openAlert !== true) {
                setOpenAlert(true);
                return;
            }
            try {
                await request.post(`apps/${org_id}`, {...checkedData});

                setPage(1);
                setTableSearchItems((prevState) =>
                    prevState.map((item, index) => {
                        if (index === 0 || index === 1) {
                            return {...item, value: ""};
                        }
                        return item;
                    })
                );
                setRefresh((prev) => !prev);
                setMessage({message: MessageCollection.CREATE});
                closeAlert();
                closeModal();
            } catch (error) {
                // setAlert(error);
            }
        }
    };

    const modifyApp = async () => {

        const refinedAppData = {
            ...appData,
            enable: isChecked
        };

        const dtx_code = refinedAppData.dtx_code;
        const checkedData = validCheck(refinedAppData);
        if (checkedData !== undefined) {
            if (openAlert !== true) {
                setOpenAlert(true);
                return;
            }
            try {
                await request.put(`apps/${dtx_code}`, {...checkedData});
                setTableSearchItems((prevState) =>
                    prevState.map((item, index) => {
                        if (index === 0 || index === 1) {
                            return {...item, value: ""};
                        }
                        return item;
                    })
                );
                setRefresh((prev) => !prev);
                setMessage({message: MessageCollection.MODIFY});
                closeAlert();
                closeModal();
            } catch (error) {
                //  setAlert(error);
            }
        }
    };

    // 입력창값이 변경될때
    const onChangeSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickSearchButton();
        }
        const {name, value} = event.target;
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
    const onClickSearchButton = () => {
        setPage(1);
        setRefresh((prev) => !prev);
    };
    // 테이블에다가 사용자 등록하는 모달 버튼
    const tableButton = {
        name: "DTx앱 등록",
        onClick: () => {
            setEdiCode("");
            setModalType("create");
            setErrorMessage([]);
            setAppData(initAppData);
            _setInstallRows([initInstallData]);
            setSubModalType("CORP");
            setOpenModal(true);
        },
    };

    const onChangeAppData = (event) => {
        const {name, value, checked} = event.target;
        if (name === "prcp_valid_period" || name === "use_period") {
            if (value.length > 3) {
                return;
            }
        }
        if (name === "enable") {
            setAppData((prevState) => ({
                ...prevState,
                ["enable"]: checked,
            }))
        }
        setAppData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const openEditModal = async (row) => {
        const selectedAppsDataResponse = await request.get(`apps/${row.dtx_code}`);
        const selectedAppData = selectedAppsDataResponse.data.data;
        row.edi_code = selectedAppData.edi_code;
        row.prcp_valid_period = selectedAppData.prcp_valid_period;
        row.use_period = selectedAppData.use_period;
        setIsChecked(selectedAppData.enable);
        if (selectedAppData.install === null || selectedAppData.install.length < 1) {
            _setInstallRows([]);
            setTotalInstallElements(0);
        } else {
            for (let i = 0; i < selectedAppData.install.length; i++) {
                selectedAppData.install[i].temp_id = i;
            }
            _setInstallRows(selectedAppData.install);
            setTotalInstallElements(selectedAppData.install.length);
        }
        //중복검사용 edicode 임시저장.
        setEdiCode(row.edi_code);
        setAppData(row);
        setModalType("modifyApps");
        setSubModalType("CORP");
        setOpenModal(true);
    };

    //모달창 내 기업선택
    const openSelectOrgModal = () => {
        setModalPage(1);
        setSubModalType("subCorp");
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgName") {
                    return {...item, value: ""};
                }
                return item;
            })
        );
        setOpenSubModal(true);
    };

    //tableSearchItems 필터
    const onClickOrgFilter = () => {
        setModalPage(1);
        setSubModalType("CORP");
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgName") {
                    return {...item, value: ""};
                }
                return item;
            })
        );
        setOpenSubModal(true);
    };

    const onClickOrgFilterForRemove = () => {
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "corpFilter") {
                    return {...item, value: "*", display: ButtonPlaceHolder.CORP};
                }
                return item;
            })
        );
    };

    const onChangeBlur = async (event) => {
        const {name, value} = event.target;
        if (name === "use_period" || name === "prcp_valid_period") {
            if (value > 365) {
                setMessage({
                    message: "최대 기간은 365일입니다.",
                    color: MessageColor.RED,
                });
                //값 365로 만들기
                setAppData((prev) => ({...prev, [name]: 365}));
            }
        }
        if (name === "edi_code" && value !== "" && value !== ediCode) {
            try {
                const checkRes = await request.get(`apps/edi-is-duplicated/${value}`);
                //중복값 삭제하기
                if (checkRes === undefined) {
                    setAppData((prev) => ({...prev, edi_code: ""}));
                }
            } catch (error) {
                //  setAlert(error);
            }

        }
    };

    useEffect(() => {
        (async () => {
            setTableSearchItems([
                {
                    name: ["corpFilter"],
                    type: "labelButton",
                    onClick: onClickOrgFilter,
                    onClickRemove: onClickOrgFilterForRemove,
                    display: ButtonPlaceHolder.CORP,
                    value: "*",
                },
                {
                    name: ["code", "name"],
                    placeholder: ["DTx코드, DTx명"],
                    value: "",
                    type: "text",
                },
            ]);


            const marketResponse = await request.get(`commons/type/market`);
            const updateMarketEnum = {};
            marketResponse.data.data.map((data) => {
                updateMarketEnum[data] = data;
            });
            setMarketEnum(updateMarketEnum);
        })();
    }, []);


    useEffect(() => {
        (async () => {
            const organsResponse = await request.get("organs", {
                params: {
                    page: modalPage - 1,
                    size: DefaultModalPageSize,
                    sort: modalSortParams,
                    name: modalTableSearchItems[0].value.trim(),
                    type: "CORP",
                },
            });
            _setOrgRows(organsResponse.data.data.content);
            setModalTotalPages(organsResponse.data.data.totalPages);
            setModalTotalElements(organsResponse.data.data.totalElements);
        })();
    }, [openSubModal, modalPage, modalSortParams, modalRefresh]);

    useEffect(() => {
        (async () => {
            const appsData = await request.get(`apps`, {
                params: {
                    page: page - 1,
                    size: pageSize,
                    id:
                        tableSearchItems[0] === undefined ? "*" : tableSearchItems[0].value,
                    search_word_like:
                        tableSearchItems[1] === undefined
                            ? "*"
                            : tableSearchItems[1].value.trim(),
                    sort: sortParams,
                },
            });
            _setRows(appsData.data.data.content);
            setTotalPages(appsData.data.data.totalPages);
            setTotalElements(appsData.data.data.totalElements);
        })();
    }, [refresh, page, sortParams]);

    // 최종 화면 나오는 곳
    return (
        <>
            <div className="wrapper">
                {<History title={title} breadcrumb={breadcrumb}/>}
                <hr/>
                {
                    <Table
                        rows={rows}
                        columns={columns}
                        getTotalRows={totalElements}
                        search={tableSearchItems}
                        onChangeSearchValue={onChangeSearchValue}
                        onClickSearchButton={onClickSearchButton}
                        button={tableButton}
                        page={page}
                        totalPages={totalPages}
                        changePage={setPage}
                        sortParams={sortParams}
                        setSortParams={setSortParams}
                    />
                }
            </div>
            {
                <Modal
                    open={openModal}
                    close={closeModal}
                    title={
                        modalType === "create"
                            ? "DTx 앱 등록"
                            : modalType === "modifyApps"
                                ? "DTx 앱 수정"
                                : ""
                    }
                    content={
                        <CreateDTxAPPModal
                            appData={appData}
                            onChangeAppData={onChangeAppData}
                            onChangeBlur={onChangeBlur}
                            isChecked={isChecked}
                            setIsChecked={setIsChecked}
                            installRows={installRows}
                            installColumns={installColumns}
                            turnRows={turnRows}
                            createApp={createApp}
                            modifyApp={modifyApp}
                            closeModal={closeModal}
                            openSelectOrgModal={openSelectOrgModal}
                            modalType={modalType}
                            errorMessage={errorMessage}
                        />
                    }
                />
            }
            {
                <Modal
                    open={openSubModal}
                    close={closeSubModal}
                    title={OrgModalName.CORP}
                    content={
                        <SelectOrgModal
                            rows={orgRows}
                            orgType={"CORP"}
                            selectOrg={selectOrg}
                            getTotalRows={modalTotalElements}
                            search={modalTableSearchItems}
                            onChangeSearchValue={onChangeModalSearchValue}
                            onClickSearchButton={onClickModalSearchButton}
                            page={modalPage}
                            limit={DefaultModalPageSize}
                            totalPages={modalTotalPages}
                            changePage={setModalPage}
                            sortParams={modalSortParams}
                            setSortParams={setModalSortParams}
                        ></SelectOrgModal>
                    }
                />
            }
            {
                <Modal
                    size={"sm"}
                    open={openAlert}
                    close={closeAlert}
                    title={"DTx 앱"}
                    content={
                        <div>
                            <div style={{marginBottom: "30px"}}>정말 저장하시겠습니까?</div>
                            <div style={{display: "flex", justifyContent: "right"}}>
                                <button className="btn btn_gray_line" onClick={closeAlert}>
                                    취소
                                </button>
                                <button
                                    style={{marginLeft: "30px"}}
                                    className="btn btn_black"
                                    onClick={
                                        modalType === "create"
                                            ? createApp
                                            : modalType === "modifyApps"
                                                ? modifyApp
                                                : () => {
                                                }
                                    }
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    }
                />
            }
        </>
    );
};

export default ManageDtxApp;
