import React from "react";
import DynamicTable from "../../organisms/DynamicTable/DynamicTable";
import Input from "../../atoms/Input/Input";

export const CreateDtxHospModal = ({
                                       dtxData,
                                       modalType,
                                       createDtxHosp,
                                       errorMessage,
                                       modifyDtxHosp,
                                       openSelectOrgModal,
                                       localCodeRows,
                                       localCodeColumns,
                                       closeModal,
                                   }) => {
    return (
        <>
            <div className="wrapper">
                <ul className="form_wrap mb50">
                    <li>
                        <label className="name">의료기관{modalType ==="create"? <sup className="required">*</sup>: ""}</label>
                        <div style={{marginBottom: "0px"}} className="search_box">
                            <div key={dtxData.org_id} className="search_wrap">
                                <Input
                                    type="text"
                                    name={"org_name"}
                                    value={dtxData.org_name}
                                    disabled={modalType !== "create"}
                                    placeholder={"의료기관 선택"}
                                    autoComplete="off"
                                    className="input_search_modal"
                                    style={
                                        modalType === "create"
                                            ? {
                                                backgroundColor: "#FAFAFA",
                                                color: "#666670",
                                                cursor: "pointer",
                                            }
                                            : {
                                                backgroundColor: "#FAFAFA",
                                                color: "#666670",
                                            }
                                    }
                                    onClick={
                                        modalType === "create" ? () => openSelectOrgModal("subHosp") : () => {
                                        }
                                    }
                                    onChange={() => {
                                    }}
                                    errorMessage={errorMessage}
                                />
                                {modalType === "create" ? (
                                    <button
                                        className="btn_search"
                                        onClick={() => openSelectOrgModal("subHosp")}
                                    ></button>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </li>
                    <li>
                        <label className="name">DTx명{modalType ==="create"? <sup className="required">*</sup>: ""}</label>
                        <div style={{marginBottom: "0px"}} className="search_box">
                            <div key={dtxData.dtx_name} className="search_wrap">
                                <Input
                                    type="text"
                                    name={"dtx_name"}
                                    value={dtxData.dtx_name}
                                    disabled={modalType !== "create"}
                                    placeholder={"DTx 선택"}
                                    className="input_search_modal"
                                    autoComplete="off"
                                    style={
                                        modalType === "create"
                                            ? {
                                                backgroundColor: "#FAFAFA",
                                                color: "#666670",
                                                cursor: "pointer",
                                            }
                                            : {
                                                backgroundColor: "#FAFAFA",
                                                color: "#666670",
                                            }
                                    }
                                    onClick={modalType === "create" ? () => openSelectOrgModal("DTX") : ""}
                                    onChange={() => {
                                    }}
                                    errorMessage={errorMessage}
                                />
                                {modalType === "create" ? (
                                    <button
                                        className="btn_search"
                                        onClick={() => openSelectOrgModal("DTX")}
                                    ></button>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </li>
                    <div className={"li_box"} style={{"display": "none"}}>
                        <li>
                            <label className="name">매입단가</label>
                            <input
                                type="text"
                                name="hosp_name"
                                value={dtxData.unit_purchase_price}
                                disabled
                            />
                        </li>
                        <li>
                            <label className="name">매출단가</label>
                            <input
                                type="text"
                                name="dtx_name"
                                value={dtxData.unit_sales_price}
                                disabled
                            />
                        </li>
                    </div>
                    <li>
                        <label className="name">로컬코드 관리</label>
                        <DynamicTable
                            rows={localCodeRows}
                            columns={localCodeColumns}
                        ></DynamicTable>
                    </li>
                </ul>

            </div>
            <div className="btn_wrap">
                <div className="left"></div>
                <div className="center"></div>
                <div className="right">
                    <button className="btn btn_gray_line" onClick={closeModal}>
                        취소
                    </button>
                    <button className="btn btn_black" onClick={modalType === "create" ? createDtxHosp : modifyDtxHosp}>
                        저장
                    </button>
                </div>
            </div>
        </>
    );
};

export default CreateDtxHospModal;
