import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import moment from "moment";

import {
    DefaultModalPageSize,
    DefaultPageSize,
    DefaultSortParams,
    OrgModalName,
} from "../components/Default/DefaultValue";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";
import logCode from "../assets/resources/logCode.json";
import ExtServiceUsageChart from "../components/organisms/Chart/ExtServiceUsageChart";


const ExtServiceUsageHistory = () => {
    const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
    const [modalRefresh, setModalRefresh] = useState(false);
    // 제목, 브레드크럼
    const title = "연계서비스 사용내역";
    const breadcrumb = [
        {name: "HOME", link: "./"},
        {name: "데이터 조회"},
        {name: "연계서비스 사용내역", link: "./UsageHistory"},
    ];

    // 모달
    const [openModal, setOpenModal] = useState(false);
    const [modalType, setModalType] = useState("admin");
    const closeModal = () => {
        setOpenModal(false);
    };

    // 테이블 관련 변수
    const [totalElements, setTotalElements] = useState(0);
    const [fileSize, setFileSize] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [sortParams, setSortParams] = useState("count_dt,desc");

    // 모달 테이블 관련 변수
    const [modalTotalElements, setModalTotalElements] = useState(0);
    const [modalPage, setModalPage] = useState(1);
    const [modalTotalPages, setModalTotalPages] = useState(5);
    const [modalSortParams, setModalSortParams] = useState(
        DefaultSortParams.DEFAULT
    );

    // 검색조회부분
    const [tableSearchItems, setTableSearchItems] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState({
        "org_id": "*",
        "org_name": "전체 기관"
    });

    // 서브 모달 검색 조회
    const [modalTableSearchItems, setModalTableSearchItems] = useState([
        {
            name: ["orgName"],
            placeholder: ["기관명"],
            value: "*",
            type: "text",
        },
    ]);

    // td 내용
    const [rows, _setRows] = useState([]);
    const [orgRows, _setOrgRows] = useState([]);
    const [chartData, setChartData] = useState([]);
    //그래프 위 표시되는 월
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [monthlyCount, setMonthlyCount] = useState({ total: 0, success: 0, fail: 0 });

    //그래프
    const [serviceData, setServiceData] = useState([]);
    //react DatePicker
    const [startDate, setStartDate] = useState(new Date());

    // th 내용
    const columns = [
        {
            name: "count_dt",
            header: "호출일자",
            width: "20%",
        },
        {
            name: "service",
            header: "연계서비스",
            width: "20%",
            customBody: (row) => (
                <td>{row.service.display}</td>
            )
        },
        {
            name: "display",
            header: "호출 API",
            width: "23%",
        },
        {
            name: "success_count",
            header: "성공",
            width: "10%",
        },
        {
            name: "fail_count",
            header: "실패",
            width: "10%",
        },
        {
            name: "total_count",
            header: "합계",
            width: "*",
        },
    ];
    // 테이블에다가 사용자 등록하는 모달 버튼
    const tableButton = {
        name: "내역 다운받기",
        onClick: () => {
            //엑셀로 결과 다운받기
            void downloadFile();
        },
    };

    const downloadFile = async () => {
        try {
            const download = await request.get("use/ext-services/download-csv", {
                params: {
                    start_date: moment(startDate).format("YYYY-MM"),
                    type: "apiCount",
                    id: tableSearchItems[1].value,
                    service: tableSearchItems[2].value,
                    code: tableSearchItems[3].value,
                },
                responseType: 'arraybuffer'
            });

            if (download !== undefined) {
                const blob = new Blob([download.data], {type: "binary"});
                const blobURL = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobURL;
                link.setAttribute('download', 'result.csv');
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(blobURL);
            }
        } catch (error) {

        }

    };

    // 입력창값이 변경될때
    const onChangeSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickSearchButton();
        }
        const {name, value} = event.target;
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    const onClickSearchButton = () => {
        setPage(1);
        setRefresh((prev) => !prev);
    };

    const selectOrg = (row) => {
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgFilter") {
                    return {...item, value: row.org_id, display: row.org_name};
                }
                return item;
            })
        );
        closeModal();
        setModalType(row.org_type);

        setSelectedOrg({org_id: row.org_id, org_name: row.org_name});
    };

    const onClickOrgFilter = () => {
        setModalPage(1);
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgName") {
                    return {...item, value: ""};
                }
                return item;
            })
        );
        setOpenModal(true);
    };

    const onClickOrgFilterForRemove = () => {
        setModalType("admin");
        setSelectedOrg({org_id: "*", org_name: "전체 기관"});
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === "orgFilter") {
                    return {...item, value: "*", display: "전체 기관"};
                }
                return item;
            })
        );

    };

    const onChangeModalSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickModalSearchButton();
        }
        const {name, value} = event.target;
        setModalTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    const onClickModalSearchButton = () => {
        setModalPage(1);
        setModalRefresh((prev) => !prev);
    };
    useEffect(() => {
        (async () => {
            const updateLogTypeEnum = {"전체 서비스": "*"};
            const updatedConvertCodeToDesc = {};
            const logType = logCode;
            logType.map((data) => {
                if (data.value.split(":")[0] === "emr" || data.value.split(":")[0] === "dtx") {
                    updateLogTypeEnum[data.display] = data.value;
                    updatedConvertCodeToDesc[data.value] = data.display;
                }
            });
            let searchItems = [
                {
                    name: ["selectMonth"],
                    type: "date",
                    value: "",
                    label: "호출 월",
                },
                {
                    name: ["orgFilter"],
                    type: "labelButton",
                    onClick: () => {
                        onClickOrgFilter()
                    },
                    onClickRemove: () => {
                        onClickOrgFilterForRemove()
                    },
                    display: "전체 기관",
                    value: "*",
                },
                {
                    name: ["detailFilter"],
                    filter: updateLogTypeEnum,
                    value: Object.values(updateLogTypeEnum)[0],
                    type: "select",
                },
            ];
            setTableSearchItems(searchItems);
            setRefresh((prev) => !prev);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const updateLogTypeEnum = {"전체 API": "*"};
            const updatedConvertCodeToDesc = {};
            const logType = logCode;
            logType.map((data) => {
                if (modalType === "admin") {
                    if (data.value.split(":")[0] === "emr" || data.value.split(":")[0] === "dtx") {
                        updateLogTypeEnum[data.display] = data.value;
                        updatedConvertCodeToDesc[data.value] = data.display;
                    }
                } else if (modalType === "HOSP") {
                    if (data.value.split(":")[0] === "emr") {
                        updateLogTypeEnum[data.display] = data.value;
                        updatedConvertCodeToDesc[data.value] = data.display;

                    }
                } else if (data.value.split(":")[0] === "dtx") {
                    updateLogTypeEnum[data.display] = data.value;
                    updatedConvertCodeToDesc[data.value] = data.display;

                }
            });

            let updateServiceTypeEnum = {"전체 연계서비스": "*"};
            const svrEnumResponse = await request.get(`commons/type/service`);
            const deleteDtxEnum = svrEnumResponse.data.data.filter((item) => item.id !== "DTx");
            setServiceData(deleteDtxEnum);
            deleteDtxEnum.map((data) => {
                updateServiceTypeEnum[data.display] = data.id;
            });
            let refinedSearchItems = [
                {
                    name: ["selectMonth"],
                    type: "date",
                    value: "",
                    label: "호출 월",
                },
                {
                    name: ["orgFilter"],
                    type: "labelButton",
                    onClick: () => {
                        onClickOrgFilter()
                    },
                    onClickRemove: () => {
                        onClickOrgFilterForRemove()
                    },
                    display: selectedOrg.org_name,
                    value: selectedOrg.org_id,
                },
                {
                    name: ["serviceFilter"],
                    filter: updateServiceTypeEnum,
                    value: Object.values(updateServiceTypeEnum)[0],
                    type: "select",
                },
                {
                    name: ["detailFilter"],
                    filter: updateLogTypeEnum,
                    value: Object.values(updateLogTypeEnum)[0],
                    type: "select",
                },
            ];
            setTableSearchItems(refinedSearchItems);
        })();
    }, [selectedOrg]);


    useEffect(() => {
        (async () => {
            try {
                const organsResponse = await request.get("organs", {
                    params: {
                        page: modalPage - 1,
                        size: DefaultModalPageSize,
                        sort: modalSortParams,
                        name: modalTableSearchItems[0].value.trim(),
                        type: "*",
                    },
                });
                _setOrgRows(organsResponse.data.data.content);
                setModalTotalPages(organsResponse.data.data.totalPages);
                setModalTotalElements(organsResponse.data.data.totalElements);
            } catch (error) {
            }
        })();
    }, [openModal, modalPage, modalSortParams, modalRefresh]);

    useEffect(() => {
        (async () => {
                try {
                    const usageResponse = await request.get(`use/ext-services`, {
                        params: {
                            page: page - 1,
                            size: DefaultPageSize,
                            start_date: moment(startDate).format("YYYY-MM"),
                            type: "apiCount",
                            id: tableSearchItems[1] === undefined ? "*" : tableSearchItems[1].value,
                            service: tableSearchItems[2] === undefined ? "*" : tableSearchItems[2].value,
                            code: tableSearchItems[3] === undefined ? "*" : tableSearchItems[3].value,
                            sort: sortParams,
                        },
                    });
                    _setRows(usageResponse.data.data.content);
                    setTotalPages(usageResponse.data.data.totalPages);
                    setTotalElements(usageResponse.data.data.totalElements);
                    //월간 총 호출 건수
                    if (usageResponse.data.data.content.length > 0) {
                        const {total, success, fail} = usageResponse.data.data.content.reduce((acc, el) => {
                            acc.total += el.total_count;
                            acc.success += el.success_count;
                            acc.fail += el.fail_count;
                            return acc;
                        }, {total: 0, success: 0, fail: 0});  // 초기값 설정);

                        const updateCount = {total, success, fail};
                        setMonthlyCount(updateCount);

                    }

                    const chartResponse = await request.get(`use/ext-services/chart`, {
                        params: {
                            start_date: moment(startDate).format("YYYY-MM"),
                            type: "apiCount",
                            id: tableSearchItems[1] === undefined ? "*" : tableSearchItems[1].value,
                            service: tableSearchItems[2] === undefined ? "*" : tableSearchItems[2].value,
                            code: tableSearchItems[3] === undefined ? "*" : tableSearchItems[3].value,
                        },
                    });
                    setChartData(chartResponse.data.data);
                    // //레포트 파일 크기
                    const fileResponse = await request.get(`use/ext-services/filesize`, {
                        params: {
                            start_date: moment(startDate).format("YYYY-MM"),
                            type: "storageSize",
                            id: tableSearchItems[1].value,
                            service: tableSearchItems[2].value,
                            code: tableSearchItems[3].value,
                        },
                    });
                    setFileSize(fileResponse?.data.data?.file_size);
                } catch (error) {

                }
            }
        )
        ();
    }, [refresh, page, sortParams]);

    // 최종 화면 나오는 곳
    return (
        <>
            <div className="wrapper">
                {<History title={title} breadcrumb={breadcrumb}/>}
                <hr/>

                <section className={"divide_section"}>
                    <div className="table">
                        {monthlyCount ? <Table
                            rows={rows}
                            columns={columns}
                            // overflow
                            getTotalRows={totalElements}
                            search={tableSearchItems}
                            onChangeSearchValue={onChangeSearchValue}
                            onClickSearchButton={onClickSearchButton}
                            countName={"월간 호출 건수"}
                            monthlyCount={monthlyCount}
                            countName2={"레포트 파일 사이즈"}
                            fileSize={fileSize}
                            button={tableButton}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            page={page}
                            totalPages={totalPages}
                            changePage={setPage}
                            sortParams={sortParams}
                            setSortParams={setSortParams}
                        /> : <></>}
                    </div>
                    <div className="chart">
                        <ExtServiceUsageChart
                            labelsData={serviceData}
                            chartData={chartData}
                            monthlyCount={monthlyCount}
                            selectedMonth={selectedMonth}
                        />
                    </div>
                </section>
            </div>
            {
                <Modal
                    open={openModal}
                    close={closeModal}
                    title={OrgModalName.ALL}
                    content={
                        <SelectOrgModal
                            rows={orgRows}
                            orgType={modalType}
                            selectOrg={selectOrg}
                            getTotalRows={modalTotalElements}
                            search={modalTableSearchItems}
                            onChangeSearchValue={onChangeModalSearchValue}
                            onClickSearchButton={onClickModalSearchButton}
                            page={modalPage}
                            limit={DefaultModalPageSize}
                            totalPages={modalTotalPages}
                            changePage={setModalPage}
                            sortParams={modalSortParams}
                            setSortParams={setModalSortParams}
                        ></SelectOrgModal>
                    }
                />
            }
        </>
    );
};

export default ExtServiceUsageHistory;
