import React, {useEffect, useState} from "react";
import Input from "../components/atoms/Input/Input";
import {useSetRecoilState} from "recoil";
import {AlertErrorState, AlertInfoState} from "..";
import {
    isBizno,
    isValidEmail,
    isValidId,
    isValidPhoneNumber,
    isValidTelNumber,
    MessageCollection,
} from "../components/Default/DefaultValue";
import Postcode from "../components/organisms/Postcode/Postcode";
import History from "../components/organisms/History/History";
import request from "../config/Axios";
import Modal from "../components/organisms/Modal/Modal";
import {useNavigate} from "react-router-dom";
const Setup = () => {
    const title = "초기 설정";
    const desc = "플랫폼 초기 설정을 시작합니다.";
    const setMessage = useSetRecoilState(AlertInfoState);
    const setAlert = useSetRecoilState(AlertErrorState);

    //주소
    const [postalCode, setPostalCode] = useState("");
    const [roadAddress, setRoadAddress] = useState("");
    const [detailAddress, setDetailAddress] = useState("");

    //Alert 모달
    const [openAlert, setOpenAlert] = useState(false);

    let navigate = useNavigate();

    const closeAlert = () => {
        setOpenAlert(false);
    };

    const [errorMessage, setErrorMessage] = useState([]);
    const initOrganData = {
        org_name: "",
        president_name: "",
        postal_code: "",
        road_address: "",
        address_detail: "",
        biz_no: "",
        tel_no: "",
    };

    const initUserData = {
        id: "",
        name: "",
        email: "",
        mobile_num: ""
    };

    const [organData, setOrganData] = useState(initOrganData);
    const [userData, setUserData] = useState(initUserData);

    const onChangeOrganData = (event) => {
        const {name, value} = event.target;
        setOrganData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onChangeUserData = (event) => {
        const {name, value} = event.target;
        setUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const checkValue = (checkItems1, checkItems2, refinedData) => {
        let flag = true;

        checkItems1.map((data) => {
            if (flag === true && refinedData.organ[data] === "") {
                setErrorMessage([data, "필수 입력값입니다."]);
                flag = false;

            }
        });

        checkItems2.map((data) => {
            if (flag === true && refinedData.user[data] === "") {
                setErrorMessage([data, "필수 입력값입니다."]);
                flag = false;

            }
        });

        if (flag === false) {
            return;
        }
        if (!isBizno(refinedData.organ.biz_no)) {
            setErrorMessage([
                "biz_no",
                "올바른 사업자등록번호 형식을 입력해주세요.",
            ]);
            return;
        }
        if (!isValidPhoneNumber(refinedData.organ.tel_no) && !isValidTelNumber(refinedData.organ.tel_no)) {
            setErrorMessage(["tel_no", "올바른 전화번호 형식을 입력해주세요."]);
            return;
        }

        if (!isValidId(refinedData.user.id)) {
            setErrorMessage([
                "id",
                "6~20자의 영문 대소문자,숫자만 사용 가능합니다",
            ]);
            return;
        }

        if (!isValidEmail(refinedData.user.email)) {
            setErrorMessage(["email", "올바른 이메일 형식을 입력해주세요."]);
            return;
        }

        if (!isValidPhoneNumber(refinedData.user.mobile_num)) {
            setErrorMessage(["mobile_num", "올바른 전화번호 형식을 입력해주세요."]);
            return;
        }
        if (openAlert !== true) {
            setOpenAlert(true);
            return;
        }
        return refinedData;

    }
    const createUser = async () => {
        setErrorMessage([]);
        const refinedJoinUsData = {
            organ: {
                ...organData,
                postal_code: postalCode,
                road_address: roadAddress,
                address_detail: detailAddress,
            },
            user: {
                ...userData
            }
        };
        const checkItems1 = [
            "org_name",
            "president_name",
            "postal_code",
            "address_detail",
            "tel_no",
            "biz_no",
        ];
        const checkItems2 = [
            "id",
            "name",
            "email",
            "mobile_num"
        ];

        const checkedData = checkValue(checkItems1, checkItems2, refinedJoinUsData);

        if (checkedData !== undefined) {
            try {
                await request.post(`setup/organs`, {
                    ...refinedJoinUsData,
                });
                setMessage({message: MessageCollection.CREATE});
                closeAlert();

                //로그인 화면으로 이동
                navigate("/Login");

            } catch (error) {
                setAlert(error);
            }
        }
    };
    useEffect(() => {
        (async () => {
        //organ count 체크
        try {
            const checkCnt = await request.get(`setup/count-check`);
            if(checkCnt.data.data > 0){
                navigate("/Login");
            }
        } catch (error) {
            // setAlert(error);
        }
        })();
    }, []);

    return (
        <>
            <div className="wrapper" style={{maxWidth: "1000px", margin: " 0 auto"}}>
                {<History title={title} desc={desc}/>}
                <hr/>
                <ul className="form_wrap join_us">
                    <form>
                        <li className="sub_title">
                            <label className="name">플랫폼 정보</label>
                        </li>
                        <li>
                            <label className="name">회사명<sup className="required">*</sup></label>
                            <Input
                                type="text"
                                name="org_name"
                                maxLength={20}
                                value={organData.org_name}
                                onChange={onChangeOrganData}
                                errorMessage={errorMessage}
                            />
                        </li>
                        <li>
                            <label className="name">대표자명<sup className="required">*</sup></label>
                            <Input
                                type="text"
                                name="president_name"
                                maxLength={16}
                                value={organData.president_name}
                                onChange={onChangeOrganData}
                                errorMessage={errorMessage}
                            />
                        </li>
                        <li>
                            <label className="name">주소<sup className="required">*</sup></label>
                            <Postcode
                                postalCode={postalCode}
                                setPostalCode={setPostalCode}
                                roadAddress={roadAddress}
                                setRoadAddress={setRoadAddress}
                                detailAddress={detailAddress}
                                setDetailAddress={setDetailAddress}
                                errorMessage={errorMessage}
                            ></Postcode>
                        </li>
                        <li>
                            <label className="name">전화번호<sup className="required">*</sup></label>
                            <Input
                                type="text"
                                name="tel_no"
                                maxLength={13}
                                value={organData.tel_no}
                                onChange={onChangeOrganData}
                                errorMessage={errorMessage}
                            />
                        </li>
                        <li>
                            <label className="name">사업자등록번호<sup className="required">*</sup></label>
                            <Input
                                type="text"
                                name="biz_no"
                                maxLength={12}
                                value={organData.biz_no || ""}
                                onChange={onChangeOrganData}
                                placeholder="ex) 000-00-00000"
                                errorMessage={errorMessage}
                            />
                        </li>
                        <li className="sub_title">
                            <label className="name">관리자 정보</label>
                        </li>
                        <li>
                            <label className="name">아이디<sup className="required">*</sup></label>
                            <Input
                                type="text"
                                name="id"
                                maxLength={20}
                                value={userData.id}
                                onChange={onChangeUserData}
                                errorMessage={errorMessage}
                            />
                        </li>
                        <li>
                            <label className="name">이름<sup className="required">*</sup></label>
                            <Input
                                type="text"
                                name="name"
                                maxLength={30}
                                value={userData.name}
                                onChange={onChangeUserData}
                                errorMessage={errorMessage}
                            />
                        </li>
                        <li>
                            <label className="name">이메일<sup className="required">*</sup></label>
                            <Input
                                type="email"
                                name="email"
                                maxLength={50}
                                value={userData.email}
                                onChange={onChangeUserData}
                                errorMessage={errorMessage}
                            />
                        </li>
                        <li>
                            <label className="name">전화번호<sup className="required">*</sup></label>
                            <Input
                                type="tel"
                                name="mobile_num"
                                maxLength={30}
                                value={userData.mobile_num}
                                onChange={onChangeUserData}
                                errorMessage={errorMessage}
                            />
                        </li>
                    </form>
                </ul>
                <div className="btn_wrap">
                    <div className="left" style={{color: "rgba(74, 83, 95, 0.7)"}}>
                        회원가입시 입력한 이메일로 초기 비밀번호가 전송됩니다.
                    </div>
                    <div className="center"></div>
                    <div className="right">
                        <button
                            className="btn btn_black"
                            onClick={createUser}
                        >
                            저장
                        </button>
                    </div>
                </div>
            </div>
            {
                <Modal
                    size={"sm"}
                    open={openAlert}
                    close={closeAlert}
                    title={"초기 설정 등록"}
                    content={
                        <div>
                            <div style={{marginBottom: "30px"}}>정말 저장하시겠습니까?</div>
                            <div style={{display: "flex", justifyContent: "right"}}>
                                <button className="btn btn_gray_line" onClick={closeAlert}>
                                    취소
                                </button>
                                <button
                                    style={{marginLeft: "30px"}}
                                    className="btn btn_black"
                                    onClick={createUser}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    }
                />
            }
        </>
    );
};

export default Setup;
