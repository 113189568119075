import React from "react";
import Input from "../../atoms/Input/Input";

const CreateUserInModal = ({
                               userData,
                               onChangeUserData,
                               onChangeBlur,
                               ignoreRole,
                               closeModal,
                               modifyUser,
                               createUser,
                               openResetPasswordModal,
                               openSelectOrgModal,
                               modalType,
                               errorMessage,
                           }) => {
    return (
        <>
            <ul className="form_wrap">
                <li>
                    <label className="name">아이디{modalType === "modify" ? <></> :
                        <sup className="required">*</sup>}</label>
                    <Input
                        type="text"
                        name="id"
                        maxLength={20}
                        value={userData.id}
                        onChange={onChangeUserData}
                        onBlur={onChangeBlur}
                        required
                        errorMessage={errorMessage}
                        disabled={modalType === "modify"}
                    />
                </li>
                <li>
                    <label className="name">소속<sup className="required">*</sup></label>
                    <div style={{ marginBottom: "0px" }} className="search_box">
                        <div key={userData.org_id} className="search_wrap">
                            <Input
                                type="text"
                                name={"org_name"}
                                value={userData.org_name}
                                className="input_search_modal"
                                placeholder={"소속기관 선택"}
                                autoComplete="off"
                                style={{
                                    backgroundColor: "#FAFAFA",
                                    color: "#666670",
                                    cursor: "pointer",
                                }}
                                onClick={openSelectOrgModal}
                                onChange={() => {}}
                                errorMessage={errorMessage}
                            />
                            <button
                                className="btn_search"
                                onClick={openSelectOrgModal}
                            ></button>
                        </div>
                    </div>
                </li>
                <li>
                    <label className="name">이름<sup className="required">*</sup></label>
                    <Input
                        type="text"
                        name="name"
                        maxLength={"32"}
                        value={userData.name}
                        onChange={onChangeUserData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">이메일<sup className="required">*</sup></label>
                    <Input
                        type="email"
                        name="email"
                        maxLength={"50"}
                        value={userData.email}
                        onChange={onChangeUserData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">전화번호<sup className="required">*</sup></label>
                    <Input
                        type="tel"
                        name="mobile_num"
                        maxLength={"13"}
                        value={userData.mobile_num === null ? "" : userData.mobile_num}
                        onChange={onChangeUserData}
                        required
                        placeholder={"ex) 000-0000-0000"}
                        errorMessage={errorMessage}
                    />
                </li>
            </ul>
            <div className="btn_wrap">
                <div className="left">
                    {modalType === "modify" && (
                        <button className="btn btn_black" onClick={openResetPasswordModal}>
                            비밀번호 초기화
                        </button>
                    )}
                </div>
                <div className="center"></div>
                <div className="right">
                    <button className="btn btn_gray_line" onClick={closeModal}>
                        취소
                    </button>
                    <button
                        className="btn btn_black"
                        onClick={modalType === "modify" ? modifyUser : createUser}
                    >
                        저장
                    </button>
                </div>
            </div>
        </>
    );
};

export default CreateUserInModal;
