import {Outlet, useLocation, useNavigate} from "react-router-dom";
import logo from "../assets/images/header_logo.png";
import React, {useEffect, useLayoutEffect} from "react";
import request from "../config/Axios";
import {useRecoilState} from "recoil";
import jwt_decode from "jwt-decode";
import {JwtState, UserDataState} from "..";
import Snackbar from "../components/organisms/Snackbar/Snackbar";
import AxiosHandler from "../config/AxiosHandler";
import Badge from "../components/atoms/Badge/Badge";

const LayOuts = () => {
    AxiosHandler();
    let navigate = useNavigate();

    const {pathname} = useLocation();
    const [userData, setUserData] = useRecoilState(UserDataState);
    const [jwtToken, setJwtToken] = useRecoilState(JwtState);

    const logout = async () => {
        await request.post(`auth/logout`);
        localStorage.removeItem("jwt");
        localStorage.removeItem("name");
        localStorage.removeItem("uuid");
        navigate("/login");
    };

    const menuItems = [
        {
            path: "/",
            label: "Home",
            iconClass: "menu_1",
        },
        {
            path: "/ManageOrganization",
            label: "플랫폼 운영",
            iconClass: "menu_2",
            subItems: [
                {path: "/ManageOrganization", label: "기관 정보"},
                {path: "/ManageUser", label: "사용자 정보"},
            ],
        },
        {
            path: "/ManageDtxApp",
            label: "서비스 설정",
            iconClass: "menu_3",
            subItems: [
                {path: "/ManageDtxApp", label: "DTx 앱"},
                {path: "/ManageDTxHosp", label: "DTx 로컬코드", divide: true},
                {path: "/ManageExtService", label: "연계서비스"},
                {path: "/ManageAlertRule", label: "알림 정책"},
            ],
        },
        {
            path: "/InquirePrcpView",
            label: "데이터 조회",
            iconClass: "menu_5",
            subItems: [
                {path: "/InquirePrcpView", label: "DTx 처방정보"},
                {path: "/InquireDtxUsage", label: "DTx 결과리포트"},
                {path: "/UsageHistory", label: "DTx 사용내역", divide: true},
                {path: "/ExtServicePrcpView", label: "연계서비스 처방정보"},
                {path: "/ExtServiceUsageHistory", label: "연계서비스 사용내역"},
                {path: "/AlertHist", label: "알림 이력"},
            ],
        },
        {
            path: "/UserSelfAuth",
            label: "동의서 관리",
            iconClass: "menu_6",
            subItems: [
                {path: "/UserSelfAuth", label: "본인확인"},
                {path: "/PersonalInfoAgreement", label: "정보제공 동의"},
                {path: "/ManageAgreeRule", label: "정보제공 설정"},
            ],
        },
        {
            path: "/OprLog",
            label: "이력 관리",
            iconClass: "menu_7",
            subItems: [
                {path: "/OprLog", label: "운영로그"},
                {path: "/AuditLog", label: "감사로그"},
            ],
        },
        {
            path: "/SystemConfig",
            label: "설정",
            iconClass: "menu_8",
            subItems: [
                {path: "/SystemConfig", label: "시스템 설정"},
            ],
        },
    ];

    useLayoutEffect(() => {
        (async () => {
            if (localStorage.getItem("jwt") === null) {
                navigate("/login");
            } else {
                const jwt = jwt_decode(localStorage.getItem("jwt"));
                setJwtToken({
                    userId: jwt.sub,
                });
            }

        })();
    }, []);

    useEffect(() => {
        if (jwtToken && jwtToken.userId) {
            (async () => {
                try {
                    const userDataResponse = await request.get(`users/${jwtToken.userId}`);
                    setUserData(userDataResponse.data.data);
                } catch (error) {

                }
            })();
        }
    }, []);

    return (<>
            <Snackbar error={{}}></Snackbar>
            <header className="header">
                <div className="header_container">
                    <h1
                        className="header_logo"
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        <img src={logo} alt="JanuSync"/>
                    </h1>
                    <ul className="header_user">
                        <li>
                            {Badge(userData.org_type,userData.org_name)}
                        </li>
                        <li className="user">
                            <span>{localStorage.getItem("name")}</span> 님
                        </li>
                        <li className="link" onClick={logout}>
                            <button onClick={logout}>로그아웃</button>
                        </li>
                    </ul>
                </div>
            </header>
            {/* <!-- // header -->
    <!-- container --> */}
            <div id="container" className="container">
                {/* <!-- side-menu --> */}
                <div className="side_menu">
                    <ul>
                        {menuItems.map((item) => (
                            <li key={item.path} className="dep_1">
                                <i className={`icon ${item.iconClass}`}></i>
                                <p onClick={() => navigate(item.path)}>{item.label}</p>
                                {item.subItems && (
                                    <ul>
                                        {item.subItems.map((subItem) => (
                                            <li
                                                key={subItem.path}
                                                className={subItem.divide ? "dep_2 divide" : "dep_2"}
                                                style={pathname === subItem.path ? {fontWeight: "600"} : {}}
                                                onClick={() => navigate(subItem.path)}
                                            >
                                                {subItem.label}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                    <small className="copyright">
                        © JanuSync. All rights reserved.
                    </small>
                </div>
                {/* <!-- side-menu -->
      <!-- content_wrap --> */}
                <Outlet></Outlet>
            </div>
        </>
    );
};

export default LayOuts;
