import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import Modal from "../components/organisms/Modal/Modal";
import {useSetRecoilState} from "recoil";
import {AlertErrorState, AlertInfoState} from "../index";
import CreateOrgInModal from "../components/templates/CreateOrgInModal/CreateOrgInModal";
import {
    DefaultPageSize,
    DefaultSortParams,
    isBizno,
    isValidPhoneNumber,
    isValidTelNumber,
    MessageCollection,
} from "../components/Default/DefaultValue";
import Badge from "../components/atoms/Badge/Badge";

const ManageOrganization = () => {
    const [refresh, setRefresh] = useState(false);
    const setAlert = useSetRecoilState(AlertErrorState);
    const [errorMessage, setErrorMessage] = useState([]);
    const setMessage = useSetRecoilState(AlertInfoState);

    // 제목, 브레드크럼
    const title = "기관 정보";
    const breadcrumb = [
        {name: "HOME", link: "./"},
        {name: "플랫폼 운영"},
        {name: "기관 정보", link: "./ManageOrganization"},
    ];

    const pageSize = DefaultPageSize;

    // 모달
    const [modalType, setModalType] = useState("");
    const [detailModalType, setDetailModalType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => {
        setErrorMessage([]);
        setCheckedList(new Set());
        setSvcCheckedList(new Set());
        setOrganizationData(initOrganizationData)

        setOpenModal((openModal) => !openModal);
    };

    // 서브 모달
    const [openSubModal, setOpenSubModal] = useState(false);
    const closeSubModal = () => {
        setOpenSubModal((openModal) => !openModal);
    };

    //Alert 모달
    const [openAlert, setOpenAlert] = useState(false);
    const closeAlert = () => {
        setOpenAlert((openModal) => !openModal);
    };

    // 테이블 관련 변수
    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [sortParams, setSortParams] = useState(DefaultSortParams.DEFAULT);

    // 검색 조회
    const [tableSearchItems, setTableSearchItems] = useState([]);

    // ENUM
    const [hospTypeEnum, setHospTypeEnum] = useState([]);

    //주소
    const [postalCode, setPostalCode] = useState("");
    const [roadAddress, setRoadAddress] = useState("");
    const [detailAddress, setDetailAddress] = useState("");

    const [selectedId, setSelectedId] = useState("");


    const initOrganizationData = {
        org_name: "",
        biz_no: "",
        president_name: "",
        class_code: Object.values(hospTypeEnum)[0],
        postal_code: "",
        road_address: "",
        address_detail: "",
        tel_no: "",
        oid: "",
        oauth_client_id: "",
        oauth_client_secret: "",
        org_type: "",
        is_external_consent:"false",
        result_type: [],
        service_list: ["DTx"]
    };


    const [organizationData, setOrganizationData] = useState(initOrganizationData);

    // td 내용
    const [rows, _setRows] = useState([]);

    // header,body 변경 th 내용
    const columns = [
        {
            name: "org_id",
            header: "아이디",
            width: "9%",
        },
        {
            name: "org_name",
            header: "기관명",
            width: "18%",
            customBody: (row) => <td key="org_name">{row.org_name}</td>,
        },
        {
            name: "org_type",
            header: "유형",
            width: "6%",
            customBody: (row) => (
                <td key="org_type">
                    {Badge(row.org_type,row.org_type)}
                </td>
            ),
        },
        {
            name: "biz_no",
            header: "사업자등록번호",
            width: "10%",
        },
        {
            name: "president_name",
            header: "대표자",
            width: "8%",
        },
        {
            name: "road_address",
            header: "주소",
            width: "27%",
        },
        {
            name: "create_at",
            header: "등록일",
            width: "15%",
            customBody: (row) => <td key="create_at">{row.create_at}</td>,
        },
        {
            name: "update",
            header: "수정",
            width: "7%",
            sort: false,
            customBody: (row) => (
                <td key={"update"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            void openEditModal(row.org_id);
                        }}
                    >
                        수정
                    </label>
                </td>
            ),
        },
        {
            name: "org_id",
            header: "ID",
            width: "0%",
            customHeader: () => <th key="ord_id" style={{display: "none"}}></th>,
            customBody: () => <td key="ord_id" style={{display: "none"}}></td>,
        },
    ];

    //정보제공 동의방식
    const agreeTypeList = [{display: "미사용", value: "false"}, {display: "사용", value: "true"}];

    const reportList = ["PDF", "JPG", "PNG"];
    const [checkedList, setCheckedList] = useState(new Set());

    //서비스 체크박스
    const [serviceList, setServiceList] = useState([]);
    const [svcCheckedList, setSvcCheckedList] = useState(new Set());
    const openEditModal = async (id) => {
        setDetailModalType("modify");
        const selectedOrganResponse = await request.get(`organs/${id}`);

        const selectedOrgData = {...selectedOrganResponse.data.data};
        for (const key in selectedOrgData) {
            if (selectedOrgData[key] === null || selectedOrgData[key] === undefined) {
                selectedOrgData[key] = "";
            }
        }
        setModalType(selectedOrgData.org_type);

        if (selectedOrgData.org_type === "HOSP") {
            if (selectedOrgData.result_type !== undefined && selectedOrgData.result_type.length > 0) {
                const addList = new Set();
                selectedOrgData.result_type.forEach(item => addList.add(item));
                setCheckedList(addList);
            }
          if(selectedOrgData.is_external_consent !== null){
              selectedOrgData.is_external_consent = selectedOrgData.is_external_consent + ""
          }

            setModalType("HOSP");
        }

        if (selectedOrgData.org_type === "CORP") {
            if (selectedOrgData.service_list !== undefined && selectedOrgData.service_list.length > 0) {
                const addList = new Set();
                selectedOrgData.service_list.forEach(item => addList.add(item));
                setSvcCheckedList(addList);
            }
        }

        if (selectedOrgData.org_type !== "PLAT") {
            try {
                const oauthResponse = await request.get(
                    `organs/oauth/${selectedOrganResponse.data.data.org_id}`,
                    {
                        params: {
                            type: selectedOrganResponse.data.data.org_type,
                        },
                    }
                );

                if (oauthResponse === undefined) {
                    selectedOrgData.oauth_client_id = "";
                    selectedOrgData.oauth_client_secret = "";
                } else {

                    selectedOrgData.oauth_client_id = oauthResponse.data.data.client_id;
                    selectedOrgData.oauth_client_secret = oauthResponse.data.data.secret_key

                }
            } catch (error) {

                // setAlert(error);
            }
        }
        setPostalCode(selectedOrgData.postal_code);
        setRoadAddress(selectedOrgData.road_address);
        setDetailAddress(selectedOrgData.address_detail);
        setOrganizationData(selectedOrgData);
        setSelectedId(id);
        setOpenModal(true);
    };

    // 입력창값이 변경될때
    const onChangeSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickSearchButton();
        }
        const {name, value} = event.target;
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
    };

    // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
    const onClickSearchButton = () => {
        setPage(1);
        setRefresh((prev) => !prev);
    };

    const createOrg = () => {
        setDetailModalType("create");
        setPostalCode("");
        setRoadAddress("");
        setDetailAddress("");
        setOpenModal(true);
    };

    // 테이블에다가 사용자 등록하는 모달 버튼
    const tableButton = {
        name: "의료기관 등록",
        onClick: () => {
            setModalType("HOSP");
            createOrg();
        },
    };

    const tableButton2 = {
        name: "기업 등록",
        onClick: () => {
            setModalType("CORP");
            createOrg();
        },
    };
    const checkValue = (checkItems, refinedData) => {
        let flag = true;

        checkItems.map((data) => {
            if (flag === true && refinedData[data] === "") {
                setErrorMessage([data, "필수 입력값입니다."]);
                flag = false;
            }

        });

        if (flag === false) {
            return;
        }

        if (detailModalType === "create" && !isBizno(refinedData.biz_no)) {
            setErrorMessage([
                "biz_no",
                "올바른 사업자등록번호 형식을 입력해주세요.",
            ]);
            return;
        }
        if (!isValidPhoneNumber(refinedData.tel_no) && !isValidTelNumber(refinedData.tel_no)) {
            setErrorMessage(["tel_no", "올바른 전화번호 형식을 입력해주세요."]);
            return;
        }

        if (refinedData.org_type === "HOSP" && refinedData.result_type.length === 0) {
            setErrorMessage([
                "result",
                "",
            ]);
            return;
        }
        if (refinedData.org_type === "CORP" && refinedData.service_list.length === 0) {
            setErrorMessage([
                "service",
                "",
            ]);
            return;
        }

        if (openAlert !== true) {
            setOpenAlert(true);
            return;
        }
        return refinedData;

    }
    const createInst = async () => {
        setErrorMessage([]);
        const refinedOrganizationData = {
            ...organizationData,
            postal_code: postalCode,
            road_address: roadAddress,
            address_detail: detailAddress,
            org_type: modalType,
            result_type: Array.from(checkedList),
            service_list: Array.from(svcCheckedList),
            is_external_consent : organizationData.is_external_consent === "true"
        };
        delete refinedOrganizationData.oauth_client_id;
        delete refinedOrganizationData.oauth_client_secret;

        let checkItems;
        if (modalType === "HOSP") {
            checkItems = [
                "org_name",
                "biz_no",
                "president_name",
                "postal_code",
                "address_detail",
                "tel_no",
                "oid",
                "result_type"
            ];
        } else {
            delete refinedOrganizationData.oid;
            delete refinedOrganizationData.is_external_consent
            checkItems = [
                "org_name",
                "biz_no",
                "president_name",
                "postal_code",
                "address_detail",
                "tel_no",
            ];
        }
        const checkedData = checkValue(checkItems, refinedOrganizationData);
        if (checkedData !== undefined) {
            if (modalType === "CORP") {
                delete refinedOrganizationData.class_code;
            }
            try {
                const res = await request.post("organs", {
                    ...refinedOrganizationData
                });
                if (res !== undefined) {
                    setTableSearchItems((prevState) =>
                        prevState.map((item, index) => {
                            if (index === 0) {
                                return {...item, value: ""};
                            }
                            return item;
                        })
                    );
                    setPage(1);

                    setRefresh((prev) => !prev);
                    setMessage({message: MessageCollection.CREATE, color: ""});
                    closeAlert();
                    closeModal();
                }
            } catch (error) {
                // setAlert(error);
            }
        }
    };

    const modifyInst = async () => {
        setErrorMessage([]);
        const refinedOrganizationData = {
            ...organizationData,
            postal_code: postalCode,
            road_address: roadAddress,
            address_detail: detailAddress,
            org_type: modalType,
            result_type: Array.from(checkedList),
            service_list: Array.from(svcCheckedList),
            is_external_consent : organizationData.is_external_consent === "true"

        };

        delete refinedOrganizationData.oauth_client_id;
        delete refinedOrganizationData.oauth_client_secret;

        let checkItems;
        if (modalType === "HOSP") {
            checkItems = [
                "org_name",
                "biz_no",
                "president_name",
                "postal_code",
                "address_detail",
                "tel_no",
                "oid",
                "result_type"
            ];
        } else {
            delete refinedOrganizationData.oid;
            delete refinedOrganizationData.is_external_consent;
            checkItems = [
                "president_name",
                "postal_code",
                "address_detail",
                "tel_no",
            ];
        }
        const checkedData = checkValue(checkItems, refinedOrganizationData);
        if (checkedData !== undefined) {
            try {
                await request.put(`organs/${selectedId}`, {
                    ...refinedOrganizationData,
                });

                setRefresh((prev) => !prev);
                setMessage({message: MessageCollection.MODIFY, color: ""});
                closeAlert();
                closeModal();
            } catch (error) {
                // setAlert(error);
            }
        }
    };

    const onchangeOrganizationData = (event) => {
        const {name, value} = event.target;
        if(name === "is_external_consent"){
            setOrganizationData((prevState) => ({
                ...prevState,
                is_external_consent: value + "",
            }));
        }else {
            setOrganizationData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const onChangeBlur = async (event) => {
        const {name, value} = event.target;
        if (name === "biz_no" && value !== "") {
            try {
                const checkRes = await request.get(`organs/bizno-is-duplicated/${value}`);
                if (checkRes === undefined) {
                    setOrganizationData((prev) => ({...prev, biz_no: ""}));
                }
            } catch (error) {
                // setAlert(error);
            }
        }
        if (name === "oid" && value !== "") {
            try {
                const checkRes = await request.get(`organs/oid-is-duplicated/${value}`);
                if (checkRes === undefined) {
                    setOrganizationData((prev) => ({...prev, oid: ""}));
                }
            } catch (error) {
                // setAlert(error);
            }

        }
    };

    const openResetOauth = () => {
        setOpenSubModal(true);
    };

    const resetOauth = async () => {
        try {
            await request.get(`organs/oauth-reset/${organizationData.org_id}`);

            const orgOauthResponse = await request.get(
                `organs/oauth/${organizationData.org_id}`,
                {
                    params: {
                        type: organizationData.org_id.substr(0, 4),
                    },
                }
            );
            const updatedOrganizationData = {...organizationData};
            updatedOrganizationData.oauth_client_id =
                orgOauthResponse.data.data.client_id;
            updatedOrganizationData.oauth_client_secret =
                orgOauthResponse.data.data.secret_key;
            setOrganizationData(updatedOrganizationData);
            setMessage({message: MessageCollection.INITOAUTH, color: ""});
            closeSubModal();
        } catch (error) {
            setAlert(error);
        }
    };

    useEffect(() => {
        (async () => {
            const updatedOrgTypeEnum = {"전체": "*"};
            try {
                const updateHospTypeEnum = {};
                const hospEnumResponse = await request.get(`commons/type/hosp`);
                hospEnumResponse.data.data.map((data) => {
                    updateHospTypeEnum[data.display] = data.code;
                });
                setHospTypeEnum(updateHospTypeEnum);
                const orgEnumResponse = await request.get(`commons/type/organ`);
                orgEnumResponse.data.data.map((data) => {
                    updatedOrgTypeEnum[data.display] = data.codePrefix;
                });
                const svrEnumResponse = await request.get(`commons/type/service`);
                setServiceList(svrEnumResponse.data.data);
            } catch (error) {
                // setAlert(error);
            }
            setTableSearchItems([
                {
                    name: ["orgFilter"],
                    filter: updatedOrgTypeEnum,
                    value: Object.values(updatedOrgTypeEnum)[0],
                    type: "select",
                },
                {
                    name: ["org_name"],
                    placeholder: ["기관명"],
                    value: "",
                    type: "text",
                },
            ]);
        })();
    }, []);

    useEffect(() => {
      setOrganizationData(prevState => ({...prevState,
          class_code :Object.values(hospTypeEnum)[0], }));
    }, [hospTypeEnum]);


    useEffect(() => {
        if (tableSearchItems.length > 0) {
            (async () => {
                try {
                    const organsResponse = await request.get(`organs`, {
                        params: {
                            page: page - 1,
                            size: pageSize,
                            type: tableSearchItems[0].value,
                            name: tableSearchItems[1].value.trim(),
                            sort: sortParams,
                        },
                    });
                    _setRows(organsResponse.data.data.content);
                    setTotalPages(organsResponse.data.data.totalPages);
                    setTotalElements(organsResponse.data.data.totalElements);
                } catch (error) {
                    //  setAlert(error)
                }
            })();
        } else {
            setTimeout(() => {
                setRefresh((prev) => !prev);
            }, [10]);
        }
    }, [refresh, page, sortParams]);

    return (
        <>
            <div className="wrapper">
                {<History title={title} breadcrumb={breadcrumb}/>}
                <hr/>
                {
                    <Table
                        rows={rows}
                        columns={columns}
                        getTotalRows={totalElements}
                        search={tableSearchItems}
                        onChangeSearchValue={onChangeSearchValue}
                        onClickSearchButton={onClickSearchButton}
                        button={tableButton}
                        button2={tableButton2}
                        page={page}
                        totalPages={totalPages}
                        changePage={setPage}
                        sortParams={sortParams}
                        setSortParams={setSortParams}
                    />
                }
            </div>
            {
                <Modal
                    open={openModal}
                    close={closeModal}
                    title={
                        (modalType === "CORP") && (detailModalType === "create")
                            ? "기업 등록"
                            : (modalType === "CORP") && (detailModalType === "modify")
                                ? "기업 수정"
                                : (modalType === "HOSP") && (detailModalType === "create")
                                    ? "의료기관 등록"
                                    : (modalType === "HOSP") && (detailModalType === "modify")
                                        ? "의료기관 수정"
                                        : "플랫폼 수정"
                    }
                    content={ organizationData && openModal &&
                        (<CreateOrgInModal
                            organizationData={organizationData}
                            onchangeOrganizationData={onchangeOrganizationData}
                            onChangeBlur={onChangeBlur}
                            hospTypeEnum={hospTypeEnum}
                            reportList={reportList}
                            agreeTypeList={agreeTypeList}
                            serviceList={serviceList}
                            checkedList={checkedList}
                            setCheckedList={setCheckedList}
                            svcCheckedList={svcCheckedList}
                            setSvcCheckedList={setSvcCheckedList}
                            postalCode={postalCode}
                            detailAddress={detailAddress}
                            setDetailAddress={setDetailAddress}
                            setPostalCode={setPostalCode}
                            roadAddress={roadAddress}
                            setRoadAddress={setRoadAddress}
                            createInst={createInst}
                            modifyInst={modifyInst}
                            openResetOauth={openResetOauth}
                            closeModal={closeModal}
                            modalType={modalType}
                            detailModalType={detailModalType}
                            errorMessage={errorMessage}
                        ></CreateOrgInModal>)
                    }
                />
            }
            {
                <Modal
                    size="sm"
                    open={openSubModal}
                    close={closeSubModal}
                    title={"Oauth 초기화"}
                    content={
                        <div>
                            <div style={{marginBottom: "30px"}}>
                                client_secret이 초기화되면 현재 사용중인 서비스에 장애가 발생할
                                수 있습니다. 진행하시겠습니까?
                            </div>
                            <div style={{display: "flex", justifyContent: "right"}}>
                                <button className="btn btn_gray_line" onClick={closeSubModal}>
                                    취소
                                </button>
                                <button
                                    style={{marginLeft: "30px"}}
                                    className="btn btn_black"
                                    onClick={resetOauth}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    }
                />
            }
            {
                <Modal
                    size={"sm"}
                    open={openAlert}
                    close={closeAlert}
                    title={"기관 정보"}
                    content={
                        <div>
                            <div style={{marginBottom: "30px"}}>정말 저장하시겠습니까?</div>
                            <div style={{display: "flex", justifyContent: "right"}}>
                                <button className="btn btn_gray_line" onClick={closeAlert}>
                                    취소
                                </button>
                                <button
                                    style={{marginLeft: "30px"}}
                                    className="btn btn_black"
                                    onClick={detailModalType === "create" ? createInst : modifyInst}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    }
                />
            }
        </>
    );
};

export default ManageOrganization;
