import React from "react";
import Input from "../../atoms/Input/Input";
import Postcode from "../../organisms/Postcode/Postcode";

const CreateOrgInModal = ({
                              organizationData,
                              onchangeOrganizationData,
                              onChangeBlur,
                              hospTypeEnum,
                              postalCode,
                              detailAddress,
                              setDetailAddress,
                              setPostalCode,
                              roadAddress,
                              setRoadAddress,
                              createInst,
                              modifyInst,
                              openResetOauth,
                              closeModal,
                              modalType,
                              detailModalType,
                              errorMessage,
                              reportList,
                              agreeTypeList,
                              checkedList,
                              setCheckedList,
                              serviceList,
                              svcCheckedList,
                              setSvcCheckedList
                          }) => {
    const updateSet = (set, id) => {
        const updatedSet = new Set(set);
        if (updatedSet.has(id)) {
            updatedSet.delete(id);
        } else {
            updatedSet.add(id);
        }

        return updatedSet;
    };
    const handleOnChange = (index, id, name) => {
       if (name === "service") {
            setSvcCheckedList((prevSet) => updateSet(prevSet, id));
        } else {
            setCheckedList((prevSet) => updateSet(prevSet, id));
        }
    };
    return modalType === "CORP" || modalType === "PLAT" ? (
        <>
            <ul className="form_wrap">
                {detailModalType === "modify" ? (
                    <li>
                        <label className="name">기관 아이디</label>
                        <Input
                            type="text"
                            name="org_id"
                            value={organizationData.org_id}
                            disabled
                        />
                    </li>
                ) : (
                    <></>
                )}
                <li>
                    <label className="name">기업명<sup className="required">*</sup></label>
                    <Input
                        type="text"
                        name="org_name"
                        maxLength={"32"}
                        value={organizationData.org_name}
                        onChange={onchangeOrganizationData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">사업자등록번호{detailModalType === "create" ?
                        <sup className="required">*</sup> : ""}</label>
                    <Input
                        type="text"
                        name="biz_no"
                        maxLength={"12"}
                        value={organizationData.biz_no || ""}
                        onChange={onchangeOrganizationData}
                        onBlur={onChangeBlur}
                        disabled={detailModalType === "modify"}
                        required
                        placeholder="ex) 000-00-00000"
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">대표자<sup className="required">*</sup></label>
                    <Input
                        type="text"
                        name="president_name"
                        maxLength={"16"}
                        value={organizationData.president_name}
                        onChange={onchangeOrganizationData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">주소<sup className="required">*</sup></label>
                    <Postcode
                        postalCode={postalCode}
                        setPostalCode={setPostalCode}
                        roadAddress={roadAddress}
                        setRoadAddress={setRoadAddress}
                        detailAddress={detailAddress}
                        setDetailAddress={setDetailAddress}
                        errorMessage={errorMessage}
                    ></Postcode>
                </li>
                <li>
                    <label className="name">전화번호<sup className="required">*</sup></label>
                    <Input
                        type="tel"
                        name="tel_no"
                        maxLength={"13"}
                        value={organizationData.tel_no}
                        onChange={onchangeOrganizationData}
                        required
                        placeholder={"ex) 000-0000-0000"}
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">서비스<sup className="required">*</sup></label>
                    <ul className={"result_ul"}>
                        {serviceList.map((item, idx) => (
                            <li key={idx}>
                                <label htmlFor={item.display}>{item.display}</label>
                                <input
                                    id={item.id}
                                    type="checkbox"
                                    checked={svcCheckedList.has(item.id)}
                                    onChange={() => handleOnChange(idx, item.id, "service")}
                                />
                            </li>
                        ))}
                    </ul>
                    {errorMessage.length !== 0 && errorMessage[0] === "service" ?
                        <p className="warning"
                           style={{marginLeft: "235px"}}
                        >서비스를 선택해주세요.</p> : ""}
                </li>
                {detailModalType === "modify" ? (
                    <>
                        <li>
                            <label className="name">oAuth2 client_id</label>
                            <Input
                                type="text"
                                name="oauth_client_id"
                                value={organizationData.oauth_client_id}
                                onChange={onchangeOrganizationData}
                                disabled
                            />
                        </li>
                        <li>
                            <label className="name">oAuth2 client_secret</label>
                            <Input
                                type="text"
                                className="w490"
                                name="oauth_client_secret"
                                value={organizationData.oauth_client_secret}
                                onChange={onchangeOrganizationData}
                                disabled
                            />
                            <label className="btn btn_gray ml10" onClick={openResetOauth}>
                                초기화
                            </label>
                        </li>
                    </>
                ) : (
                    <></>
                )}
            </ul>
            <div className="btn_wrap">
                <div className="left"></div>
                <div className="center"></div>
                <div className="right">
                    <button className="btn btn_gray_line" onClick={closeModal}>
                        취소
                    </button>
                    <button className="btn btn_black" onClick={createInst}>
                        저장
                    </button>
                </div>
            </div>
        </>
    ) : modalType === "HOSP" ? (
        <div>
            <ul className="form_wrap">
                {detailModalType === "modify" ? (
                    <li>
                        <label className="name">기관 아이디</label>
                        <Input
                            type="text"
                            name="org_id"
                            value={organizationData.org_id}
                            disabled
                        />
                    </li>
                ) : (
                    <></>
                )}
                <li>
                    <label className="name required">기관명<sup className="required">*</sup></label>
                    <Input
                        type="text"
                        name="org_name"
                        maxLength={"32"}
                        value={organizationData.org_name}
                        onChange={onchangeOrganizationData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">사업자등록번호{detailModalType === "create" ?
                        <sup className="required">*</sup> : ""}</label>
                    <Input
                        type="text"
                        name="biz_no"
                        maxLength={"12"}
                        value={organizationData.biz_no || ""}
                        onChange={onchangeOrganizationData}
                        onBlur={onChangeBlur}
                        disabled={detailModalType === "modify"}
                        required
                        placeholder="ex) 000-00-00000"
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name required">대표자<sup className="required">*</sup></label>
                    <Input
                        type="text"
                        name="president_name"
                        maxLength={"16"}
                        value={organizationData.president_name}
                        onChange={onchangeOrganizationData}
                        required
                        errorMessage={errorMessage}
                    />
                </li>
                <li>
                    <label className="name">기관구분<sup className="required">*</sup></label>
                    <select
                        id="class_code"
                        name="class_code"
                        required="required"
                        value={organizationData.class_code}
                        onChange={onchangeOrganizationData}
                    >
                        {Object.entries(hospTypeEnum).map((org) => {
                            return (
                                <option key={org[0]} value={org[1]}>
                                    {org[0]}
                                </option>
                            );
                        })}
                    </select>
                </li>
                <li>
                    <label className="name">주소<sup className="required">*</sup></label>
                    <Postcode
                        postalCode={postalCode}
                        setPostalCode={setPostalCode}
                        roadAddress={roadAddress}
                        setRoadAddress={setRoadAddress}
                        detailAddress={detailAddress}
                        setDetailAddress={setDetailAddress}
                        errorMessage={errorMessage}
                    ></Postcode>
                </li>
                <li>
                    <label className="name">전화번호<sup className="required">*</sup></label>
                    <Input
                        type="tel"
                        name="tel_no"
                        maxLength={"13"}
                        value={organizationData.tel_no}
                        onChange={onchangeOrganizationData}
                        required
                        placeholder="ex) 000-0000-0000"
                        errorMessage={errorMessage}
                    />
                </li>
                {modalType !== "PLAT" ? (
                    <>
                        <li>
                            <label className="name">OID{detailModalType === "create" ?
                                <sup className="required">*</sup> : ""}</label>
                            <Input
                                type="text"
                                name="oid"
                                maxLength={"30"}
                                value={organizationData.oid}
                                onChange={onchangeOrganizationData}
                                onBlur={onChangeBlur}
                                disabled={
                                    detailModalType === "modify" || modalType === "platform"
                                }
                                required
                                errorMessage={errorMessage}
                            />
                        </li>
                    </>
                ) : (
                    <></>
                )}
                <li>
                    <label className="name">외부 정보제공동의 연동여부<sup className="required">*</sup></label>
                    <ul key={agreeTypeList.is_external_consent} className="radio_ul">
                        {agreeTypeList.map((item, idx) => (
                            <li key={idx}>
                                <label htmlFor={item.display}
                                       className={organizationData?.is_external_consent === item.value ? "checked" : ""}
                                >{item.display}</label>
                                <input
                                    id={item.display}
                                    value={item.value}
                                    name={"is_external_consent"}
                                    type="radio"
                                    onChange={onchangeOrganizationData}
                                />
                            </li>
                        ))}
                    </ul>
                </li>
                <li>
                    <label className="name">지원 검사 결과 리포트 형식<sup className="required">*</sup></label>
                    <ul className={"result_ul"}>
                        {reportList.map((item, idx) => (
                            <li key={idx}>
                                <label htmlFor={item}>{item}</label>
                                <input
                                    id={item}
                                    type="checkbox"
                                    checked={checkedList.has(item)}
                                    onChange={() => handleOnChange(idx, item, "result")}
                                />
                            </li>
                        ))}
                    </ul>
                    {errorMessage.length !== 0 && errorMessage[0] === "result" ?
                        <p className="warning"
                           style={{marginLeft: "235px"}}
                        >결과 리포트 형식을 선택해주세요.</p> : ""}
                </li>

                {detailModalType === "modify" ? (
                    <>
                        <li>
                            <label className="name">oAuth2 client_id</label>
                            <Input
                                type="text"
                                name="oauth_client_id"
                                value={organizationData.oauth_client_id}
                                disabled
                            />
                        </li>
                        <li>
                            <label className="name">oAuth2 client_secret</label>
                            <Input
                                type="text"
                                className="w490"
                                name="oauth_client_secret"
                                value={organizationData.oauth_client_secret}
                                disabled
                            />
                            <label className="btn btn_gray ml10" onClick={openResetOauth}>
                                초기화
                            </label>
                        </li>
                    </>
                ) : (
                    <></>
                )}
            </ul>
            <div className="btn_wrap">
                <div className="left"></div>
                <div className="center"></div>
                <div className="right">
                    <button className="btn btn_gray_line" onClick={closeModal}>
                        취소
                    </button>
                    <button className="btn btn_black" onClick={detailModalType === "create" ? createInst : modifyInst}>
                        저장
                    </button>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default CreateOrgInModal;
