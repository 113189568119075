import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import moment from "moment";
import {
    copyToClipboard,
    DefaultPageSize,
    DefaultSearchDay,
    DefaultSortParams,
    MessageCollection, MessageColor,
} from "../components/Default/DefaultValue";

import {useRecoilState} from "recoil";
import { AlertInfoState} from "..";
import Modal from "../components/organisms/Modal/Modal";

const UserSelfAuth = () => {
    const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
    const [message, setMessage] = useRecoilState(AlertInfoState);

    // 제목, 브레드크럼
    const title = "정보제공 동의";
    const breadcrumb = [
        {name: "HOME", link: "./"},
        {name: "동의서 관리"},
        {name: "정보제공 동의", link: "./PersonalInfoAgreement"},
    ];

    const pageSize = DefaultPageSize;

    // 모달
    const [openModal, setOpenModal] = useState(false);
    const closeModal = () => {
        setOpenModal((openModal) => !openModal);
    };

    const [totalElements, setTotalElements] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [sortParams, setSortParams] = useState(DefaultSortParams.SELFAUTH);

    const [modalTotalElements, setModalTotalElements] = useState(0);

    // 검색조회부분
    const [tableSearchItems, setTableSearchItems] = useState([]);

    const [checkedIdsSet, setCheckedIdsSet] = useState(new Set());

    const [selectedAgreement, setSelectedAgreement] = useState({});
    const [selectedAgreementHealthInfoList, setSelectedAgreementHealthInfoList] = useState([]);
    const [disAgreementList, setDisAgreementList] = useState([]);

    // td 내용
    const [rows, _setRows] = useState([]);

    // th 내용
    const columns = [
        {
            name: "phi_code",
            header: "PHI코드",
            width: "15%",
            customBody: (row) => (
                <td key="phi_code">
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div className="ellipsis">{row.phi_code}</div>
                        <div
                            className="attachIcon"
                            style={{width: "18px"}}
                            onClick={() => {
                                copyToClipboard(row.phi_code);
                                setMessage({message: MessageCollection.COPY});
                            }}
                        ></div>
                    </div>
                </td>
            ),
        },
        {
            name: "org_name",
            header: "제공기관",
            width: "19%",
        },
        {
            name: "agree_on_other_hospital",
            header: "개인정보제공",
            width: "11%",
            customBody: (row) => (
                <td key="agree_on_other_hospital">
                    <div>{row.agree_on_other_hospital ? "동의" : "미동의"}</div>
                </td>
            ),
        },
        {
            name: "agree_dt",
            header: "동의일시",
            width: "15%",
        },
        {
            name: "revoke_dt",
            header: "취소일시",
            width: "15%",
        },
        {
            name: "modify",
            header: "수정",
            width: "8%",
            sort: false,
            customBody: (row) => (
                <td key={"modify"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            void openEditModal(row.phi_code);
                        }}
                    >
                        수정
                    </label>
                </td>
            ),
        },
    ];

    const healthInfoColumns = [
        {
            name: "display",
            header: "동의 항목",
            width: "90%",
            sort: false,
        },
        {
            name: "agree_dt",
            header: "철회",
            width: "10%",
            sort: false,
            customBody: (row) => (
                <td key={"agree_dt"}>
                    <label
                        className="btn btn_sm btn_gray_line"
                        onClick={() => {
                            deleteHealthInfo(row.name);
                        }}
                    >
                        철회
                    </label>
                </td>
            ),
        },
    ];

    const deleteHealthInfo = (name) => {
        const updatedHealthInfoList = selectedAgreementHealthInfoList.filter(
            (data) => data.name !== name
        );
        const updatedAgreeList = selectedAgreement.health_info_agree_list.filter(
            (data) => data !== name
        );
        const updatedSelectedAgreement = {...selectedAgreement};
        updatedSelectedAgreement.health_info_agree_list = updatedAgreeList;
        setSelectedAgreementHealthInfoList(updatedHealthInfoList);
        setSelectedAgreement(updatedSelectedAgreement);
    };

    const tableButton = {
        name: "철회하기",
        onClick: () => {
            void revokeAgreement();
        },
    };

    const openEditModal = async (id) => {
        const selectedAgreeResponse = await request.get(`agrees/info/${id}`, {
            params: {code: id},
        });
        setSelectedAgreement(selectedAgreeResponse.data.data);
        const serviceType = selectedAgreeResponse.data.data.service_type;
        const agreementTypeData = await request.get(`agree-rules/${serviceType}`);
        let health_info_agree_list = selectedAgreeResponse.data.data.health_info_agree_list;
        if (health_info_agree_list === null) {
            health_info_agree_list = [];
        }

        const agreelist = agreementTypeData.data.data.agreement.filter((el) => health_info_agree_list.find((item) => item === el.profile));
        const disagreelist = agreementTypeData.data.data.agreement.filter((el) => health_info_agree_list.indexOf(el.profile) === -1);

        let updatedHealthInfo =
            agreelist.map((item) => ({
                name: item.profile,
                display: item.display,
            }));
        const updatedDisAgree =
            disagreelist.map((item) => ({
                disagree_name: item.profile,
                disagree_display: item.display
            }));
        setSelectedAgreementHealthInfoList(updatedHealthInfo);
        setDisAgreementList(updatedDisAgree);
        setModalTotalElements(updatedHealthInfo.length);
        setOpenModal(true);
    };

    const onChangeAgreementData = (event) => {
        const {name, value} = event.target;
        if (value === "true") {
            setSelectedAgreement((prevState) => ({
                ...prevState,
                [name]: value === false,
            }));
        } else {
            setMessage({message: MessageCollection.AGREE, color: "#ccba54"});
        }
    };

    const modifyAgreement = async () => {
        try {
            await request.put(`agrees/info/${selectedAgreement.phi_code}`, {
                code: selectedAgreement.phi_code,
                ...selectedAgreement,
            });
            setMessage({message: MessageCollection.MODIFY});
            closeModal();
            setRefresh((prev) => !prev);
        } catch (error) {
            //  setAlert(error);
        }
    };

    const revokeAgreement = async () => {
        const revokeArray = Array.from(checkedIdsSet);
        try {
            await request.put(`agrees/info/revoke`, revokeArray);
            setMessage({message: MessageCollection.MODIFY});
            setRefresh((prev) => !prev);
        } catch (error) {
            // setAlert(error);
        }
    };

    // 입력창값이 변경될때
    const onChangeSearchValue = (event) => {
        if (event.key === "Enter") {
            onClickSearchButton();
        }
        const {name, value} = event.target;
        setTableSearchItems((prevState) =>
            prevState.map((item) => {
                if (item.name.join(",") === name) {
                    return {...item, value};
                }
                return item;
            })
        );
        if (name === "startDate") {
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "endDate") {
                        return {
                            ...item,
                            value:
                                item.value > moment(value).add(30, "day").format("YYYY-MM-DD")
                                    ? moment(value).add(30, "day").format("YYYY-MM-DD")
                                    : item.value < value
                                        ? value
                                        : item.value,
                        };
                    }
                    return item;
                })
            );
        }
    };

    // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
    const onClickSearchButton = () => {
        const start = tableSearchItems[0].value;
        const end = tableSearchItems[1].value;
        const diff = moment(end).diff(moment(start), "days")

        if(diff > 30){
            setMessage({
                message: "최대 조회기간은 30일입니다.",
                color: MessageColor.RED,
            })
        }else if(diff < 0){
            setMessage({
                message: "종료날짜는 시작날짜보다 이전일 수 없습니다.",
                color: MessageColor.RED,
            })
        }else {
            setPage(1);
            setCheckedIdsSet(new Set());
            setRefresh((prev) => !prev);
        }
    };

    useEffect(() => {
        (async () => {
            let searchItems = [
                {
                    name: ["startDate"],
                    value: moment(new Date())
                        .subtract(DefaultSearchDay, "day")
                        .format("YYYY-MM-DD"),
                    type: "date",
                    label: "동의일시",
                },
                {
                    name: ["endDate"],
                    value: moment(new Date()).format("YYYY-MM-DD"),
                    type: "date",
                },
                {
                    name: ["name"],
                    placeholder: ["환자 이름"],
                    value: "",
                    type: "text",
                },
                {
                    name: ["phone"],
                    placeholder: ["전화번호"],
                    value: "",
                    type: "text",
                },
            ];

            setTableSearchItems(searchItems);
            setRefresh((prev) => !prev);
        })();
    }, []);

    useEffect(() => {
        if (tableSearchItems.length > 0) {
            //유효하지 않은 날짜 체크
            if (!moment(tableSearchItems[0].value).isValid() || !moment(tableSearchItems[1].value).isValid()) {
                return;
            }
            if (tableSearchItems[2].value === "" || tableSearchItems[3].value === "") {
                return;
            }
            (async () => {
                const agreeInfoData = await request.get(`agrees/info`, {
                    params: {
                        page: page - 1,
                        size: pageSize,
                        sort: sortParams,
                        start_date: moment(tableSearchItems[0].value).format("YYYYMMDD"),
                        end_date: moment(tableSearchItems[1].value).format("YYYYMMDD"),
                        name: tableSearchItems[2].value.trim(),
                        phone: tableSearchItems[3].value.trim(),
                    },
                });
                _setRows(agreeInfoData.data.data.content);
                setTotalPages(agreeInfoData.data.data.totalPages);
                setTotalElements(agreeInfoData.data.data.totalElements);

            })();
        }
    }, [refresh, page, sortParams]);

    // 최종 화면 나오는 곳
    return (
        <>
            <div className="wrapper">
                {<History title={title} breadcrumb={breadcrumb}/>}
                <hr/>
                {
                    <Table
                        rows={rows}
                        columns={columns}
                        getTotalRows={totalElements}
                        search={tableSearchItems}
                        onChangeSearchValue={onChangeSearchValue}
                        onClickSearchButton={onClickSearchButton}
                        button={tableButton}
                        page={page}
                        totalPages={totalPages}
                        changePage={setPage}
                        sortParams={sortParams}
                        setSortParams={setSortParams}
                        checkbox={"phi_code"} // 체크박스를 활성화 하겠다. + 어떤 컬럼을 기준으로 잡겠다.(string: column_name)
                        checkMode="multiple" // 한번에 여러개의 체크박스를 활성화 할수 있음(string : multiple)
                        checkedIdsSet={checkedIdsSet} // 체크된 항목의 ID 리스트 (new Set())
                        setCheckedIdsSet={setCheckedIdsSet} // 체크된 항목의 ID 리스트를 바꾸기 위한 변수
                    />
                }
            </div>
            {
                <Modal
                    open={openModal}
                    close={closeModal}
                    title={"정보제공동의 수정"}
                    content={
                        <>
                            {selectedAgreement.phi_code && (
                                <ul className="form_wrap">
                                    <li>
                                        <label className="name">PHI코드</label>
                                        <input
                                            type="text"
                                            name="phi_code"
                                            value={selectedAgreement.phi_code}
                                            disabled
                                        />
                                    </li>
                                    <li>
                                        <label className="name">정보이용 기관명</label>
                                        <input
                                            type="text"
                                            name="org_name"
                                            value={selectedAgreement.org_name}
                                            disabled
                                        />
                                    </li>
                                    <li>
                                        <div className="chk_btn"></div>
                                        <label className="name" style={{marginTop: "2px"}}>
                                            정보제공 동의
                                        </label>
                                        <input
                                            id="agree_on_other_hospital"
                                            className="check_btn"
                                            type="checkbox"
                                            name="agree_on_other_hospital"
                                            value={selectedAgreement.agree_on_other_hospital}
                                            checked={selectedAgreement.agree_on_other_hospital }
                                            onChange={onChangeAgreementData}
                                        />
                                        <label htmlFor="agree_on_other_hospital"></label>
                                    </li>
                                    <li>
                                        <label className="name">동의일시</label>
                                        <input
                                            type="text"
                                            name="org_name"
                                            value={selectedAgreement.agree_dt}
                                            disabled
                                        />
                                    </li>
                                    <li>
                                        <label className="name">의료제공 동의 목록</label>
                                    </li>
                                    <Table
                                        rows={selectedAgreementHealthInfoList}
                                        columns={healthInfoColumns}
                                        getTotalRows={modalTotalElements}
                                        hidePagenation
                                    />
                                </ul>
                            )}
                            <ul className={"disagreement"}>
                                <p>비동의 항목 - </p>
                                {disAgreementList.length === 0 ? "없음" : disAgreementList.map((el)=> (<li key={el.disagree_display}>{el.disagree_display}</li>))}
                            </ul>
                            <div className="btn_wrap">
                                <div className="left"></div>
                                <div className="center"></div>
                                <div className="right">
                                    <button className="btn btn_gray_line" onClick={closeModal}>
                                        취소
                                    </button>
                                    <button className="btn btn_black" onClick={modifyAgreement}>
                                        저장
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                />
            }
        </>
    );
};

export default UserSelfAuth;
