import {useRecoilState} from "recoil";
import {AlertErrorState} from "../../../index";
import {styled} from "styled-components";
import {useEffect} from "react";
import InfoIcon from "@mui/icons-material/Info";


const SnackBarWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 50px;
  right: 50px;
  background-color: #cc4545;
  opacity: 100%;
  color: white;
  z-index: 99999999;
  padding: 10px;
  min-height: 50px;
  word-break: break-all;
  border-radius: 5px;
  justify-content: space-between;
  min-width: 100px;
  width: 300px;

`;
const SnackbarMessage = styled.div`
  font-size: 15px;
  font-weight: 500;
  word-wrap: break-word;
  margin-top: 4px;
  width: ${(props) => props.code ? "50px" : "250px"};
  margin-left: ${(props) => props.code ? "10px" : ""};
`;

const Snackbar = () => {
    const [errorState, setErrorState] = useRecoilState(AlertErrorState);
    const handleClose = () => {
        setErrorState({error: {}});
    };


    const errorResponseWithSnackbar = {
        400: (
            <>
                <SnackBarWrapper
                    style={{
                        display: errorState.error?.response ? "flex" : "none",
                    }}
                >
                    <InfoIcon></InfoIcon>
                    <SnackbarMessage
                        code={1}>{errorState.error?.response ? `(${errorState.error?.response?.status})` : ""}</SnackbarMessage>
                    <SnackbarMessage>{errorState.error?.response ? errorState.error?.response?.data.msg : ""}</SnackbarMessage>
                </SnackBarWrapper>
            </>
        ),
        401: (
            <>
                <SnackBarWrapper
                    style={{
                        display: errorState.error?.response ? "flex" : "none",
                    }}
                >
                    <InfoIcon></InfoIcon>
                    <SnackbarMessage
                        code={1}>{errorState.error?.response ? `(${errorState.error?.response?.status})` : ""}</SnackbarMessage>
                    <SnackbarMessage>{errorState.error?.response ? errorState.error?.response?.data.msg : ""}</SnackbarMessage>
                </SnackBarWrapper>
            </>
        ),
        403: (
            <>
                <SnackbarMessage code={1}>
                    {errorState.error?.response
                        ? `(${errorState.error?.response?.status}) CODE : ${errorState.error?.response?.data.code}`
                        : ""}
                </SnackbarMessage>
                <SnackbarMessage>{errorState.error?.response ? `${errorState.error?.response?.data.msg}` : ""}</SnackbarMessage>
            </>
        ),
        500: (
            <>
                <SnackbarMessage>
                    {errorState.error?.response
                        ? `(${errorState.error?.response?.status}) CODE : ${errorState.error?.response?.data.code}`
                        : ""}
                </SnackbarMessage>
                <SnackbarMessage>{errorState.error?.response ? `${errorState.error?.response?.data.msg}` : ""}</SnackbarMessage>
            </>
        ),
    };

    const showErrorSnackbar = (status) => {
        return (
            <SnackBarWrapper
                style={{
                    display: errorState.error?.response ? "block" : "none",
                }}
            >
                {errorResponseWithSnackbar[status]}
            </SnackBarWrapper>
        );
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleClose();
        }, 3000);

        return () => clearTimeout(timer);
    }, [errorState]);

    return (
        <>
            {errorState.error?.response &&
            Object.keys(errorResponseWithSnackbar).includes(errorState.error?.response?.status.toString()) ? (
                showErrorSnackbar(errorState.error?.response.status)
            ) : (
                <>
                    <SnackBarWrapper
                        style={{
                            display: errorState.error?.response ? "block" : "none",
                        }}
                    >
                        <SnackbarMessage code={1}>
                            {errorState.error?.response ? `(${errorState.error?.response?.status})` : ""}
                        </SnackbarMessage>
                        <SnackbarMessage>{"정의되지 않은 상태값입니다, 관리자에게 문의해주세요."}</SnackbarMessage>
                    </SnackBarWrapper>
                </>
            )}
        </>
    );
};

export default Snackbar;
