import axios from "axios";

const sleep = (ms)=> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const requestForRetry = async (config)=> {
  let retries = 3; // 재시도 횟수
  while (retries > 0) {
    try {
      await sleep(1000); // 1초 대기
      const response = await axios(config);
      return response;
    } catch (retryError) {
      retries -= 1;
      if (retries <= 0) {
        window.location.href = "/Login";
        return Promise.reject(retryError);
        console.log("retryError")
      }
    }
  }
}

export default requestForRetry;
