import React, {useEffect, useState} from "react";
import request from "../config/Axios";
import History from "../components/organisms/History/History";
import {Table} from "../components/organisms/Table/Table";
import {
    ButtonPlaceHolder,
    DefaultModalPageSize,
    DefaultPageSize,
    DefaultSortParams,
    MessageCollection,
    OrgModalName,
} from "../components/Default/DefaultValue";

import {useSetRecoilState} from "recoil";
import {AlertInfoState} from "..";
import Modal from "../components/organisms/Modal/Modal";
import SelectOrgModal from "../components/templates/SelectOrgModal/SelectOrgModal";
import CreateAgreementModal from "../components/templates/CreateAgreementModal/CreateAgreementModal";


const ManageAgreeRule = () => {
        const [refresh, setRefresh] = useState(false); // 검색에서 페이지 새로고침 되니까 있는게 좋음
        const [modalRefresh, setModalRefresh] = useState(false);
        const [errorMessage, setErrorMessage] = useState([]);
        const setMessage = useSetRecoilState(AlertInfoState);
        const [isChecked, setIsChecked] = useState(true);
        // 제목, 브레드크럼
        const title = "정보제공 설정";
        const breadcrumb = [
            {name: "HOME", link: "./"},
            {name: "동의서 관리"},
            {name: "정보제공 설정", link: "./ManageAgreeRule"},
        ];

        const pageSize = DefaultPageSize;

        // 모달
        const [openModal, setOpenModal] = useState(false);
        const [modalType, setModalType] = useState("");
        const [detailModalType, setDetailModalType] = useState("");
        const closeModal = () => {
            setErrorMessage([]);
            setMyServiceType("");
            setDetailModalType("");
            setAgreeCheckList(new Set());
            setOpenModal((openModal) => !openModal);
        };

        const initAgreeConfigData = {
            provider_org_id: "",
            provider_org_name: "",
            used_org_id: "",
            used_org_name: "",
            service_type: "DTx",
            agreement_list: [],
        };
        const [agreeConfigData, setAgreeConfigData] = useState(initAgreeConfigData);
        const [convertProfileToDisplay, setConvertProfileToDisplay] = useState({});

        const [page, setPage] = useState(1);
        const [totalPages, setTotalPages] = useState(5);
        const [totalElements, setTotalElements] = useState(0);
        const [sortParams, setSortParams] = useState(DefaultSortParams.DEFAULT);
        //Alert 모달
        const [openAlert, setOpenAlert] = useState(false);
        const closeAlert = () => {
            setOpenAlert((openModal) => !openModal);
        };
        // 서브 모달
        const [subModalType, setSubModalType] = useState("");
        const [openSubModal, setOpenSubModal] = useState(false);
        const [isModal, setIsModal] = useState(false);

        // 모달 테이블 관련 변수
        const [modalTotalElements, setModalTotalElements] = useState(0);
        const [modalPage, setModalPage] = useState(1);
        const [modalTotalPages, setModalTotalPages] = useState(5);
        const [modalSortParams, setModalSortParams] = useState(
            DefaultSortParams.DEFAULT
        );

        // 서브 모달 검색 조회
        const orgTableSearchItems = {
            name: ["orgName"],
            placeholder: ["기관명"],
            value: "",
            type: "text",
        };
        const [modalTableSearchItems, setModalTableSearchItems] = useState([orgTableSearchItems]);

        // 검색조회부분
        const [tableSearchItems, setTableSearchItems] = useState([]);

        //정보제공항목
        const [agreeCheckList, setAgreeCheckList] = useState(new Set()); //체크한 항목들
        const [agreeRequired, setAgreeRequired] = useState([]); // 필수항목들 미리 담아놓기

        //기존 서비스와 동일하면 중복체크 x
        const [myServiceType, setMyServiceType] = useState("");


        // td 내용
        const [rows, _setRows] = useState([]);
        const [orgRows, _setOrgRows] = useState([]);
        const [deleteRow, setDeleteRow] = useState([]);

        // ENUM
        const [svcTypeEnum, setSvcTypeEnum] = useState([]);
        const [modifyEnum, setModifyEnum] = useState({});

        // th 내용
        const columns = [
            {
                name: "provider_org_name",
                header: "의료기관",
                width: "12%",
            },
            {
                name: "used_org_name",
                header: "기업",
                width: "12%",
            },
            {
                name: "service_type",
                header: "서비스",
                width: "12%",
                customBody: (row) => (
                    <td key={"service_type"}>
                        {convertProfileToDisplay[row.service_type]}
                    </td>
                ),
            },
            {
                name: "agreement_list",
                header: "정보제공 설정 항목",
                width: "*",
                sort: false,
                customBody: (row) => (
                    <td key={"agreement_list"}>
                        {row.agreement_list.map((el) => <p key={el}>{el}</p>)}
                    </td>
                ),
            },
            {
                name: "create_at",
                header: "등록일",
                width: "15%",
            },
            {
                name: "modify",
                header: "수정",
                width: "8%",
                sort: false,
                customBody: (row) => (
                    <td key={"modify"}>
                        <label
                            className="btn btn_sm btn_gray_line"
                            onClick={() => {
                                void openEditModal(row);
                            }}
                        >
                            수정
                        </label>
                    </td>
                ),
            },
            {
                name: "delete",
                header: "삭제",
                width: "8%",
                sort: false,
                customBody: (row) => (
                    <td key={"delete"}>
                        <label
                            className="btn btn_sm btn_gray_line"
                            onClick={() => {
                                void openDeleteModal(row);
                            }}
                        >
                            삭제
                        </label>
                    </td>
                ),
            },
        ];
        //checkbox
        const initAgreeList = [
            {
                profile: "",
                display: "",
                required: "",
                desc: ""
            },
        ];

        const [agreeList, setAgreeList] = useState(initAgreeList);

        const tableButton = {
            name: "설정 등록",
            onClick: () => {
                void createConfig();
            },
        };
        const closeSubModal = () => {
            setOpenSubModal((openModal) => !openModal);
            setSubModalType("");
        };
        const createConfig = () => {
            setModalType("create");
            setOpenModal(true);
            setAgreeConfigData(initAgreeConfigData); //초기화
            setIsChecked(false); //초기화
        };
        const checkValue = async (checkItems, refinedData) => {
            setDetailModalType("");
            let flag = true;

            checkItems.map((data) => {
                if (flag === true && refinedData[data] === "") {
                    setErrorMessage([data, "필수 입력값입니다."]);
                    flag = false;
                }
            });

            if (flag === false) {
                return;
            }

            //정보제공설정 중복확인
            // 등록 - 무조건 중복체크
            // 수정 - 본인 service_type 빼고 중복체크
            if (modalType === "create") {
                const {provider_org_id, used_org_id, service_type} = refinedData;
                try {
                    const duplicatedAgreeRule = await request.get(`/agree-rules/rules-is-duplicated/${provider_org_id}/${used_org_id}/${service_type}`)
                    if (duplicatedAgreeRule === undefined) {
                        return;
                    }
                } catch (error) {

                }
            } else { //modalType === "modify"
                if (myServiceType !== refinedData.service_type) {
                    const {provider_org_id, used_org_id, service_type} = refinedData;
                    try {
                        const duplicatedAgreeRule = await request.get(`/agree-rules/rules-is-duplicated/${provider_org_id}/${used_org_id}/${service_type}`)
                        if (duplicatedAgreeRule === undefined) {
                            return;
                        }
                    } catch (error) {

                    }
                }
            }

            //전체 미선택일시, 필수항목 넣어주기
            if(refinedData.agreement_list.length === 0){
                refinedData.agreement_list = agreeRequired;
            }


            //필수항목 체크 확인
            agreeRequired.map((el)=>{
                if(refinedData.agreement_list.indexOf(el) === -1){
                    setDetailModalType("required");
                }
            });
            setOpenAlert(true);

            setErrorMessage([]);
            if (openAlert !== true) {
                setOpenAlert(true);
                return;
            }

            delete refinedData.provider_org_name;
            delete refinedData.used_org_name;
            delete refinedData.update_at;
            return refinedData;

        }
        const createAgreeRule = async () => {
            setModalType("create");

            const refinedAgreeConfigData = {
                ...agreeConfigData,
                agreement_list: Array.from(agreeCheckList),
            };

            let checkItems = [
                "provider_org_name",
                "used_org_name",
                "service_type",
            ];


            const checkedData = await checkValue(checkItems, refinedAgreeConfigData);
            if (checkedData !== undefined) {
                const {provider_org_id, used_org_id, service_type} = refinedAgreeConfigData;
                try {
                    await request.post(`agree-rules/${provider_org_id}/${used_org_id}/${service_type}`, {
                        ...refinedAgreeConfigData
                    });

                    setPage(1);
                    setRefresh((prev) => !prev);
                    setMessage({message: MessageCollection.CREATE, color: ""});
                    closeAlert();
                    closeModal();
                } catch (error) {
                    //axios는 200이 아닌경우 자동으로 오류발생 axios handler로 이동
                    // if(!axios.isAxiosError(error)) {
                    //     setAlert(error)
                    // }
                }
            }
        };
        const openEditModal = async (row) => {
            setMyServiceType(row.service_type);//기존에 선택한 서비스타입이 아닌경우만 중복체크하기위해 넣어줌.
            try {
                const {provider_org_id, used_org_id, service_type} = row;
                const ruleResponse = await request.get(`agree-rules/${provider_org_id}/${used_org_id}/${service_type}`);
                const data = ruleResponse.data.data;
                const set = new Set();
                data.agreement_list.map(item => set.add(item));
                setAgreeCheckList(set);


            } catch (error) {

            }
            setAgreeConfigData(row);
            setModalType("modify");
            setOpenModal(true);
        };
        const openDeleteModal = async (row) => {
            setDeleteRow(row);
            setModalType("delete");
            setOpenAlert(true);
        };

        const onChangeAgreeConfigData = async (event) => {
            const {name, value} = event.target;
            if (name === "service_type") {
                const agreeRuleEnum = await request.get(`agree-rules/${value}`);
                setAgreeList(agreeRuleEnum.data.data.agreement);
                setAgreeConfigData((prevState) => ({
                    ...prevState,
                    service_type: value
                }));
                setIsChecked(false);
                setAgreeCheckList(new Set());

                //필수항목 list
                const updateRequired = agreeRuleEnum.data.data.agreement?.map((item) => item.required === "Required" ? item.profile : null);
                setAgreeRequired(updateRequired.filter(item => item));
            }else {
                setAgreeConfigData((prevState) => ({
                    ...prevState,
                    [name]: value
                }));
            }
        };

        const modifyAgreeRule = async () => {
            const refinedAgreeConfigData = {
                ...agreeConfigData,
                agreement_list: Array.from(agreeCheckList),
            };
            let checkItems = [
                "provider_org_name",
                "used_org_name",
                "service_type",
            ];
            const checkedData = await checkValue(checkItems, refinedAgreeConfigData);
            if (checkedData !== undefined) {
                try {
                    const {provider_org_id, used_org_id, service_type} = refinedAgreeConfigData;
                    await request.put(`agree-rules/${provider_org_id}/${used_org_id}/${service_type}`, {
                        ...refinedAgreeConfigData,
                    });


                    setPage(1);
                    setMessage({message: MessageCollection.MODIFY, color: ""});
                    setRefresh((prev) => !prev);

                    closeAlert();
                    closeModal();
                } catch (error) {
                    //  setAlert(error);
                }
            }
        };
        const deleteAgreeRule = async () => {
            try {
                const {provider_org_id, used_org_id, service_type} = deleteRow;
                await request.delete(`agree-rules/${provider_org_id}/${used_org_id}/${service_type}`);


                setPage(1);
                setMessage({message: MessageCollection.MODIFY, color: ""});
                setRefresh((prev) => !prev);

                closeAlert();
                setDeleteRow([]);
            } catch (error) {
                //  setAlert(error);
            }
        };

        // 입력창값이 변경될때
        const onChangeSearchValue = (event) => {
            if (event.key === "Enter") {
                onClickSearchButton();
            }
            const {name, value} = event.target;
            setTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === name) {
                        return {...item, value};
                    }
                    return item;
                })
            );
        };

        // 검색 영역에서 검색버튼 눌렀을때 뜨는 알림창
        const onClickSearchButton = () => {
            setPage(1);
            setRefresh((prev) => !prev);
        };
        const onClickOrgFilter = (value, isModal) => {
            setIsModal(isModal);
            setSubModalType(value);
            setModalTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "orgName") {
                        return {...item, value: ""};
                    }
                    return item;
                })
            );

            setOpenSubModal(true);
        };
        const selectOrg = (row) => {
            if (isModal) {
                if (subModalType === "HOSP") {
                    setAgreeConfigData((prevState) => ({
                        ...prevState,
                        provider_org_id: row.org_id,
                        provider_org_name: row.org_name
                    }));
                } else {
                    setAgreeConfigData((prevState) => ({
                        ...prevState,
                        used_org_id: row.org_id,
                        used_org_name: row.org_name
                    }));
                }
            } else {
                if (subModalType === "HOSP") {
                    setTableSearchItems((prevState) =>
                        prevState.map((item) => {
                            if (item.name.join(",") === "hospFilter") {
                                return {...item, value: row.org_id, display: row.org_name};
                            }
                            return item;
                        })
                    );
                }
                if (subModalType === "CORP") {
                    setTableSearchItems((prevState) =>
                        prevState.map((item) => {
                            if (item.name.join(",") === "corpFilter") {
                                return {...item, value: row.org_id, display: row.org_name};
                            }
                            return item;
                        })
                    );
                }
            }
            closeSubModal();
        };
        const onChangeModalSearchValue = (event) => {
            if (event.key === "Enter") {
                onClickModalSearchButton();
            }
            const {name, value} = event.target;
            setModalTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === name) {
                        return {...item, value};
                    }
                    return item;
                })
            );
        };
        const openSelectOrgModal = (type) => {
            setModalPage(1);
            setSubModalType(type);
            setModalTableSearchItems((prevState) =>
                prevState.map((item) => {
                    if (item.name.join(",") === "orgName") {
                        return {...item, value: ""};
                    }
                    return item;
                })
            );
            setOpenSubModal(true);
        };
        const onClickOrgFilterForRemove = (value) => {
            if (value === "HOSP") {
                setTableSearchItems((prevState) =>
                    prevState.map((item) => {
                        if (item.name.join(",") === "hospFilter") {
                            return {...item, value: "", display: ButtonPlaceHolder.HOSP};
                        }
                        return item;
                    })
                );
            }else{
                setTableSearchItems((prevState) =>
                    prevState.map((item) => {
                        if (item.name.join(",") === "corpFilter") {
                            return {...item, value: "", display: ButtonPlaceHolder.CORP};
                        }
                        return item;
                    })
                );
            }
        };
        const onClickModalSearchButton = () => {
            setModalPage(1);
            setModalRefresh((prev) => !prev);
        };
        useEffect(() => {
            (async () => {
                try {
                    const organsResponse = await request.get("organs", {
                        params: {
                            page: modalPage - 1,
                            size: DefaultModalPageSize,
                            sort: modalSortParams,
                            type: subModalType,
                            name: modalTableSearchItems[0].value.trim(),
                        },
                    });
                    _setOrgRows(organsResponse.data.data.content);
                    setModalTotalPages(organsResponse.data.data.totalPages);
                    setModalTotalElements(organsResponse.data.data.totalElements);
                } catch (error) {

                }
            })();
        }, [openSubModal, modalPage, modalSortParams, modalRefresh]);

        useEffect(() => {
            (async () => {
                    const updatedServiceTypeEnum = {"전체": ""};
                    const updatedConvertTypeEnum = {};
                    try {
                        const svrEnumResponse = await request.get(`commons/type/service`);
                        svrEnumResponse.data.data.map((data) => {
                            updatedServiceTypeEnum[data.display] = data.id;
                            updatedConvertTypeEnum[data.id] = data.display;

                        });
                        setSvcTypeEnum(updatedServiceTypeEnum);
                        setConvertProfileToDisplay(updatedConvertTypeEnum);

                        setTableSearchItems([
                            {
                                name: ["hospFilter"],
                                type: "labelButton",
                                onClick: () => {
                                    onClickOrgFilter("HOSP", false);
                                },
                                onClickRemove: () => {
                                    onClickOrgFilterForRemove("HOSP");
                                },
                                display: ButtonPlaceHolder.HOSP,
                                value: "",
                            },
                            {
                                name: ["corpFilter"],
                                type: "labelButton",
                                onClick: () => {
                                    onClickOrgFilter("CORP", false);
                                },
                                onClickRemove: () => {
                                    onClickOrgFilterForRemove("CORP");
                                },
                                display: ButtonPlaceHolder.CORP,
                                value: "",
                            },
                            {
                                name: ["svcFilter"],
                                filter: updatedServiceTypeEnum,
                                value: Object.values(updatedServiceTypeEnum)[0],
                                type: "select",
                            },
                        ]);
                    } catch (error) {
                    }


                    try {
                        const type = agreeConfigData.service_type;
                        const agreeRuleEnum = await request.get(`agree-rules/${type}`);
                        setAgreeList(agreeRuleEnum.data.data.agreement);
                        setIsChecked(false);

                        //필수항목 list
                        const updateRequired = agreeRuleEnum.data.data.agreement?.map((item) => item.required === "Required" ? item.profile : null);
                        setAgreeRequired(updateRequired.filter(item => item));
                    } catch (error) {
                    }
                }
            )();
        }, []);

        useEffect(() => {
            (async () => {
                const agreeRuleEnum = await request.get(`agree-rules/${agreeConfigData.service_type}`);
                setAgreeList(agreeRuleEnum.data.data.agreement);
                //필수항목 list
                const updateRequired = agreeRuleEnum.data.data.agreement?.map((item) => item.required === "Required" ? item.profile : null);
                setAgreeRequired(updateRequired.filter(item => item));
            })();
        }, [ agreeConfigData.service_type]);

        useEffect(() => {
            const modifySvcEnum = {...svcTypeEnum};
            delete modifySvcEnum["전체"];
            setModifyEnum(modifySvcEnum);
        }, [svcTypeEnum]);

        useEffect(() => {
            if (tableSearchItems.length > 0) {
                (async () => {
                    try {
                        const serviceData = await request.get(`agree-rules`, {
                            params: {
                                page: page - 1,
                                size: pageSize,
                                hosp_id: tableSearchItems[0].value,
                                corp_id: tableSearchItems[1].value,
                                type: tableSearchItems[2].value,
                                sort: sortParams,
                            },
                        });
                        _setRows(serviceData.data.data.content);
                        setTotalPages(serviceData.data.data.totalPages);
                        setTotalElements(serviceData.data.data.totalElements);
                    } catch (error) {
                        //ax
                        //  setAlert(error);
                    }
                })();
            } else {
                setRefresh((prev) => !prev);
            }
        }, [refresh, page, sortParams]);


// 최종 화면 나오는 곳
        return (
            <>
                <div className="wrapper">
                    {<History title={title} breadcrumb={breadcrumb}/>}
                    <hr/>
                    {
                        <Table
                            rows={rows}
                            columns={columns}
                            getTotalRows={totalElements}
                            search={tableSearchItems}
                            onChangeSearchValue={onChangeSearchValue}
                            onClickSearchButton={onClickSearchButton}
                            button={tableButton}
                            page={page}
                            totalPages={totalPages}
                            changePage={setPage}
                            sortParams={sortParams}
                            setSortParams={setSortParams}
                        />
                    }
                </div>
                {
                    <Modal
                        open={openModal}
                        close={closeModal}
                        title={modalType === "create" ? "정보제공 설정 등록" : "정보제공 설정 수정"}
                        content={
                            <CreateAgreementModal
                                modifyEnum={modifyEnum}
                                agreeConfigData={agreeConfigData}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                agreeList={agreeList}
                                agreeCheckList={agreeCheckList}
                                setAgreeCheckList={setAgreeCheckList}
                                createAgreeRule={createAgreeRule}
                                modifyAgreeRule={modifyAgreeRule}
                                openSelectOrgModal={openSelectOrgModal}
                                onClickOrgFilter={onClickOrgFilter}
                                closeModal={closeModal}
                                modalType={modalType}
                                onChangeAgreeConfigData={onChangeAgreeConfigData}
                                errorMessage={errorMessage}
                            ></CreateAgreementModal>
                        }
                    />
                }
                {
                    <Modal
                        open={openSubModal}
                        close={closeSubModal}
                        title={subModalType === "HOSP" ? OrgModalName.HOSP : OrgModalName.CORP}
                        content={
                            <SelectOrgModal
                                rows={orgRows}
                                orgType={subModalType}
                                selectOrg={selectOrg}
                                getTotalRows={modalTotalElements}
                                search={modalTableSearchItems}
                                onChangeSearchValue={onChangeModalSearchValue}
                                onClickSearchButton={onClickModalSearchButton}
                                page={modalPage}
                                limit={DefaultModalPageSize}
                                totalPages={modalTotalPages}
                                changePage={setModalPage}
                                sortParams={modalSortParams}
                                setSortParams={setModalSortParams}
                            ></SelectOrgModal>
                        }
                    />
                }
                {
                    <Modal
                        size={"sm"}
                        open={openAlert}
                        close={closeAlert}
                        title={"정보제공 설정"}
                        content={
                            <div>
                                <div style={{marginBottom: "30px"}}>
                                    {modalType === "delete" ? "삭제시 정보제공설정을 사용할 수 없습니다. 삭제하시겠습니까?"
                                        : detailModalType === "required" ? "필수 항목을 동의하지 않았습니다. 그대로 저장하시겠습니까?"
                                            : detailModalType !== "required" ? "정말 저장하시겠습니까?" : ""}
                                </div>
                                <div style={{display: "flex", justifyContent: "right"}}>
                                    <button className="btn btn_gray_line" onClick={closeAlert}>
                                        취소
                                    </button>
                                    <button
                                        style={{marginLeft: "30px"}}
                                        className="btn btn_black"
                                        onClick={
                                            modalType === "create"
                                                ? createAgreeRule
                                                : modalType === "modify"
                                                    ? modifyAgreeRule
                                                    : deleteAgreeRule
                                        }
                                    >
                                        확인
                                    </button>
                                </div>
                            </div>
                        }
                    />
                }
            </>
        );
    }
;

export default ManageAgreeRule;
